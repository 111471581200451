.news-page {
  &__preview-text {
    margin-bottom: $dist/2;

    p {
      font-size: $fs-medium;
    }
  }

  &__text {
    @extend %cf;

    .news-page__image {
      display: block;
      float: right;
      margin-left: $dist/2;
      margin-bottom: $dist/4;
      @include image-hover();

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &__meta {
    @include resp-margin(top);
    @include resp-padding(top);
    border-top: 1px solid $border-color;

    ul {
      @extend %cf;
      margin: 0;
      padding: 0;

      li {
        display: block;
        @include resp-margin(top);

        &.news-page__prev {
          float: left;

          a {
            &:after {
              display: none;
            }

            padding-left: 0;
            padding-right: $dist/2;

            &:before {
              @include fontawesome($fa-var-long-arrow-left);
              background: $ac-sec-hover;
              width: $dist;
              height: $dist;
              line-height: $dist;
              text-align: center;
              margin-right: $dist/2;
              transition: background linear $duration;
            }
          }
        }

        &.news-page__next {
          float: right;
        }

        a {
          @extend %button;
          @extend %button--neutral;
          @extend %button--small;
        }
      }
    }
  }
}