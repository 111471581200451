.page__text,
.main__text {
  & + .calendar {
    @include resp-margin(top);
  }
}

.calendar {
  &--calendar-view {
    @include resp-margin(bottom);
  }

  &--list-view {
    @include resp-margin(top);
  }

  .fc-event-container {
    & > a[href] {
      .fc-title:before {
        @include fontawesome($fa-var-info-circle);
        margin-right: 0.5em;
      }
    }
  }

  .fc-event {
    padding: $dist/5;
    border-radius: $radius;
  }
}

.calendar-categories {
  & > strong {
    display: block;
    text-transform: uppercase;
    margin-bottom: $dist/4;
  }

  i {
    margin-right: 0.5em;
  }

  & > ul {
    margin: -4px 0 0 0-4px;
    padding: 0;
    font-size: 0;

    li {
      display: inline-block;
      font-size: $fs;
      margin: 4px 0 0 4px;
      padding: $dist/4;
      border-radius: $radius;

      &:last-of-type {
        border: 1px solid $border-color;

        &:before {
          @include fontawesome($fa-var-info-circle);
          margin-right: 0.5em;
        }
      }
    }
  }
}

.calendar-entry {
  @extend %cf;
  @include resp-padding(v);

  &:nth-of-type(odd) {
    background: $box-color;
  }

  &--is-event {

  }

  &--is-annoucement {

  }

  &__location {
    margin-right: $dist/2;

    i {
      margin-right: 0.25em;
    }
  }

  &__category {
    i {
      margin-right: 0.25em;
    }
  }

  &__dates {
    float: left;
    width: 230px;
    margin-right: gutter(12);
    text-align: right;

    .date__day {
      display: block;
      font-weight: $fw-bold;
      font-size: $fs-medium;
      margin-top: $dist/4;
      padding-top: $dist/4;
      border-top: 1px dashed $border-color;

      &:first-of-type {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }
    }

    .date__time {
      display: block;
    }

    .dates__date {
      margin-top: $dist/4;
      padding-top: $dist/4;
      border-top: 1px dashed $border-color;

      &:first-of-type {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }
    }
  }

  &__details {
    float: left;
    width: calc(100% - 230px - #{gutter(12)});

    h3 {
      text-transform: uppercase;
      margin-bottom: $dist/4;

      a {
        @extend %decent-link;
        font-weight: $fw-h-bold;

        &:before {
          @include fontawesome($fa-var-info-circle);
          margin-right: 0.25em;
        }
      }
    }

    & > p {
      margin-top: $dist/4;
    }

    & > a {
      margin-top: $dist/4;
    }
  }

  @include breakpoint(null, $bp-m) {
    .calendar-entry__dates {
      width: 100%;
      margin-right: 0;
      text-align: left;

      span {
        font-weight: $fw;
      }
    }

    .calendar-entry__details {
      width: 100%;
    }

    &:nth-of-type(odd) {
      margin-left: -$dist/4;
      margin-right: -$dist/4;
      padding-left: $dist/4;
      padding-right: $dist/4;
    }
  }
}