.school-pages {
  @include resp-margin(bottom, 1.5);

  &__schools {
    @extend %cf;
  }

  &__school {
    position: relative;
    float: left;
    width: span(6 of 12);
    @include resp-margin(top);

    &:nth-of-type(-n+2) {
      margin-top: 0;
    }

    &:nth-of-type(2n+2) {
      margin-left: gutter(12);
    }

    &:nth-of-type(2n+3) {
      clear: both;
    }

    &:hover {
      h3 {
        color: $ac;
      }
    }

    &--red {
      &:hover {
        h3 {
          color: $c4;
        }
      }

      .school-pages__school-details {
        &:after {
          background: $c4;
        }
      }
    }

    &--blue {
      &:hover {
        h3 {
          color: $c3;
        }
      }

      .school-pages__school-details {
        &:after {
          background: $c3;
        }
      }
    }

    &--green {
      &:hover {
        h3 {
          color: $c2;
        }
      }

      .school-pages__school-details {
        &:after {
          background: $c2;
        }
      }
    }

    &--light-green {
      &:hover {
        h3 {
          color: $c1;
        }
      }

      .school-pages__school-details {
        &:after {
          background: $c1;
        }
      }
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 0;
      opacity: 0;
      z-index: 10;
    }
  }

  &__school-image {
    width: span(3 of 6) + gutter(6);

    img {
      @include resp-img;
    }
  }

  &__school-details {
    @include center(v);
    z-index: 10;
    right: 0;
    margin-left: - gutter(6)*2;
    padding: $dist/2 $dist/2 $dist/2 $dist;
    width: span(3 of 6) + gutter(6)*2;
    border: 1px solid $border-color;
    background: #ffffff;

    &:after {
      @include center(v);
      left: -6px;
      z-index: 5;
      display: block;
      content: '';
      width: 12px;
      height: $dist*2.5;
      background: $hc;
    }

    &:before {
      position: absolute;
      z-index: 1;
      left: -1px;
      top: -1px;
      bottom: -1px;
      content: '';
      display: block;
      width: 18.15263%;
      background: #ffffff;
    }

    & > * {
      position: relative;
      z-index: 10;
    }

    h3 {
      text-transform: uppercase;
      transition: color $duration linear;

      &:after {
        display: block;
        content: '';
        margin: $dist/2-2px 0 $dist/2 0;
        height: 2px;
        width: $dist*2;
        background: $border-color;
      }
    }
  }

  @include breakpoint($bp-m, $bp-l) {
    &__school-details {
      margin-left: - gutter(6)*4;
      width: span(3 of 6) + gutter(6)*4;

      &:before {
        width: 30.89655%;
      }
    }
  }

  @include breakpoint($bp-s, $bp-m) {
    &__school {
      width: 100%;
      margin-left: 0 !important;

      &:nth-of-type(-n+1) {
        margin-top: 0;
      }

      &:nth-of-type(2) {
        @include resp-margin(top);
      }
    }

    &__school-details {
      margin-left: - gutter(6)*4;
      width: span(3 of 6) + gutter(6)*4;

      &:before {
        width: 30.89655%;
      }
    }
  }

  @include breakpoint(null, $bp-s) {
    &__school {
      width: 100%;
      margin-left: 0 !important;

      &:nth-of-type(-n+1) {
        margin-top: 0;
      }

      &:nth-of-type(2) {
        @include resp-margin(top);
      }
    }

    &__school-image {
      width: 100%;
    }

    &__school-details {
      @include center(f);
      width: calc(100% - #{$dist*2});
      margin-left: 0;
      border: 0;
      background: rgba(#ffffff, 0.8);

      &:before {
        display: none;
      }

      h3 {
        &:after {
          background: #ffffff;
        }
      }
    }
  }
}