.latest-events {
  text-align: right;

  & > a {
    @extend %button;
    @extend %button--neutral;
    @extend %button--small;
  }

  ul {
    @extend %cf;
    text-align: left;

    li {
       $grid-conf: (
         (null, $bp-s, 1, 2),
         ($bp-s, $bp-l, 2),
         ($bp-l, null, 4)
       );

      @include resp-gallery-layout($grid-conf);
    }
  }

  &__date {
    i {
      font-size: $fs-medium;
      margin-right: 0.25em;
    }

    .date__day {
      font-weight: $fw-bold;
      font-size: $fs-medium;
    }

    .date__time {
      display: block;
      margin-left: $fs-medium;
      padding-left: 0.60em;
      font-weight: $fw-bold;
    }
  }

  &__title {
    display: block;
    margin-top: 0.25em;
    text-transform: uppercase;
    margin-left: $fs-medium;
    padding-left: 0.60em;
    color: $ac;
    font-weight: $fw;

    a {
      text-decoration: none;

      &:after {
        @include fontawesome($fa-var-plus-square);
      }
    }
  }
}