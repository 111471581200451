html .userform {
  &-progress {
    @include resp-margin(bottom);

    & > p {
      margin-bottom: $dist/4;
    }

    .progress {
      background: $box-color;
      height: $dist/1.5;

      &-bar {
        background: $hc;
        height: $dist/1.5;
      }
    }

    & > nav {
      margin-top: -$dist/1.5;

      ul {
        font-size: 0;

        li {
          font-size: $fs;
          height: $dist/1.5;

          button {
            display: block;
            border: 0;
            outline: 0;
            padding: 0;
            margin: 0;
            height: $dist/1.5;
            min-width: $dist/1.5;
            text-align: center;
            background: $box-color;
            border-right: 1px solid $box-color-dark;
            border-left: 1px solid $box-color-dark;
            color: $fc;
          }

          &:first-of-type {
            button {
              border-left: 0 !important;
            }
          }

          &:last-of-type {
            button {
              border-right: 0 !important;
            }
          }

          &.viewed {
            button {
              cursor: pointer;
              border-right: 1px solid $hc-hover;
              border-left: 1px solid $hc-hover;
              background: $hc;
              color: #ffffff;
            }
          }

          &.current {
            button {
              border-right: 1px solid $box-color-dark;
              border-left: 1px solid $hc-hover;
              background: $box-color;
              color: $fc;
            }
          }
        }
      }
    }
  }

  legend.left {
    font-weight: $fw-bold;
    margin-bottom: $dist/4;
  }

  .right-title {
    margin-top: $dist/4;
    font-style: italic;
    color: $fc-light;
  }

  fieldset.field {
    margin-bottom: $dist/2;
  }

  .FormHeading {
    margin-bottom: $dist/2;
  }

  .step-navigation {
    ul {
      @extend %cf;
      font-size: 0;

      li {
        font-size: $fs;

        button {
          @extend %button;
          @extend %button--small;
          @extend %button--neutral;

          &.step-button-prev:not([style="display: none;"]) {
            margin-right: $dist/4;
          }

          &.step-button-prev {
            font-size: 0;

            &:before {
              font-size: $fs;
            }

            &:after {
              display: inline;
              content: 'zurück';
              font-size: $fs;
            }
          }

          &.step-button-next {
            font-size: 0;

            &:after {
              font-size: $fs;
            }

            &:before {
              display: inline;
              content: 'weiter';
              font-size: $fs;
            }
          }
        }
      }
    }
  }

  .userformsgroup {
    margin-top: $dist/2;
    border: 1px solid $border-color;
    border-radius: $radius;
    @include resp-padding(v);
    @include resp-padding(h);

    & > legend {
      padding-left: $dist/4;
      padding-right: $dist/4;
      margin-left: -$dist/4;
      border: 0;
      width: auto;
      font-weight: $fw-bold;
    }
  }

  .step-button-wrapper.Actions {
    margin-top: 0;
    float: right;
  }
}