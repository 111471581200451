.page-media {
  @include resp-margin(top);

  &__images {
    @extend %cf;
    margin: 0;
    padding: 0;

    li {
      list-style: none !important;

      $grid-conf: (
         (null, $bp-m, 2),
         ($bp-m, $bp-l, 3),
         ($bp-l, null, 6)
       );

      @include resp-gallery-layout($grid-conf);

      a {
        display: block;
        @include image-hover();

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    & + .page-media__files {
      @include resp-margin(top);
    }
  }
}