#Form_SearchForm {
  font-size: $fs;

  .system-message + *, :not([style='display: none']).message + * {
    margin-top: 0 !important;
  }
}

.search-results {
  &--dashboard { }

  &__list {
    & > h2 {
      color: $hc;
      text-transform: uppercase;
      @include resp-margin(bottom);
    }
  }

  &__all-from-type {
    margin-top: $dist/2;
    @extend %button;
  }

  &__item {
    @include resp-margin(bottom);
    @include resp-padding(bottom);
    border-bottom: 1px solid $border-color;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
      line-height: 1;
      text-transform: uppercase;
      font-weight: $fw-h-bold;

      a {
        line-height: 1;
        color: $fc;
        text-decoration: none;
        font-weight: $fw-h-bold;

        &:hover {
          color: $ac;
        }
      }
    }

    & > p {
      margin-top: $dist/2;
    }
  }

  &__query-instance {
    display: inline-block;
    padding: 0.25em 0.5em;
    background: $box-color;
    font-style: italic;
  }

  &__read-more {
    margin-top: $dist/2;

    @extend %button;
    @extend %button--small;
    @extend %button--neutral;
  }
}