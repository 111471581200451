.module--gallery {
  .gallery {
    margin: 0;
    padding: 0;
    @extend %cf;

    li {
      display: block;
      float: left;

      a {
        @include image-hover();

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    &--ipr-2 {
      li {
        $grid-conf: (
          (null, $bp-xs, 1),
          ($bp-xs, null, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-3 {
      li {
        $grid-conf: (
          (null, $bp-xs, 1),
          ($bp-xs, $bp-m, 2),
          ($bp-m, null, 3)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-4 {
      li {
        $grid-conf: (
          (null, $bp-xs, 1),
          ($bp-xs, $bp-m, 2),
          ($bp-m, null, 4)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-6 {
      li {
        $grid-conf: (
          (null, $bp-xs, 1),
          ($bp-xs, $bp-s, 2),
          ($bp-s, $bp-l, 4),
          ($bp-l, null, 6)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }
  }
}