html {
  font-size: $fs-root;

  @include breakpoint(null, $bp-s) {
    font-size: $fs-root - 2px;
  }
}

body {
  font-family: $ff;
  font-weight: $fw;
  font-size: $fs;
  color: $fc;
  text-size-adjust: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $ff-h;
  font-weight: $fw-h-bold;
}

h1 {
  font-size: $fs-h1;
}

h2 {
  font-size: $fs-h2;
}

h3 {
  font-size: $fs-h3;
}

h4 {
  font-size: $fs-h4;
}

h5, h6 {
  font-size: $fs-h5;
}

h6 {
  font-size: $fs-h6;
}

// - Bold elements
strong, b, th {
  font-weight: $fw-medium;
}

// - Small text
small {
  font-size: $fs-small;
}

// - Lineheight
h1, h2, h3, h4, h5, h6,
strong, p, b, em, i, span, a, small, th, td, li {
  line-height: $line-height;
}

// - Blockqoute
blockquote {
  padding: $dist/4 0 $dist/4 $dist;
  border-left: 1px solid $border-color;
  color: $fc-light;
}

// - Icons
i.fa,
i.fal,
i.far,
i.fab {
  display: inline-block;
  width: 1em;
  text-align: center;
  font-style: normal;
}

// - Links
a {
  color: $ac;
  text-decoration: underline;

  &:hover {
    color: $ac-hover;
  }
}

// - Text Selection
::selection {
  color: #ffffff;
  background: $hc;
}

::-moz-selection {
  color: #ffffff;
  background: $hc;
}

// - Lists
ul.list--checklist {
  list-style: none;
  margin-left: 0 !important;

  li {
    display: block;
    @include icon-before($fa-var-check);
  }
}

// - Discreet text
.text--discreet {
  color: $fc-light !important;
}

// - Text highlight
.text--highlight {
  color: $hc;
}

// - Hinterlegung
.text--background {
  @include resp-padding(v, 0.5);
  @include resp-padding(h, 0.5);
  background: $hc;
  color: #ffffff;
}

.text--background-discreet {
  @include resp-padding(v, 0.5);
  @include resp-padding(h, 0.5);
  background: $box-color;
}

// - Horizontal lines
hr {
  border: none;
  height: 1px;
  background: $border-color;
  @include resp-margin(v);
}

// - Italic
em, i {
  font-style: italic;
}

// - Messages
.system-message {
  & + * {
    margin-top: $dist !important;

    @include breakpoint(null, $bp-m) {
      margin-top: $dist/2 !important;
    }
  }

  & + .system-message {
    margin-top: $dist/6 !important;
  }

  &,
  &--neutral {
    cursor: pointer;
    position: relative;
    border-left: 5px solid $border-color;
    padding: $dist/2 $dist $dist/2 $dist/2;
    background: rgba($border-color, 0.1);

    &:after {
      @include fontawesome($fa-var-times);
      position: absolute;
      right: $dist/4;
      top: $dist/4;
      opacity: 0.5;
      transition: all $duration linear;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  &--good {
    border-color: $valid-color;
    background: rgba($valid-color, 0.1);
  }

  &--warning {
    border-color: $warning-color;
    background: rgba($warning-color, 0.1);
  }

  &--bad {
    border-color: $invalid-color;
    background: rgba($invalid-color, 0.1);
  }

  &--permanent {
    cursor: default;

    &:after {
      display: none;
    }
  }
}

.message:not([style*="display: none"]) {
  @extend .system-message;
  @extend .system-message--permanent;

  &.bad,
  &.error,
  &.required {
    @extend .system-message--bad;
  }

  &.good,
  &.success {
    @extend .system-message--good;
  }

  &.warning {
    @extend .system-message--warning;
  }
}

// - Distance
.dist-right {
  margin-right: 0.25em;
}

.dist-left {
  margin-left: 0.25em;
}

.dist-top {
  margin-top: 0.25em;
}

.dist-bottom {
  margin-bottom: 0.25em;
}

img {
  &.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &.left {
    float: left;
    @include resp-margin(right, 0.5);
    @include resp-margin(bottom, 2);
  }

  &.right {
    float: right;
    @include resp-margin(left, 0.5);
    @include resp-margin(bottom, 2);
  }
}

.main__text {
  img {
    &.is-resp {
      @include resp-img;
    }
  }

  br {
    line-height: 1;
  };

  .containes-floated-img {
    & + * {
      margin-top: 0;
    }

    & + * + *{
      clear: both;
    }
  }

  & > *:last-child {
    &.containes-floated-img {
      @extend %cf;
    }
  }

  & + .modular-content,
  & + .job-descriptions {
    @include resp-margin(top);
  }

  & + .modular-content-wrapper {
    padding-top: 0;
  }
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

textarea {
  resize: vertical;
}

a {
  transition: color $duration linear;
  font-weight: $fw;
}

.global-sc-dist-right {
  margin-right: 0.5em;
}

.global-sc-dist-left {
  margin-left: 0.5em;
}

.global-sc-dist-top {
  margin-top: 0.5em;
}

.global-sc-dist-bottom {
  margin-bottom: 0.5em;
}