#UserDefinedForm {
	.main {
		@extend %cf;
	}

	.main__text {
		float: left;
		width: span(6 of 12);
		margin-right: gutter(12);

		& + #UserForm_Form {
			margin-top: $dist;

			@include breakpoint($bp-m, null) {
				margin-top: 0;
			}

			float: left;
			width: span(6 of 12);
			clear: none;
		}
	}

	@include breakpoint(null, $bp-m) {
		.main__text {
			width: 100%;
			margin-right: 0;

			& + #UserForm_Form {
				width: 100%;
			}
		}
	}
}