.module--downloads {
  ul {
    margin: 0;
    padding: 0;

    li {
      @extend %cf;
      list-style: none !important;
      display: block;
      border-bottom: 1px dashed $border-color;
      margin-bottom: $dist/4;
      padding-bottom: $dist/4;
      text-transform: uppercase;

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }

      & > a {
        display: block;
        width: calc(100% - 150px);
        float: left;
        color: $fc;
        text-decoration: none;

        &:hover {
          color: $ac;
        }

        i {
          margin-right: 0.5em;
          color: $fc-light;
        }
      }

      span {
        display: block;
        width: 150px;
        text-align: right;
        float: right;

        a {
          color: $ac;
          text-decoration: none;

          &:hover {
            color: $ac-hover;
          }
        }
      }
    }
  }
}