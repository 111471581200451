// - Table
.table-box-wrapper,
.table-scroll-wrapper {
  border-radius: $radius;
  overflow: hidden;
  border: 1px solid $border-color;

  td,
  th {
    &:last-of-type {
      border-right: 0;
    }
  }

  table:not(.table--resp) {
    tbody {
      tr:last-of-type {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  .table--resp {
    tbody {
      tr:last-of-type {
        td:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  float: none;

  tr {
    position: relative;

    th {
      text-align: left;
      font-weight: $fw-bold;
    }

    th,
    td {
      padding: $dist/4;
      border-bottom: 1px solid $border-color;
      border-right: 1px solid $border-color;
    }
  }

  &.table--unstyled {
    width: auto;
    border-collapse: collapse;
    border-spacing: 0;
    float: none;
    border: 0;

    tr {
      th {
        text-align: left;
        font-weight: $fw;
      }

      th,
      td {
        padding: 0 $dist/2 0 0;
        border-bottom: 0;
        border-right: 0;

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }

  &.table--resp {
    border-bottom: 0;

    td,
    th {
      border-right: 0;
    }
  }
}

.table--no-head {
  tr {
    &:nth-of-type(even) {
      background: $box-color;
    }
  }
}

.table--head-left.table--resp,
.table--head-top {
  thead {
    tr:first-of-type {
      background: $box-color;
    }
  }

  tbody {
    tr:nth-of-type(even) {
      background: $box-color;
    }
  }

  &.table--resp {
    tr {
      td,
      th {
        &:before {
          font-size: $fs-small;
        }
      }
    }
  }
}

.table--head-left,
.table--head-top {
  &.table--resp {
    td:empty,
    th:empty {
      visibility: hidden;
      opacity: 0;
      padding: 0;
      border: 0;

      &:before {
        display: none;
      }
    }
  }
}