.page-title {
  @include resp-margin(bottom);

  h1 {
    line-height: 1;
    text-transform: uppercase;
  }

  .breadcrumbs {
    margin-bottom: $dist/4;
    color: $fc-light;

    a {
      color: $fc-light;
      font-weight: $fw-light;

      &:hover {
        color: $ac;
      }
    }
  }
}