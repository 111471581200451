.module--grid {
  .grid {
    @extend %cf;

    &__item {
      display: block;
      float: left;
    }

    &__header {
      text-align: center;
      margin-bottom: $dist/2;

      img {
        display: inline-block;
        width: 100%;
        height: auto;
      }

      i {
        font-size: 2.5rem;
      }

      a {
        color: $fc;

        &:hover {
          color: $ac;
        }
      }

      &.grid__header--image {
        a {
          &[data-lightbox] {
            @include image-hover();
          }

          &:not([href^='/assets']) {
            @include image-hover($fa-var-long-arrow-right);
          }
        }
      }
    }

    &__title {
      margin-bottom: $dist/2;
      text-transform: uppercase;
    }

    &__link {
      display: inline-block;
      margin-top: $dist/2;
      text-decoration: none;
      @extend %button;
      @extend %button--small;
    }

    &--align-center {
      text-align: center;
    }

    &--ipr-2 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, null, 2, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-3 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 3, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-4 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 4, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }
  }
}