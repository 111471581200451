.navigation {
  position: relative;
  z-index: 500;

  // - default
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      line-height: 1;
      font-size: $fs;

      &[class*="--current"],
      &[class*="--section"],
      &:hover {
        & > a {
          color: $ac;
        }
      }

      a {
        display: block;
        color: $fc;
        text-decoration: none;
        line-height: 1;
        transition: color linear $duration;

        &[href="javascript:void(0);"] {
          cursor: default;
        }
      }
    }
  }

  &:not(.navigation--mobile) .navigation__sub {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s linear, visibility 0.1s linear;
    position: absolute;
    background: $footer-light;
    padding: $dist/3 0;

    a {
      padding: $dist/3 $dist/2;
      font-weight: $fw-light;
    }
  }

  // - vertical navigation
  &--vertical {
    .navigation__sub {
      transform: translateX(100%);
      top: 0;
      right: 0;
    }

    ul {
      li {
        &:hover {
          & > .navigation__sub {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  // - horizontal navigation
  &--horizontal {
    .navigation__top {
      font-size: 0;
    }

    .navigation__top-link {
      display: inline-block;
      margin-right: $dist/2;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        & > .navigation__sub {
          opacity: 1;
          visibility: visible;
          top: $fs;
        }
      }

      & > .navigation__sub {
        left: 0;

        .navigation__sub {
          transform: translateX(100%);
          top: 0;
          right: 0;
        }
      }

      .navigation__sub {
        min-width: 100%;

        li {
          &:hover {
            & > .navigation__sub {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      &:nth-last-of-type(-n+2) {
        & > .navigation__sub {
          left: auto;
          right: 0;

          .navigation__sub {
            transform: translateX(-100%);
            right: auto;
            left: 0;
          }
        }
      }
    }
  }

  .mobile-navigation-button {
    position: relative;
    bottom: -1px;
    display: none;
    cursor: pointer;
    color: #ffffff;
    text-transform: uppercase;
    height: 45px;
    line-height: 45px;
    transition: color $duration linear;

    &:hover {
      color: $ac;
    }

    span {
      display: none;

      &:first-of-type {
        display: inline;
      }
    }

    i {
      margin-right: $dist/2;
    }
  }

  &--main:not(.navigation--mobile) {
    @extend %outer-container;
    display: flex;
    background: $footer-dark;
    @extend %cf;

    .navigation__home-link {
      margin-right: $dist/2;
      width: 76px;
      flex-shrink: 0;
      float: left;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: $dist 0;
        background: $footer-light;
        text-decoration: none;
        text-align: center;
        color: #ffffff;
        line-height: 1;
        transition: color linear $duration;

        &:hover {
          color: $ac;
        }
      }

      &--current {
        a {
          color: $ac;
        }
      }
    }

    .navigation__top {
      float: left;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-around;
      padding-right: $dist/2;
    }

    li {
      &[class*="--current"],
      &[class*="--section"],
      &:hover {
        & > a {
          color: $ac;
        }
      }
    }

    .navigation__top-link {
      display: block;
      font-size: $fs;
      margin-right: 0;

      a {
        color: #ffffff;
      }

      & > a {
        height: 100%;
        min-width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: $dist 0;
        text-transform: uppercase;
        border-right: calc(#{$dist} * 0.5) solid transparent;
        border-left: calc(#{$dist} * 0.5) solid transparent;
        color: #ffffff;
      }

      &:hover > a,
      &[class*="--current"] > a,
      &[class*="--section"] > a {
        background: $hc;
        border-right-color: $hc;
        border-left-color: $hc;
        color: #ffffff !important;

        &:before {
          background: $hc;
        }
      }

      &.no-color {
        &:hover > a,
        &[class*="--current"] > a,
        &[class*="--section"] > a {
          color: #ffffff !important;
        }

        .navigation__sub {
          border-top-color: $ac !important;

          &::before {
            color: $ac !important;
          }

          .navigation__sub-link {
            &:hover {
              & > a {
                color: $ac !important;
              }
            }
          }
        }
      }

      &.color-light-green {
        &:hover > a,
        &[class*="--current"] > a,
        &[class*="--section"] > a {
          background: $hc;
          border-right-color: $hc;
          border-left-color: $hc;
          color: #ffffff !important;
        }
      }

      &.color-red {
        &:hover > a,
        &[class*="--current"] > a,
        &[class*="--section"] > a {
          background: $c4;
          border-right-color: $c4;
          border-left-color: $c4;
          color: #ffffff !important;
        }
      }

      &.color-orange {
        &:hover > a,
        &[class*="--current"] > a,
        &[class*="--section"] > a {
          background: $c5;
          border-right-color: $c5;
          border-left-color: $c5;
          color: #ffffff !important;
        }
      }

      &.color-blue {
        &:hover > a,
        &[class*="--current"] > a,
        &[class*="--section"] > a {
          background: $c3;
          border-right-color: $c3;
          border-left-color: $c3;
          color: #ffffff !important;
        }
      }

      &.color-green {
        &:hover > a,
        &[class*="--current"] > a,
        &[class*="--section"] > a {
          background: $c2;
          border-right-color: $c2;
          border-left-color: $c2;
          color: #ffffff !important;
        }
      }

      & > .navigation__sub {
        border-top: 2px solid $hc-hover;

        &:before {
          @include fontawesome($fa-var-caret-up, solid);
          color: $hc-hover;
          position: absolute;
          top: -($dist/3 + 2px);
          left: $dist/2 - 3px;
          text-align: left;
          vertical-align: bottom;
        }

        .navigation__sub {
          border-left: 2px solid $footer-dark;
          background: lighten($footer-light, 5%);
        }
      }

      &:nth-last-of-type(-n+2) {
        & > .navigation__sub {
          left: auto;
          right: 0;

          &:before {
            right: $dist/2 - 3px;
            left: auto;
            text-align: right;
          }

          .navigation__sub {
            border-left: 0;
            border-right: 2px solid $footer-dark;
            transform: translateX(-100%);
            right: auto;
            left: 0;
          }
        }
      }
    }

    &.navigation--horizontal {
      .navigation__top-link {
        &:hover {
          & > .navigation__sub {
            top: unset;
            bottom: 0;
            transform: translateY(100%);
          }
        }
      }
    }

    @include breakpoint(null, $bp-l) {
      .navigation__home-link {
        margin-right: $dist/2;
        width: 45px;

        a {
          padding: 0;
          height: 45px;
          line-height: 45px;
        }
      }

      .navigation__top {
        width: calc(100% - #{$dist/2 + 45px});
        bottom: 0;
        padding-right: $dist/2;
      }

      .navigation__top-link {
        & > a {
          padding: 0;
          line-height: 45px;
          height: 45px;
        }
      }
    }

    @include breakpoint(null, $bp-nav) {
      .mobile-navigation-button {
        display: block;
      }

      .navigation__top {
        display: none;
      }
    }
  }

  &--mobile {
    $page-bg: $box-color-dark;

    // - overwrite defaults
    ul {
      li {
        @extend %cf;

        &:hover {
          & > .navigation__sub {
            display: none;
            transform: translateX(0);
          }
        }
      }
    }

    .navigation__sub {
      clear: both;
      position: static !important;
      display: none;
      border-top: 1px solid $border-color;
      opacity: 1 !important;
      visibility: visible !important;
      transform: none !important;
      background: $page-bg;

      &--active {
        display: block !important;
      }

      .navigation__sub-link {
        @extend %cf;
        border-bottom: 1px solid $border-color;

        &--has-sub {
          & > a {
            float: left;
            width: calc(100% - 1em - #{$dist/2});
          }

          & > .navigation__sub-toggle {
            padding: $dist/4;
            width: calc(1em + #{$dist/2});
          }
        }

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }

    .navigation__sub-toggle {
      display: block;
      float: left;
      cursor: pointer;
      color: $fc-light;
      line-height: 1;

      &:hover,
      &--active {
        color: $fc;
      }

      &--active {
        i:before {
          content: fa-content($fa-var-minus-circle);
        }
      }
    }

    // - mobile navigation styling | level 1
    .navigation__top-link {
      border-bottom: 1px solid $border-color;

      & > a {
        padding: $dist/2;
        font-weight: $fw-bold;
      }

      &--has-sub {
        & > a {
          float: left;
          width: calc(100% - 1em - #{$dist/2});
        }
      }

      & > .navigation__sub-toggle {
        padding: $dist/2 $dist/4;
        width: calc(1em + #{$dist/2});
      }

      // - level 2
      & > .navigation__sub {
        a {
          padding: $dist/4 $dist/2;
        }

        & > li {
          // - level 3
          & > .navigation__sub {
            background: darken($page-bg, 10%);

            & > li {
              & > a {
                background: darken($page-bg, 10%);
              }

              & > .navigation__sub-toggle {
                background: darken($page-bg, 10%);
              }

              // - level 4
              & > .navigation__sub {
                background: darken($page-bg, 20%);

                & > li {
                  & > a {
                    background: darken($page-bg, 20%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.mobile-navigation--open {
  .mobile-navigation-button {
    span {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: inline;
      }
    }
  }
}