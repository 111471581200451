.department {
  @extend %cf;
  @include resp-margin(bottom);
  @include resp-padding(bottom);
  border-bottom: 1px solid $border-color;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }

  &__image {
    width: span(3 of 12);
    float: left;
    margin-right: gutter(12);

    a {
      @include image-hover($fa-var-long-arrow-right);
    }

    img {
      @include resp-img;
    }
  }
  
  &__info {
    width: span(9 of 12);
    float: left;

    & > h2 {
      a {
        @extend %decent-link;
        font-weight: $fw-h-bold;
        text-transform: uppercase;
      }

      & + p {
        margin-top: $dist/2;
      }
    }

    & > p,
    & > h2 {
      & + .basic-holder__grand-children {
        margin-top: $dist/2 !important;
      }
    }
  }
}