.basic-holder {
  &__child {
    @include resp-margin(bottom);
    @include resp-padding(bottom);
    border-bottom: 1px solid $border-color;

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    & > h3 {
      a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $fw-h-bold;
      }
    }

    & > p,
    & > h3 {
      & + .basic-holder__grand-children {
        margin-top: $dist/2 !important;
      }
    }
  }

  &__grand-children {
    li {
      a {
        @extend %decent-link;
      }
    }
  }
}