.main__text + form {
  @include resp-margin(top);
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $fc-light;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $fc-light;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $fc-light;
}
:-moz-placeholder { /* Firefox 18- */
  color: $fc-light;
}


form {
  input,
  textarea,
  select {
    font-family: inherit;
    font-weight: $fw-light;
    font-style: inherit;
    font-size: inherit;
    color: inherit;
    border: 1px solid $border-color;
    outline: 0;
    background: transparent;

    &:not([type='checkbox']):not([type='radio']):not([type="submit"]):not([type="reset"]) {
      display: block;
      padding: $dist/4;
      width: 100%;
    }

    &:focus {
      outline: 0;
      border: 1px solid $hc;
    }

    textarea {
      resize: horizontal;
    }
  }

  input[type="submit"],
  input[type="reset"] {
    width: auto;
    font-size: $fs;
    @extend %button;
    padding: 0 $dist/2;
  }

  input[type="reset"] {
    @extend %button--neutral;
  }

  .clear {
    margin-top: -$dist/2;
  }

  .field {
    margin-bottom: $dist/2;

    &:last-of-type {
      margin-bottom: 0;
    }

    .middleColumn {
      @extend %cf;
    }

    & > label {
      &.left {
        display: block;
        margin-bottom: $dist/4;
        font-weight: $fw-bold;
        text-transform: uppercase;
      }
    }

    .message {
      display: block;
      margin-top: $dist/4;

      &.required {
        color: $invalid-color;
      }
    }

    .description {
      display: block;
      font-size: $fs-small;
    }
  }

  .Actions {
    margin-top: $dist/2;
  }

  .progress-title {
    text-transform: uppercase;
  }
}

#UserForm_Form {
  .clear {
    margin-top: 0;
  }
}

#MemberLoginForm_LoginForm {
  #ForgotPassword {
    margin-top: $dist/4;
  }
}

div.field {
  position: relative;

  &.required,
  &[aria-required="true"] {
    & > label {
      &:after {
        content: ' *';
        color: $invalid-color;
      }
    }
  }

  small.error {
    position: absolute;
    right: $dist/3;
    top: $dist/4;
    z-index: 10;
    font-size: $fs;
    font-weight: $fw-bold;
    color: #ffffff;
    background: $invalid-color;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    border-radius: 100%;
  }

  legend.left {
    text-transform: uppercase;
  }

  .userformsgroup {
    & > legend {
      text-transform: uppercase;
    }
  }
}

html button {
  &.step-button-next {
    &:before,
    &:after {
      font-size: $fs-small !important;
    }
  }

  &.step-button-prev {
    &:before,
    &:after {
      font-size: $fs-small !important;
    }

    &:after {
      font-family: $ff !important;
      font-weight: $fw !important;
      margin-left: 0 !important;
      background: none !important;
    }

    padding-left: 0 !important;
    padding-right: $dist/2 !important;

    &:before {
      font-size: $fs-small;
      @include fontawesome($fa-var-long-arrow-left);
      background: $ac-sec-hover;
      width: $dist;
      height: $dist;
      line-height: $dist;
      text-align: center;
      margin-right: $dist/2;
      transition: background linear $duration;
    }
  }
}