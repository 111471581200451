// ------
// - Grid
// ------

$max-width:                       1170px;
$outer-width:                     1600px;
$column-width:                    70px;
$gutter-width:                    30px;
$columns:                         12;

// --------
// - Größen
// --------

$dist:                            $gutter-width;
$mobilenav-width:                 270px;
$radius:                          0;
$duration:                        0.2s;
$line-height:                     1.35em;
$line-height-int:                 1.35;

// --------
// - Farben
// --------

$c1: #aac858;   // bsz
$c2: #5da55b;   // wirtschaft
$c3: #488ccb;   // fos
$c4: #eb6446;   // berufsschule
$c5: #de8530;   // berufsfachschule

// Text & Highlights
$fc:                              #575757;
$fc-light:                        #acacac;
$hc:                              $c1;
$hc-hover:                        darken($hc, 15%);
$ac:                              $c1;
$ac-hover:                        darken($ac, 15%);
$ac-sec:                          #4b4b4b;
$ac-sec-hover:                    darken($ac-sec, 15%);

// Borders & Boxes
$border-color:                    #dcdcdc;
$border-color-dark:               darken($border-color, 15%);
$box-color:                       #f4f4f4;
$box-color-dark:                  #e8e8e8;
$page-bg:                         #ffffff;
$footer-light:                    #3a3a3a;
$footer-dark:                     #303030;

// Misc
$shadow-color:                    rgba(0, 0, 0, 0.15);
$shadow-color-hover:              rgba(0, 0, 0, 0.35);
$valid-color:                     $c2;
$warning-color:                   #e0c459;
$invalid-color:                   $c4;

// -------
// - Fonts
// -------

// Family
$ff:                              'basic-sans', 'Inter', sans-serif;
$ff-h:                            $ff;

// Size
$fs-root:                         16px;
$fs-small:                        12px / $fs-root + rem;
$fs:                              16px / $fs-root + rem;
$fs-medium:                       20px / $fs-root + rem;
$fs-big:                          24px / $fs-root + rem;
$fs-huge:                         28px / $fs-root + rem;

// Headline Size
$fs-h1:                           $fs-huge;
$fs-h2:                           $fs-big;
$fs-h3:                           $fs-medium;
$fs-h4:                           $fs-medium;
$fs-h5:                           $fs;
$fs-h6:                           $fs;

// Weight
$fw-light:                        300;
$fw:                              400;
$fw-medium:                       700;
$fw-bold:                         700;
$fw-h-light:                      300;
$fw-h:                            400;
$fw-h-medium:                     700;
$fw-h-bold:                       700;

// -------------
// - Breakpoints
// -------------

$bp-xs:                           350px;
$bp-s:                            480px;
$bp-m:                            768px;
$bp-l:                            1036px;
$bp-xl:                           $max-width + $dist*2;
$bp-nav:                          1036px;

// ------
// - Susy
// ------

$susy: (
  'columns': susy-repeat(12),
  'gutters': $gutter-width/$column-width,
  'spread': 'narrow',
  'container-spread': 'narrow',
);