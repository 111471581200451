#HomePage {
  .latest-events {
    @include resp-margin(top, 1.5);
  }
}

#HomePage .main__text,
.announcement__text {
  @include resp-margin(bottom, 1.5);
  @include resp-padding(v);
  @include resp-padding(h);
  @extend %outer-container;
  background: $invalid-color;
  color: #ffffff;

  a {
    color: #ffffff;
    text-decoration: underline;

    &.button {
      text-decoration: none;
      background: darken($invalid-color, 25%);

      &:after {
        background: darken($invalid-color, 35%);
      }

      &:hover {
        background: darken($invalid-color, 35%);

        &:after {
          background: darken($invalid-color, 45%);
        }
      }
    }
  }
}