.module--textimage {
  .module__inner {
    .textimage__image a,
    a.textimage__image {
      display: block;

      &[data-lightbox] {
        @include image-hover();
      }

      &:not([data-lightbox]) {
        @include image-hover($fa-var-long-arrow-right);
      }
    }

    img:not(.center):not(.left):not(.right):not(.leftAlone) {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .textimage {
    @extend %cf;

    &__title {
      text-transform: uppercase;
    }

    div.textimage__image {
      &:after {
        position: relative;
        z-index: 5;
        display: block;
        content: '';
        height: 6px;
        width: $dist*12;
        background: $hc;
        margin: -3px auto 0 auto;
      }
    }

    &--image-top {
      .textimage__image {
        @include resp-margin(bottom);
      }
    }

    &--image-bottom {
      .textimage__image {
        @include resp-margin(top);
      }
    }

    &--image-left,
    &--image-right {
      .textimage__image {
        width: span(3 of 12);

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      div.textimage__image:after {
          width: $dist*6;
      }

      .formatted-text {
        width: span(9 of 12);
        float: left;
      }

      @include breakpoint(null, $bp-s) {
        .textimage__image {
          float: none !important;
          margin: 0;
          width: 100%;

          & + .formatted-text {
            margin-top: $dist/2 !important;
          }
        }

        .formatted-text {
          float: none;
          margin: 0;
          width: 100%;

          & + .textimage__image {
            margin-top: $dist/2 !important;
          }
        }
      }

      @include breakpoint($bp-s, $bp-m) {
        .textimage__image {
          width: span(6 of 12);
        }

        .formatted-text {
          width: span(6 of 12);
        }
      }
    }

    &--image-left {
      .textimage__image {
        float: left;
        margin-right: gutter(12);
      }
    }

    &--image-right {
      .textimage__image {
        float: right;
        margin-left: gutter(12);
      }
    }

    &--text-floating {
      .formatted-text {
        width: 100%;
      }
      
      .textimage__image {
        display: block;
        float: left;
        margin-bottom: $dist/4;

        @include breakpoint(null, $bp-s) {
          margin: 0 !important;
          margin-bottom: $dist/2 !important;
        }
      }

      &.textimage--image-right {
        .textimage__image {
          float: right;
        }
      }
    }
  }
}