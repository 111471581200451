.section-heading--latest-news {
  @include resp-margin(bottom, 1, $bp-m, !important);
  @include resp-margin(top, 1, $bp-m, !important);
  
}

.latest-news {
  width: 100%;
  max-width: $outer-width;
  margin-left: auto;
  margin-right: auto;
  background: $box-color;

  .outer-container {
    @include resp-padding(v, 1.5);
    &:after {
      content: '';
      display: block;
      height: 23px;
      width: 23px;
    }

    &--read-on {
      padding: 0;
      @include resp-padding(bottom, 1.5);
      @include resp-padding(top);
      margin-top: -23px;
      text-align: right;
      border-top: 1px solid #ffffff;

      &:after {
        display: none;
        content: '';
      }
    }
  }

  & > a {
    @include resp-margin(top);
  }

  &__children {
    @extend %cf;
  }

  &__child {
    position: relative;

   $grid-conf: (
     (null, $bp-s, 1, 2),
     ($bp-s, $bp-m, 2),
     ($bp-m, null, 3)
   );

    @include resp-gallery-layout($grid-conf);

    @include breakpoint(null, $bp-m) {
      margin-bottom: $dist;
    }

    background: #ffffff;
    text-align: center;

    & > a {
      &:first-of-type {
        display: block;
        @include resp-margin(bottom, 1);
        @include image-hover($fa-var-long-arrow-right);
      }

      &:last-of-type {
        @include center(h);
        bottom: -23px;
      }
    }

    img {
      @include resp-img;
    }

    h3 {
      @include resp-padding(h);
      text-align: left;

      a {
        @extend %decent-link;
        text-transform: uppercase;
        font-weight: $fw-bold;
      }

      &:after {
        display: block;
        content: '';
        height: 2px;
        width: $dist*2;
        margin-bottom: -$dist/2;
        margin-top: $dist/4;
        background: $border-color;
      }

      @include breakpoint(null, $bp-m) {
        margin-bottom: $dist/2;
      }
    }

    p {
      text-align: left;
      @include resp-padding(v);
      @include resp-padding(h);
      padding-bottom: 23px + $dist;

      @include breakpoint(null, $bp-m) {
        padding-bottom: 23px + $dist/2;
      }
    }
  }
}