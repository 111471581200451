.loader {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 200;
	background: rgba(#ffffff, 0.8);

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -20px 0 0 -25px;
		display: block !important;
		width: 50px;
		height: 40px;
		text-align: center;
		font-size: 10px;

		& > div {
			display: inline-block;
			height: 100%;
			width: 4px;
			background: $hc;
			-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
			animation: sk-stretchdelay 1.2s infinite ease-in-out;
		}

		.rect2 {
			-webkit-animation-delay: -1.1s;
			animation-delay: -1.1s;
		}

		.rect3 {
			-webkit-animation-delay: -1.0s;
			animation-delay: -1.0s;
		}

		.rect4 {
			-webkit-animation-delay: -0.9s;
			animation-delay: -0.9s;
		}

		.rect5 {
			-webkit-animation-delay: -0.8s;
			animation-delay: -0.8s;
		}
	}
}

@-webkit-keyframes sk-stretchdelay {
	0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
	20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	} 20% {
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
   }
}