.color-red {
  ::selection {
    background: $c4;
  }

  ::-moz-selection {
    background: $c4;
  }

  .change-color {
    color: $c4 !important;
  }

  .change-color-dark {
    color: darken($c4, 15%) !important;
  }

  .change-color-hover:hover {
    color: $c4 !important;
  }

  .change-bg {
    background: $c4 !important;
  }

  .change-bg-hover:hover {
    background: darken($c4, 15%) !important;
  }

  .change-bg-dark {
    background: darken($c4, 15%) !important;
  }

  .change-color-hover-dark:hover {
    color: darken($c4, 15%) !important;
  }

  .change-border {
    border-color: $c4 !important;
  }

  .change-border-top {
    border-top-color: $c4 !important;
  }
}

.color-blue {
  ::selection {
    background: $c3;
  }

  ::-moz-selection {
    background: $c3;
  }

  .change-color {
    color: $c3 !important;
  }

  .change-color-dark {
    color: darken($c3, 15%) !important;
  }

  .change-color-hover:hover {
    color: $c3 !important;
  }

  .change-color-hover-dark:hover {
    color: darken($c3, 15%) !important;
  }

  .change-bg {
    background: $c3 !important;
  }

  .change-bg-dark {
    background: darken($c3, 15%) !important;
  }

  .change-bg-hover:hover {
    background: darken($c3, 15%) !important;
  }

  .change-border {
    border-color: $c3 !important;
  }

  .change-border-top {
    border-top-color: $c3 !important;
  }
}

.color-green {
  ::selection {
    background: $c2;
  }

  ::-moz-selection {
    background: $c2;
  }

  .change-color {
    color: $c2 !important;
  }

  .change-color-dark {
    color: darken($c2, 15%) !important;
  }

  .change-color-hover:hover {
    color: $c2 !important;
  }

  .change-color-hover-dark:hover {
    color: darken($c2, 15%) !important;
  }

  .change-bg-dark {
    background: darken($c2, 15%) !important;
  }

  .change-bg {
    background: $c2 !important;
  }

  .change-bg-hover:hover {
    background: darken($c2, 15%) !important;
  }

  .change-border {
    border-color: $c2 !important;
  }

  .change-border-top {
    border-top-color: $c2 !important;
  }
}

.color-orange {
  ::selection {
    background: $c5;
  }

  ::-moz-selection {
    background: $c5;
  }

  .change-color {
    color: $c5 !important;
  }

  .change-color-dark {
    color: darken($c5, 15%) !important;
  }

  .change-color-hover:hover {
    color: $c5 !important;
  }

  .change-bg {
    background: $c5 !important;
  }

  .change-bg-hover:hover {
    background: darken($c5, 15%) !important;
  }

  .change-bg-dark {
    background: darken($c5, 15%) !important;
  }

  .change-color-hover-dark:hover {
    color: darken($c5, 15%) !important;
  }

  .change-border {
    border-color: $c5 !important;
  }

  .change-border-top {
    border-top-color: $c5 !important;
  }
}

// - other changes

html {
  .color-red,
  .color-blue,
  .color-green,
  .color-orange {
    .contact-person__toggle,
    .page-slider .bx-controls .bx-controls-direction a {
      @extend .change-bg;
      @extend .change-bg-hover;
    }

    .page-slider__caption-content > h3 > span span {
      @extend .change-color;
    }

    .navigation--main .navigation__sub {
      @extend .change-border-top;

      &:before,
      .navigation__sub-link--section > a,
      .navigation__sub-link--current > a {
        @extend .change-color;
      }
    }

    .navigation--main {
      .navigation__top-link:hover > a,
      .navigation__sub-link:hover > a,
      .navigation__top-link--section > a,
      .navigation__top-link--current > a,
      .mobile-navigation-button:hover,
      .navigation__home-link:hover a {
        @extend .change-color;
      }
    }

    .navigation--mobile {
      .navigation__top-link:hover > a,
      .navigation__sub-link:hover > a,
      .navigation__top-link--section > a,
      .navigation__top-link--current > a,
      .mobile-navigation-button:hover,
      .navigation__sub-link--section > a,
      .navigation__sub-link--current > a {
        @extend .change-color;
      }
    }

    .main__text,
    .formatted-text {
      a:not(.button) {
        @extend .change-color;
        @extend .change-color-hover-dark;
      }

      .text {
        &--highlight {
          @extend .change-color;
        }

        &--background {
          @extend .change-bg;
        }
      }
    }

    .button {
      &:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange) {
        @extend .change-bg;
        @extend .change-bg-hover;

        &:after {
          @extend .change-bg-dark;
        }
      }
    }

    .accordion__title {
      i {
        @extend .change-color;
        @extend .change-color-hover-dark;
      }

      &:hover i {
        @extend .change-color-dark;
      }
    }

    .job-description,
    .latest-news__child {
      h3 a {
        @extend .change-color-hover;
      }

      & > a:first-of-type {
        &:before {
          @extend .change-bg;
        }

        &:after {
          @extend .change-color;
        }
      }
    }

    .footer a {
      @extend .change-color-hover;
    }

    .team-member {
      i {
        @extend .change-color;
      }

      a {
        @extend .change-color-hover;
      }
    }

    #Form_SearchForm_action_results {
      @extend .change-bg;
      @extend .change-bg-hover;
    }

    .page-slider {
      @extend .change-border;
    }

    .breadcrumbs a {
      @extend .change-color-hover;
    }

    .textimage__image:after {
      @extend .change-bg;
    }

    .module--downloads {
      li > a {
        @extend .change-color-hover;
      }

      span a {
        @extend .change-color-hover-dark;
        @extend .change-color;
      }
    }

    .grid__header--icon a,
    .news-preview__text > h2 a,
    .basic-holder__child > h3 a,
    .department__info > h2 a {
      @extend .change-color-hover;
    }

    .basic-holder__grand-children li a {
      @extend .change-color-hover-dark;
      @extend .change-color;
    }

    .pagination__current {
      @extend .change-bg;
    }

    .pagination__link,
    .pagination__next,
    .pagination__prev {
      @extend .change-bg-hover;
    }

    form {
      input {
        &[type="submit"] {
          @extend .change-bg;
          @extend .change-bg-hover;
        }

        &:not([type="submit"]):not([type="reset"]) {
          &:focus {
            @extend .change-border;
          }
        }
      }
    }

    // - Buttons
    .grid__link,
    .news-preview__text > a {
      @extend .change-bg;
      @extend .change-bg-hover;

      &:after {
        @extend .change-bg-dark;
      }
    }

    // - Imagehover
    .textimage__image a,
    .module--gallery ul li a,
    .grid__header--image a,
    .page-media__images a,
    .news-preview__image a,
    .news-page__image,
    .department__image a {
      &:before {
        @extend .change-bg;
      }

      &:after {
        @extend .change-color;
      }
    }
  }

  .navigation--main {

    .navigation__top-link.color-red {
      &:hover > a,
      & > a:hover,
      .navigation__sub-link:hover > a {
        color: $c4 !important;
        opacity: 1;
      }

      & > .navigation__sub {
        border-top-color: $c4 !important;

        &:before {
          color: $c4 !important;
        }
      }
    }

    .navigation__top-link.color-orange {
      &:hover > a,
      & > a:hover,
      .navigation__sub-link:hover > a {
        color: $c5 !important;
        opacity: 1;
      }

      & > .navigation__sub {
        border-top-color: $c5 !important;

        &:before {
          color: $c5 !important;
        }
      }
    }

    .navigation__top-link.color-blue {
      &:hover > a,
      & > a:hover,
      .navigation__sub-link:hover > a {
        color: $c3 !important;
        opacity: 1;
      }

      & > .navigation__sub {
        border-top-color: $c3 !important;

        &:before {
          color: $c3 !important;
        }
      }
    }

    .navigation__top-link.color-green {
      &:hover > a,
      & > a:hover,
      .navigation__sub-link:hover > a {
        color: $c2 !important;
        opacity: 1;
      }

      & > .navigation__sub {
        border-top-color: $c2 !important;

        &:before {
          color: $c2 !important;
        }
      }
    }

    .navigation__top-link.color-light-green {
      &:hover > a,
      & > a:hover,
      .navigation__sub-link:hover > a {
        color: $c1 !important;
        opacity: 1;
      }

      & > .navigation__sub {
        border-top-color: $c1 !important;

        &:before {
          color: $c1 !important;
        }
      }
    }
  }
}