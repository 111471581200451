.pagination {
	text-align: center;
	@include resp-padding(top);

	&:before {
		display: block;
		content: '';
		border-top: 1px solid $border-color;
		@include resp-margin(bottom);
	}

	a,
	span {
		display: inline-block;
		width: $dist;
		height: $dist;
		line-height: $dist;
		background: $box-color;
		border-radius: $radius;
	}

	a {
		color: $fc;
		text-decoration: none;
		transition: all $duration;

		&:hover {
			background: $ac;
			color: #FFFFFF;
		}
	}

	span.pagination__current {
		background: $hc;
		color: #FFFFFF;
	}
}