.footer {
  @include resp-margin(top, 1.5);

  &__top {
    background: $footer-light;
    @include resp-padding(v);

    .outer-container {
      @include breakpoint($bp-m, null) {
        display: flex;
        justify-content: space-between;
      }
    }

    ul {
      &:nth-of-type(2) {
        @include breakpoint(null, $bp-m) {
          margin: $dist 0;
        }

        li {
          &:nth-of-type(2) {
            max-width: 250px;
            padding-left: 1.5em;

            i {
              margin-left: -1.5em;
            }
          }

          &:nth-of-type(n+3) {
            padding-left: 1.5em;
          }
        }
      }

      &:nth-of-type(3) {
        li {
          p {
            padding-left: 1.5em;

            &:nth-of-type(1) {
              display: inline-block;
              padding-left: 0;
            }
          }
        }
      }

      li {
        color: $fc-light;

        &:first-of-type {
          text-transform: uppercase;
          font-size: $fs-medium;
          margin-bottom: $dist/2;
        }

        i {
          margin-right: 0.5em;
        }

        a {
          @extend %decent-link;
          color: $fc-light;
          font-weight: $fw-light;
        }
      }
    }
  }

  &__bottom {
    background: $footer-dark;
    text-align: center;
    @include resp-padding(v);
  }

  .navigation--footer {
    a {
      color: $fc-light;
      text-transform: uppercase;
      font-weight: $fw-light;
    }
  }
}