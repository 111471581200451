.job-descriptions,
.job-descriptions__more-items {
  @extend %cf;
}

.main {
  & > *:last-child {
    &.job-descriptions {
      padding-bottom: 23px;
    }

    &.latest-news {
      margin-bottom: -$dist*1.5;

      @include breakpoint(null, $bp-m) {
        margin-bottom: -($dist*1.5)/2;
      }
    }
  }
}

.job-descriptions {
  & + .modular-content-wrapper {
    margin-top: 23px;
  }

  & + .section-heading {
    margin-top: 23px + $dist*1.5 !important;

    @include breakpoint(null, $bp-m) {
      margin-top: 23px + ($dist*1.5)/2 !important;
    }
  }

  &__more-button {
    clear: both;
    text-align: center;
    padding-top: gutter(6);

    @include breakpoint(null, $bp-s) {
      padding-top: gutter(6) * 1.75;
    }

    a {
      display: block;
      padding: $dist/2;
      text-transform: uppercase;
      background: $ac-sec;
      text-decoration: none;
      color: #ffffff;
      font-weight: $fw-bold;
      transition: background $duration linear;

      &:hover {
        background: $ac-sec-hover;
      }

      i {
        &:first-of-type {
          margin-right: $dist/2;
        }

        &:last-of-type {
          margin-left: $dist/2;
        }
      }
    }

    span {
      &:first-of-type {
        display: inline-block;
      }

      &:last-of-type {
        display: none;
      }
    }

    &--active {
      span {
        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          display: inline-block;
        }
      }
    }
  }

  &__more-items {
    display: none;
    @include resp-padding(top);

    &--active {
      padding-bottom: 23px;
    }
  }
}

.job-description {
  $grid-conf: (
    (null, $bp-xs, 1, 5),
    ($bp-xs, $bp-s, 1, 4),
    ($bp-s, $bp-m, 2, 2.5),
    ($bp-m, null, 3, 1.75)
  );

  @include resp-gallery-layout($grid-conf);
  position: relative;
  background: $box-color;

  & > a {
    &:first-of-type {
      display: block;
      @include resp-margin(bottom, 1);
      @include image-hover($fa-var-long-arrow-right);
    }

    &:last-of-type {
      @include center(h);
      bottom: -23px;
    }
  }

  img {
    @include resp-img;
  }

  h3 {
    @include resp-padding(h);
    text-align: left;

    a {
      @extend %decent-link;
      text-transform: uppercase;
      font-weight: $fw-bold;
    }

    &:after {
      display: block;
      content: '';
      height: 2px;
      width: $dist*2;
      margin-bottom: -$dist/2;
      margin-top: $dist/4;
      background: $border-color;
    }

    @include breakpoint(null, $bp-m) {
      margin-bottom: $dist/2;
    }
  }

  p {
    text-align: left;
    @include resp-padding(v);
    @include resp-padding(h);
    padding-bottom: 23px + $dist;

    @include breakpoint(null, $bp-m) {
      padding-bottom: 23px + $dist/2;
    }
  }
}