@charset "UTF-8";
#UserDefinedForm .main, html .userform .step-navigation ul, .school-pages__schools, .navigation--mobile .navigation__sub .navigation__sub-link, .navigation--mobile ul li, .navigation--main:not(.navigation--mobile), .latest-events ul, .job-descriptions,
.job-descriptions__more-items, .header .header__search fieldset, .header, form .field .middleColumn, .department, .contact-data ul, .contact-data, .responsive-iframe-container, .team-members .team-member,
.search-results__list--TeamMember .search-results__list-items .team-member, .team-members,
.search-results__list--TeamMember .search-results__list-items, .calendar-entry, .calendar-event, .page-media__images, .news-page__meta ul, .news-page__text, .news-preview, .latest-news__children, .module--textimage .textimage, .module--grid .grid, .module--gallery .gallery, .module--downloads ul li, .module--accordion .accordion__title, html .basic-holder__grand-children, .main__text > *:last-child.containes-floated-img, .cf {
  *zoom: 1;
}
#UserDefinedForm .main:before, html .userform .step-navigation ul:before, .school-pages__schools:before, .navigation--mobile .navigation__sub .navigation__sub-link:before, .navigation--mobile ul li:before, .navigation--main:not(.navigation--mobile):before, .latest-events ul:before, .job-descriptions:before,
.job-descriptions__more-items:before, .header .header__search fieldset:before, .header:before, form .field .middleColumn:before, .department:before, .contact-data ul:before, .contact-data:before, .responsive-iframe-container:before, .team-members .team-member:before,
.search-results__list--TeamMember .search-results__list-items .team-member:before, .team-members:before,
.search-results__list--TeamMember .search-results__list-items:before, .calendar-entry:before, .calendar-event:before, .page-media__images:before, .news-page__meta ul:before, .news-page__text:before, .news-preview:before, .latest-news__children:before, .module--textimage .textimage:before, .module--grid .grid:before, .module--gallery .gallery:before, .module--downloads ul li:before, .module--accordion .accordion__title:before, html .basic-holder__grand-children:before, .main__text > .containes-floated-img:last-child:before, .cf:before {
  content: "";
  display: table;
}
#UserDefinedForm .main:after, html .userform .step-navigation ul:after, .school-pages__schools:after, .navigation--mobile .navigation__sub .navigation__sub-link:after, .navigation--mobile ul li:after, .navigation--main:not(.navigation--mobile):after, .latest-events ul:after, .job-descriptions:after,
.job-descriptions__more-items:after, .header .header__search fieldset:after, .header:after, form .field .middleColumn:after, .department:after, .contact-data ul:after, .contact-data:after, .responsive-iframe-container:after, .team-members .team-member:after,
.search-results__list--TeamMember .search-results__list-items .team-member:after, .team-members:after,
.search-results__list--TeamMember .search-results__list-items:after, .calendar-entry:after, .calendar-event:after, .page-media__images:after, .news-page__meta ul:after, .news-page__text:after, .news-preview:after, .latest-news__children:after, .module--textimage .textimage:after, .module--grid .grid:after, .module--gallery .gallery:after, .module--downloads ul li:after, .module--accordion .accordion__title:after, html .basic-holder__grand-children:after, .main__text > .containes-floated-img:last-child:after, .cf:after {
  content: "";
  display: table;
  clear: both;
}

.basic-holder__grand-children li a, .job-description h3 a, .footer__top ul li a, .department__info > h2 a, .contact-data ul li a, .team-members .team-member__info a,
.search-results__list--TeamMember .search-results__list-items .team-member__info a, .calendar-entry__details h3 a, .latest-news__child h3 a {
  color: #575757;
  text-decoration: none;
  transition: color 0.2s linear;
}
.basic-holder__grand-children li a:hover, .job-description h3 a:hover, .footer__top ul li a:hover, .department__info > h2 a:hover, .contact-data ul li a:hover, .team-members .team-member__info a:hover,
.search-results__list--TeamMember .search-results__list-items .team-member__info a:hover, .calendar-entry__details h3 a:hover, .latest-news__child h3 a:hover {
  color: #aac858;
}

#JobDescriptionPage .main > *:not(.latest-news), #HomePage .main__text,
.announcement__text, .navigation--main:not(.navigation--mobile), .header, body:not(#homepage):not(#schoolpage) .main, .outer-container {
  width: calc(100% - 15px);
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

html .userform .step-navigation ul li button, .latest-events > a, form input[type=submit],
form input[type=reset], .cookie-confirm #cookie-confirm__submit,
.cookie-confirm #cookie-confirm__cancel, .search-results__read-more, .search-results__all-from-type, .news-page__meta ul li a, .news-preview__text > a, .module--grid .grid__link, .button {
  display: inline-block;
  padding: 0;
  padding-left: 15px;
  color: #FFFFFF;
  background: #aac858;
  transition: all linear 0.2s;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  font-weight: 400;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  white-space: nowrap;
}
html .userform .step-navigation ul li button:hover, .latest-events > a:hover, form input[type=submit]:hover,
form input[type=reset]:hover, .cookie-confirm #cookie-confirm__submit:hover,
.cookie-confirm #cookie-confirm__cancel:hover, .search-results__read-more:hover, .search-results__all-from-type:hover, .news-page__meta ul li a:hover, .news-preview__text > a:hover, .module--grid .grid__link:hover, .button:hover {
  background: #839f34;
  color: #ffffff;
}
html .userform .step-navigation ul li button:focus, .latest-events > a:focus, form input[type=submit]:focus,
form input[type=reset]:focus, .cookie-confirm #cookie-confirm__submit:focus,
.cookie-confirm #cookie-confirm__cancel:focus, .search-results__read-more:focus, .search-results__all-from-type:focus, .news-page__meta ul li a:focus, .news-preview__text > a:focus, .module--grid .grid__link:focus, .button:focus {
  outline: none;
  border: none;
}
html .userform .step-navigation ul li button:after, .latest-events > a:after, form input[type=submit]:after,
form input[type=reset]:after, .cookie-confirm #cookie-confirm__submit:after,
.cookie-confirm #cookie-confirm__cancel:after, .search-results__read-more:after, .search-results__all-from-type:after, .news-page__meta ul li a:after, .news-preview__text > a:after, .module--grid .grid__link:after, .button:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  background: #839f34;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin-left: 15px;
  transition: background linear 0.2s;
}

html .userform .step-navigation ul li button, .latest-events > a, .search-results__read-more, .news-page__meta ul li a, .news-preview__text > a, .module--grid .grid__link, .button--small {
  font-size: 0.75rem;
  height: 30px;
  line-height: 30px;
}
html .userform .step-navigation ul li button:after, .latest-events > a:after, .search-results__read-more:after, .news-page__meta ul li a:after, .news-preview__text > a:after, .module--grid .grid__link:after, .button--small:after {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.button--full-width {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 60px;
}
.button--full-width:after {
  position: absolute;
  right: 0;
}

.button--white {
  background: #FFFFFF;
  color: #575757;
}
.button--white:hover {
  color: #FFFFFF;
}

.button--light-green {
  background: #aac858;
  color: #FFFFFF;
}
.button--light-green:hover {
  color: #FFFFFF;
}

.button--green {
  background: #5da55b;
  color: #FFFFFF;
}
.button--green:after {
  background: #417440;
}
.button--green:hover {
  background: #417440;
}

.button--blue {
  background: #488ccb;
  color: #FFFFFF;
}
.button--blue:after {
  background: #2c659b;
}
.button--blue:hover {
  background: #2c659b;
}

.button--red {
  background: #eb6446;
  color: #FFFFFF;
}
.button--red:after {
  background: #ce3816;
}
.button--red:hover {
  background: #ce3816;
}

.button--orange {
  background: #de8530;
  color: #FFFFFF;
}
.button--orange:after {
  background: #a75f1b;
}
.button--orange:hover {
  background: #a75f1b;
}

html .userform .step-navigation ul li button, .latest-events > a, form input[type=reset], .cookie-confirm #cookie-confirm__cancel, .search-results__read-more, .news-page__meta ul li a, .button--neutral {
  background: #4b4b4b;
  color: #ffffff;
}
html .userform .step-navigation ul li button:after, .latest-events > a:after, form input[type=reset]:after, .cookie-confirm #cookie-confirm__cancel:after, .search-results__read-more:after, .news-page__meta ul li a:after, .button--neutral:after {
  background: #252525;
}
html .userform .step-navigation ul li button:hover, .latest-events > a:hover, form input[type=reset]:hover, .cookie-confirm #cookie-confirm__cancel:hover, .search-results__read-more:hover, .news-page__meta ul li a:hover, .button--neutral:hover {
  background: #252525;
}

.formatted-text code,
[class*=__text] code {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  font-family: monospace, san-serif;
  font-size: 0.75rem;
  padding: 15px;
  background: #ffffff;
  border-radius: 0;
}
.formatted-text h1,
[class*=__text] h1 {
  font-size: 1.75rem;
}
.formatted-text h2,
[class*=__text] h2 {
  font-size: 1.5rem;
}
.formatted-text h3,
[class*=__text] h3 {
  font-size: 1.25rem;
}
.formatted-text h4,
[class*=__text] h4 {
  font-size: 1.25rem;
}
.formatted-text h5,
[class*=__text] h5 {
  font-size: 1rem;
}
.formatted-text h6,
[class*=__text] h6 {
  font-size: 1rem;
}
.formatted-text h1,
[class*=__text] h1,
.formatted-text h2,
[class*=__text] h2,
.formatted-text h3,
[class*=__text] h3,
.formatted-text h4,
[class*=__text] h4,
.formatted-text h5,
[class*=__text] h5,
.formatted-text h6,
[class*=__text] h6 {
  font-family: 400;
  font-weight: 700;
}
.formatted-text > *:first-child,
[class*=__text] > *:first-child {
  margin-top: 0 !important;
}
.formatted-text > *:last-child,
[class*=__text] > *:last-child {
  margin-bottom: 0;
}
.formatted-text a i,
[class*=__text] a i {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5em;
}
.formatted-text p,
[class*=__text] p {
  margin-top: 0.75em;
}
.formatted-text p > *:first-child,
[class*=__text] p > *:first-child {
  margin-top: 0 !important;
}
.formatted-text p .btn,
[class*=__text] p .btn {
  margin-top: 0.75em;
}
.formatted-text p + ul,
[class*=__text] p + ul, .formatted-text p + ol,
[class*=__text] p + ol, .formatted-text p + h1,
[class*=__text] p + h1, .formatted-text p + h2,
[class*=__text] p + h2, .formatted-text p + h3,
[class*=__text] p + h3, .formatted-text p + h4,
[class*=__text] p + h4, .formatted-text p + h5,
[class*=__text] p + h5, .formatted-text p + h6,
[class*=__text] p + h6, .formatted-text p + table,
[class*=__text] p + table, .formatted-text p + div,
[class*=__text] p + div, .formatted-text p + blockquote,
[class*=__text] p + blockquote, .formatted-text p + code,
[class*=__text] p + code, .formatted-text p + pre,
[class*=__text] p + pre {
  margin-top: 0.75em;
}
.formatted-text blockquote,
[class*=__text] blockquote {
  margin-top: 0.75em;
}
.formatted-text blockquote > *:first-child,
[class*=__text] blockquote > *:first-child {
  margin-top: 0 !important;
}
.formatted-text blockquote + ul,
[class*=__text] blockquote + ul, .formatted-text blockquote + ol,
[class*=__text] blockquote + ol, .formatted-text blockquote + h1,
[class*=__text] blockquote + h1, .formatted-text blockquote + h2,
[class*=__text] blockquote + h2, .formatted-text blockquote + h3,
[class*=__text] blockquote + h3, .formatted-text blockquote + h4,
[class*=__text] blockquote + h4, .formatted-text blockquote + h5,
[class*=__text] blockquote + h5, .formatted-text blockquote + h6,
[class*=__text] blockquote + h6, .formatted-text blockquote + table,
[class*=__text] blockquote + table, .formatted-text blockquote + div,
[class*=__text] blockquote + div, .formatted-text blockquote + p,
[class*=__text] blockquote + p, .formatted-text blockquote + code,
[class*=__text] blockquote + code, .formatted-text blockquote + pre,
[class*=__text] blockquote + pre {
  margin-top: 0.75em;
}
.formatted-text code,
[class*=__text] code {
  margin-top: 0.75em;
}
.formatted-text code > *:first-child,
[class*=__text] code > *:first-child {
  margin-top: 0 !important;
}
.formatted-text code + ul,
[class*=__text] code + ul, .formatted-text code + ol,
[class*=__text] code + ol, .formatted-text code + h1,
[class*=__text] code + h1, .formatted-text code + h2,
[class*=__text] code + h2, .formatted-text code + h3,
[class*=__text] code + h3, .formatted-text code + h4,
[class*=__text] code + h4, .formatted-text code + h5,
[class*=__text] code + h5, .formatted-text code + h6,
[class*=__text] code + h6, .formatted-text code + table,
[class*=__text] code + table, .formatted-text code + div,
[class*=__text] code + div, .formatted-text code + p,
[class*=__text] code + p, .formatted-text code + code,
[class*=__text] code + code, .formatted-text code + pre,
[class*=__text] code + pre {
  margin-top: 0.75em;
}
.formatted-text pre,
[class*=__text] pre {
  margin-top: 0.75em;
}
.formatted-text pre > *:first-child,
[class*=__text] pre > *:first-child {
  margin-top: 0 !important;
}
.formatted-text pre + ul,
[class*=__text] pre + ul, .formatted-text pre + ol,
[class*=__text] pre + ol, .formatted-text pre + h1,
[class*=__text] pre + h1, .formatted-text pre + h2,
[class*=__text] pre + h2, .formatted-text pre + h3,
[class*=__text] pre + h3, .formatted-text pre + h4,
[class*=__text] pre + h4, .formatted-text pre + h5,
[class*=__text] pre + h5, .formatted-text pre + h6,
[class*=__text] pre + h6, .formatted-text pre + table,
[class*=__text] pre + table, .formatted-text pre + div,
[class*=__text] pre + div, .formatted-text pre + p,
[class*=__text] pre + p, .formatted-text pre + code,
[class*=__text] pre + code, .formatted-text pre + pre,
[class*=__text] pre + pre {
  margin-top: 0.75em;
}
.formatted-text h1 + h1,
[class*=__text] h1 + h1, .formatted-text h1 + h2,
[class*=__text] h1 + h2, .formatted-text h1 + h3,
[class*=__text] h1 + h3, .formatted-text h1 + h4,
[class*=__text] h1 + h4, .formatted-text h1 + h5,
[class*=__text] h1 + h5, .formatted-text h1 + h6,
[class*=__text] h1 + h6, .formatted-text h1 + p,
[class*=__text] h1 + p, .formatted-text h1 + ul,
[class*=__text] h1 + ul, .formatted-text h1 + ol,
[class*=__text] h1 + ol, .formatted-text h1 + table,
[class*=__text] h1 + table, .formatted-text h1 + div,
[class*=__text] h1 + div, .formatted-text h1 + blockquote,
[class*=__text] h1 + blockquote, .formatted-text h1 + code,
[class*=__text] h1 + code, .formatted-text h1 + pre,
[class*=__text] h1 + pre, .formatted-text h2 + h1,
[class*=__text] h2 + h1, .formatted-text h2 + h2,
[class*=__text] h2 + h2, .formatted-text h2 + h3,
[class*=__text] h2 + h3, .formatted-text h2 + h4,
[class*=__text] h2 + h4, .formatted-text h2 + h5,
[class*=__text] h2 + h5, .formatted-text h2 + h6,
[class*=__text] h2 + h6, .formatted-text h2 + p,
[class*=__text] h2 + p, .formatted-text h2 + ul,
[class*=__text] h2 + ul, .formatted-text h2 + ol,
[class*=__text] h2 + ol, .formatted-text h2 + table,
[class*=__text] h2 + table, .formatted-text h2 + div,
[class*=__text] h2 + div, .formatted-text h2 + blockquote,
[class*=__text] h2 + blockquote, .formatted-text h2 + code,
[class*=__text] h2 + code, .formatted-text h2 + pre,
[class*=__text] h2 + pre, .formatted-text h3 + h1,
[class*=__text] h3 + h1, .formatted-text h3 + h2,
[class*=__text] h3 + h2, .formatted-text h3 + h3,
[class*=__text] h3 + h3, .formatted-text h3 + h4,
[class*=__text] h3 + h4, .formatted-text h3 + h5,
[class*=__text] h3 + h5, .formatted-text h3 + h6,
[class*=__text] h3 + h6, .formatted-text h3 + p,
[class*=__text] h3 + p, .formatted-text h3 + ul,
[class*=__text] h3 + ul, .formatted-text h3 + ol,
[class*=__text] h3 + ol, .formatted-text h3 + table,
[class*=__text] h3 + table, .formatted-text h3 + div,
[class*=__text] h3 + div, .formatted-text h3 + blockquote,
[class*=__text] h3 + blockquote, .formatted-text h3 + code,
[class*=__text] h3 + code, .formatted-text h3 + pre,
[class*=__text] h3 + pre, .formatted-text h4 + h1,
[class*=__text] h4 + h1, .formatted-text h4 + h2,
[class*=__text] h4 + h2, .formatted-text h4 + h3,
[class*=__text] h4 + h3, .formatted-text h4 + h4,
[class*=__text] h4 + h4, .formatted-text h4 + h5,
[class*=__text] h4 + h5, .formatted-text h4 + h6,
[class*=__text] h4 + h6, .formatted-text h4 + p,
[class*=__text] h4 + p, .formatted-text h4 + ul,
[class*=__text] h4 + ul, .formatted-text h4 + ol,
[class*=__text] h4 + ol, .formatted-text h4 + table,
[class*=__text] h4 + table, .formatted-text h4 + div,
[class*=__text] h4 + div, .formatted-text h4 + blockquote,
[class*=__text] h4 + blockquote, .formatted-text h4 + code,
[class*=__text] h4 + code, .formatted-text h4 + pre,
[class*=__text] h4 + pre, .formatted-text h5 + h1,
[class*=__text] h5 + h1, .formatted-text h5 + h2,
[class*=__text] h5 + h2, .formatted-text h5 + h3,
[class*=__text] h5 + h3, .formatted-text h5 + h4,
[class*=__text] h5 + h4, .formatted-text h5 + h5,
[class*=__text] h5 + h5, .formatted-text h5 + h6,
[class*=__text] h5 + h6, .formatted-text h5 + p,
[class*=__text] h5 + p, .formatted-text h5 + ul,
[class*=__text] h5 + ul, .formatted-text h5 + ol,
[class*=__text] h5 + ol, .formatted-text h5 + table,
[class*=__text] h5 + table, .formatted-text h5 + div,
[class*=__text] h5 + div, .formatted-text h5 + blockquote,
[class*=__text] h5 + blockquote, .formatted-text h5 + code,
[class*=__text] h5 + code, .formatted-text h5 + pre,
[class*=__text] h5 + pre, .formatted-text h6 + h1,
[class*=__text] h6 + h1, .formatted-text h6 + h2,
[class*=__text] h6 + h2, .formatted-text h6 + h3,
[class*=__text] h6 + h3, .formatted-text h6 + h4,
[class*=__text] h6 + h4, .formatted-text h6 + h5,
[class*=__text] h6 + h5, .formatted-text h6 + h6,
[class*=__text] h6 + h6, .formatted-text h6 + p,
[class*=__text] h6 + p, .formatted-text h6 + ul,
[class*=__text] h6 + ul, .formatted-text h6 + ol,
[class*=__text] h6 + ol, .formatted-text h6 + table,
[class*=__text] h6 + table, .formatted-text h6 + div,
[class*=__text] h6 + div, .formatted-text h6 + blockquote,
[class*=__text] h6 + blockquote, .formatted-text h6 + code,
[class*=__text] h6 + code, .formatted-text h6 + pre,
[class*=__text] h6 + pre {
  margin-top: 0.75em;
}
.formatted-text ul li,
[class*=__text] ul li {
  list-style-type: disc;
}
.formatted-text ol li,
[class*=__text] ol li {
  list-style-type: decimal;
}
.formatted-text ul,
[class*=__text] ul, .formatted-text ol,
[class*=__text] ol {
  list-style: outside;
  margin: 0 0 1em 1.25em;
}
.formatted-text ul li ul,
[class*=__text] ul li ul,
.formatted-text ul li ol,
[class*=__text] ul li ol, .formatted-text ol li ul,
[class*=__text] ol li ul,
.formatted-text ol li ol,
[class*=__text] ol li ol {
  margin-bottom: 0;
}
.formatted-text ul + p,
[class*=__text] ul + p, .formatted-text ul + h1,
[class*=__text] ul + h1, .formatted-text ul + h2,
[class*=__text] ul + h2, .formatted-text ul + h3,
[class*=__text] ul + h3, .formatted-text ul + h4,
[class*=__text] ul + h4, .formatted-text ul + h5,
[class*=__text] ul + h5, .formatted-text ul + h6,
[class*=__text] ul + h6, .formatted-text ul + table,
[class*=__text] ul + table, .formatted-text ul + div,
[class*=__text] ul + div, .formatted-text ul + blockquote,
[class*=__text] ul + blockquote, .formatted-text ul + code,
[class*=__text] ul + code, .formatted-text ul + pre,
[class*=__text] ul + pre, .formatted-text ol + p,
[class*=__text] ol + p, .formatted-text ol + h1,
[class*=__text] ol + h1, .formatted-text ol + h2,
[class*=__text] ol + h2, .formatted-text ol + h3,
[class*=__text] ol + h3, .formatted-text ol + h4,
[class*=__text] ol + h4, .formatted-text ol + h5,
[class*=__text] ol + h5, .formatted-text ol + h6,
[class*=__text] ol + h6, .formatted-text ol + table,
[class*=__text] ol + table, .formatted-text ol + div,
[class*=__text] ol + div, .formatted-text ol + blockquote,
[class*=__text] ol + blockquote, .formatted-text ol + code,
[class*=__text] ol + code, .formatted-text ol + pre,
[class*=__text] ol + pre {
  margin-top: 0.75em;
}
.formatted-text div > *:first-child,
[class*=__text] div > *:first-child {
  margin-top: 0;
}
.formatted-text div + p,
[class*=__text] div + p, .formatted-text div + ul,
[class*=__text] div + ul, .formatted-text div + ol,
[class*=__text] div + ol, .formatted-text div + h1,
[class*=__text] div + h1, .formatted-text div + h2,
[class*=__text] div + h2, .formatted-text div + h3,
[class*=__text] div + h3, .formatted-text div + h4,
[class*=__text] div + h4, .formatted-text div + h5,
[class*=__text] div + h5, .formatted-text div + h6,
[class*=__text] div + h6, .formatted-text div + table,
[class*=__text] div + table, .formatted-text div + div,
[class*=__text] div + div, .formatted-text div + blockquote,
[class*=__text] div + blockquote, .formatted-text div + code,
[class*=__text] div + code, .formatted-text div + pre,
[class*=__text] div + pre {
  margin-top: 0.75em;
}
.formatted-text table > *:first-child,
[class*=__text] table > *:first-child {
  margin-top: 0;
}
.formatted-text table + p,
[class*=__text] table + p, .formatted-text table + ul,
[class*=__text] table + ul, .formatted-text table + ol,
[class*=__text] table + ol, .formatted-text table + h1,
[class*=__text] table + h1, .formatted-text table + h2,
[class*=__text] table + h2, .formatted-text table + h3,
[class*=__text] table + h3, .formatted-text table + h4,
[class*=__text] table + h4, .formatted-text table + h5,
[class*=__text] table + h5, .formatted-text table + h6,
[class*=__text] table + h6, .formatted-text table + table,
[class*=__text] table + table, .formatted-text table + div,
[class*=__text] table + div, .formatted-text table + blockquote,
[class*=__text] table + blockquote, .formatted-text table + code,
[class*=__text] table + code, .formatted-text table + pre,
[class*=__text] table + pre {
  margin-top: 0.75em;
}
.formatted-text hr,
[class*=__text] hr {
  outline: 0;
  border: 0;
  display: block;
  height: 1px;
  margin: 15px 0;
  background: #dcdcdc;
}
.formatted-text blockquote,
[class*=__text] blockquote {
  padding: 7.5px 0 7.5px 7.5px;
  border-left: 2px solid #dcdcdc;
  font-style: italic;
}

html {
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: "basic-sans", "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #575757;
  text-size-adjust: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "basic-sans", "Inter", sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.25rem;
}

h5, h6 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

strong, b, th {
  font-weight: 700;
}

small {
  font-size: 0.75rem;
}

h1, h2, h3, h4, h5, h6,
strong, p, b, em, i, span, a, small, th, td, li {
  line-height: 1.35em;
}

blockquote {
  padding: 7.5px 0 7.5px 30px;
  border-left: 1px solid #dcdcdc;
  color: #acacac;
}

i.fa,
i.fal,
i.far,
i.fab {
  display: inline-block;
  width: 1em;
  text-align: center;
  font-style: normal;
}

a {
  color: #aac858;
  text-decoration: underline;
}
a:hover {
  color: #839f34;
}

::selection {
  color: #ffffff;
  background: #aac858;
}

::-moz-selection {
  color: #ffffff;
  background: #aac858;
}

ul.list--checklist {
  list-style: none;
  margin-left: 0 !important;
}
ul.list--checklist li {
  display: block;
  padding-left: 1.25em;
}
ul.list--checklist li:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00c";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1em;
  margin-left: -1.25em;
  float: left;
  text-align: center;
  position: relative;
  top: 3px;
}

.text--discreet {
  color: #acacac !important;
}

.text--highlight {
  color: #aac858;
}

.text--background {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  background: #aac858;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .text--background {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}
@media screen and (max-width: 768px) {
  .text--background {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

.text--background-discreet {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  background: #f4f4f4;
}
@media screen and (max-width: 768px) {
  .text--background-discreet {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}
@media screen and (max-width: 768px) {
  .text--background-discreet {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

hr {
  border: none;
  height: 1px;
  background: #dcdcdc;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

em, i {
  font-style: italic;
}

.system-message + *, .message:not([style*="display: none"]) + * {
  margin-top: 30px !important;
}
@media screen and (max-width: 768px) {
  .system-message + *, .message:not([style*="display: none"]) + * {
    margin-top: 15px !important;
  }
}
.system-message + .system-message, .message:not([style*="display: none"]) + .system-message, .system-message + .message:not([style*="display: none"]), .message:not([style*="display: none"]) + .message:not([style*="display: none"]) {
  margin-top: 5px !important;
}
.system-message, .message:not([style*="display: none"]), .system-message--neutral {
  cursor: pointer;
  position: relative;
  border-left: 5px solid #dcdcdc;
  padding: 15px 30px 15px 15px;
  background: rgba(220, 220, 220, 0.1);
}
.system-message:after, .message:not([style*="display: none"]):after, .system-message--neutral:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  position: absolute;
  right: 7.5px;
  top: 7.5px;
  opacity: 0.5;
  transition: all 0.2s linear;
}
.system-message:hover:after, .message:hover:not([style*="display: none"]):after, .system-message--neutral:hover:after {
  opacity: 1;
}
.system-message--good, .message:not([style*="display: none"]).good, .message:not([style*="display: none"]).success {
  border-color: #5da55b;
  background: rgba(93, 165, 91, 0.1);
}
.system-message--warning, .message:not([style*="display: none"]).warning {
  border-color: #e0c459;
  background: rgba(224, 196, 89, 0.1);
}
.system-message--bad, .message:not([style*="display: none"]).bad, .message:not([style*="display: none"]).error, .message:not([style*="display: none"]).required {
  border-color: #eb6446;
  background: rgba(235, 100, 70, 0.1);
}
.system-message--permanent, .message:not([style*="display: none"]) {
  cursor: default;
}
.system-message--permanent:after, .message:not([style*="display: none"]):after {
  display: none;
}

.dist-right {
  margin-right: 0.25em;
}

.dist-left {
  margin-left: 0.25em;
}

.dist-top {
  margin-top: 0.25em;
}

.dist-bottom {
  margin-bottom: 0.25em;
}

img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img.left {
  float: left;
  margin-right: 15px;
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  img.left {
    margin-right: 7.5px;
  }
}
@media screen and (max-width: 768px) {
  img.left {
    margin-bottom: 30px;
  }
}
img.right {
  float: right;
  margin-left: 15px;
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  img.right {
    margin-left: 7.5px;
  }
}
@media screen and (max-width: 768px) {
  img.right {
    margin-bottom: 30px;
  }
}

.main__text img.is-resp {
  display: block;
  width: 100%;
  height: auto;
}
.main__text br {
  line-height: 1;
}
.main__text .containes-floated-img + * {
  margin-top: 0;
}
.main__text .containes-floated-img + * + * {
  clear: both;
}
.main__text + .modular-content, .main__text + .job-descriptions {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .main__text + .modular-content, .main__text + .job-descriptions {
    margin-top: 15px;
  }
}
.main__text + .modular-content-wrapper {
  padding-top: 0;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

textarea {
  resize: vertical;
}

a {
  transition: color 0.2s linear;
  font-weight: 400;
}

.global-sc-dist-right {
  margin-right: 0.5em;
}

.global-sc-dist-left {
  margin-left: 0.5em;
}

.global-sc-dist-top {
  margin-top: 0.5em;
}

.global-sc-dist-bottom {
  margin-bottom: 0.5em;
}

html .basic-holder .basic-holder__text + .basic-holder__children {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  html .basic-holder .basic-holder__text + .basic-holder__children {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  html .basic-holder .basic-holder__text + .basic-holder__children {
    padding-top: 15px;
  }
}
html .basic-holder__child {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child {
    margin-top: 15px;
  }
}
html .basic-holder__child:first-of-type {
  margin-top: 0;
}
html .basic-holder__child > h3 {
  display: block;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child > h3 {
    margin-bottom: 7.5px;
  }
}
html .basic-holder__child > h3 a {
  color: #575757;
}
html .basic-holder__child > h3 a:hover {
  color: #aac858;
}
html .basic-holder__child > p {
  margin: 0;
}
html .basic-holder__grand-children {
  margin: 0 !important;
  margin-top: 7.5px;
}
@media screen and (max-width: 480px) {
  html .basic-holder__grand-children {
    margin-top: 3.75px;
  }
}
html .basic-holder__grand-children li {
  display: block;
}
@media screen and (min-width: 769px) {
  html .basic-holder__grand-children li {
    float: left;
    width: calc(50% - 7.5px);
  }
  html .basic-holder__grand-children li:nth-of-type(2n+2) {
    margin-left: 15px;
  }
  html .basic-holder__grand-children li:nth-of-type(2n+3) {
    clear: both;
  }
}

.module--accordion .accordion__item {
  margin-bottom: 2px;
  background: #f4f4f4;
}
.module--accordion .accordion__item:last-of-type {
  margin-bottom: 0;
}
.module--accordion .accordion__item--open .accordion__content {
  display: block;
}
.module--accordion .accordion__item--open .accordion__title {
  background: #e8e8e8;
}
.module--accordion .accordion__title {
  cursor: pointer;
  padding: 15px;
  transition: background 0.2s linear;
  text-transform: uppercase;
}
.module--accordion .accordion__title:hover {
  background: #e8e8e8;
}
.module--accordion .accordion__title:hover i {
  color: #839f34;
}
.module--accordion .accordion__title strong {
  display: block;
  float: left;
  margin-right: 7.5px;
  width: calc(100% - 7.5px - 1rem);
  font-weight: 400;
}
.module--accordion .accordion__title i {
  display: block;
  float: right;
  color: #aac858;
  font-size: 1rem;
  width: 1rem;
  text-align: center;
}
.module--accordion .accordion__title i.fa-minus-circle:before {
  content: "\f077";
}
.module--accordion .accordion__title i.fa-plus-circle:before {
  content: "\f078";
}
.module--accordion .accordion__content {
  padding: 15px;
  display: none;
}
.module--accordion .accordion__content .formatted-text {
  margin-top: -15px;
}
.module--accordion .accordion__content .formatted-text:before {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 15px;
}

.module--downloads ul {
  margin: 0;
  padding: 0;
}
.module--downloads ul li {
  list-style: none !important;
  display: block;
  border-bottom: 1px dashed #dcdcdc;
  margin-bottom: 7.5px;
  padding-bottom: 7.5px;
  text-transform: uppercase;
}
.module--downloads ul li:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.module--downloads ul li > a {
  display: block;
  width: calc(100% - 150px);
  float: left;
  color: #575757;
  text-decoration: none;
}
.module--downloads ul li > a:hover {
  color: #aac858;
}
.module--downloads ul li > a i {
  margin-right: 0.5em;
  color: #acacac;
}
.module--downloads ul li span {
  display: block;
  width: 150px;
  text-align: right;
  float: right;
}
.module--downloads ul li span a {
  color: #aac858;
  text-decoration: none;
}
.module--downloads ul li span a:hover {
  color: #839f34;
}

.module--gallery .gallery {
  margin: 0;
  padding: 0;
}
.module--gallery .gallery li {
  display: block;
  float: left;
}
.module--gallery .gallery li a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.module--gallery .gallery li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.module--gallery .gallery li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.module--gallery .gallery li a:hover:before, .module--gallery .gallery li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.module--gallery .gallery li a:hover:after {
  left: 4px;
}
.module--gallery .gallery li a img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 350px) {
  .module--gallery .gallery--ipr-2 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-2 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-2 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-2 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) {
  .module--gallery .gallery--ipr-2 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-2 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-2 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-2 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (max-width: 350px) {
  .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-3 li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 350px) {
  .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-4 li:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (max-width: 350px) {
  .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 480px) {
  .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 1036px) {
  .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 14.5299145299%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .module--gallery .gallery--ipr-6 li:nth-of-type(6n+7) {
    clear: both;
  }
}

.module--grid .grid__item {
  display: block;
  float: left;
}
.module--grid .grid__header {
  text-align: center;
  margin-bottom: 15px;
}
.module--grid .grid__header img {
  display: inline-block;
  width: 100%;
  height: auto;
}
.module--grid .grid__header i {
  font-size: 2.5rem;
}
.module--grid .grid__header a {
  color: #575757;
}
.module--grid .grid__header a:hover {
  color: #aac858;
}
.module--grid .grid__header.grid__header--image a[data-lightbox] {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.module--grid .grid__header.grid__header--image a[data-lightbox]:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.module--grid .grid__header.grid__header--image a[data-lightbox]:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.module--grid .grid__header.grid__header--image a[data-lightbox]:hover:before, .module--grid .grid__header.grid__header--image a[data-lightbox]:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.module--grid .grid__header.grid__header--image a[data-lightbox]:hover:after {
  left: 4px;
}
.module--grid .grid__header.grid__header--image a:not([href^="/assets"]) {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.module--grid .grid__header.grid__header--image a:not([href^="/assets"]):after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.module--grid .grid__header.grid__header--image a:not([href^="/assets"]):before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.module--grid .grid__header.grid__header--image a:not([href^="/assets"]):hover:before, .module--grid .grid__header.grid__header--image a:not([href^="/assets"]):hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.module--grid .grid__header.grid__header--image a:not([href^="/assets"]):hover:after {
  left: 4px;
}
.module--grid .grid__title {
  margin-bottom: 15px;
  text-transform: uppercase;
}
.module--grid .grid__link {
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
}
.module--grid .grid--align-center {
  text-align: center;
}
@media screen and (max-width: 480px) {
  .module--grid .grid--ipr-2 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-2 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-2 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-2 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  .module--grid .grid--ipr-2 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-2 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-2 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-2 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (max-width: 480px) {
  .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-3 .grid__item:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 480px) {
  .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .module--grid .grid--ipr-4 .grid__item:nth-of-type(4n+5) {
    clear: both;
  }
}

.module--headline {
  text-transform: uppercase;
}

.modular-content .module {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .modular-content .module {
    margin-bottom: 15px;
  }
}
.modular-content .module:last-of-type {
  margin-bottom: 0;
}
.modular-content .module--separator-top {
  padding-top: 30px;
  border-top: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-top {
    padding-top: 15px;
  }
}
.modular-content .module--separator-bottom {
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-bottom {
    padding-bottom: 15px;
  }
}
.modular-content .module:first-of-type.module--separator-top {
  padding-top: 0;
  border-top: 0;
}
.modular-content .module:last-of-type {
  margin-bottom: 0;
}
.modular-content .module:last-of-type.module--separator-bottom {
  padding-bottom: 0;
  border-bottom: 0;
}
.modular-content .module__title {
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .modular-content .module__title {
    margin-bottom: 15px;
  }
}

.module--textimage .module__inner .textimage__image a,
.module--textimage .module__inner a.textimage__image {
  display: block;
}
.module--textimage .module__inner .textimage__image a[data-lightbox],
.module--textimage .module__inner a.textimage__image[data-lightbox] {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.module--textimage .module__inner .textimage__image a[data-lightbox]:after,
.module--textimage .module__inner a.textimage__image[data-lightbox]:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.module--textimage .module__inner .textimage__image a[data-lightbox]:before,
.module--textimage .module__inner a.textimage__image[data-lightbox]:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.module--textimage .module__inner .textimage__image a[data-lightbox]:hover:before, .module--textimage .module__inner .textimage__image a[data-lightbox]:hover:after,
.module--textimage .module__inner a.textimage__image[data-lightbox]:hover:before,
.module--textimage .module__inner a.textimage__image[data-lightbox]:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.module--textimage .module__inner .textimage__image a[data-lightbox]:hover:after,
.module--textimage .module__inner a.textimage__image[data-lightbox]:hover:after {
  left: 4px;
}
.module--textimage .module__inner .textimage__image a:not([data-lightbox]),
.module--textimage .module__inner a.textimage__image:not([data-lightbox]) {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.module--textimage .module__inner .textimage__image a:not([data-lightbox]):after,
.module--textimage .module__inner a.textimage__image:not([data-lightbox]):after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.module--textimage .module__inner .textimage__image a:not([data-lightbox]):before,
.module--textimage .module__inner a.textimage__image:not([data-lightbox]):before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:before, .module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:after,
.module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:before,
.module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:after,
.module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:after {
  left: 4px;
}
.module--textimage .module__inner img:not(.center):not(.left):not(.right):not(.leftAlone) {
  display: block;
  width: 100%;
  height: auto;
}
.module--textimage .textimage__title {
  text-transform: uppercase;
}
.module--textimage .textimage div.textimage__image:after {
  position: relative;
  z-index: 5;
  display: block;
  content: "";
  height: 6px;
  width: 360px;
  background: #aac858;
  margin: -3px auto 0 auto;
}
.module--textimage .textimage--image-top .textimage__image {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .module--textimage .textimage--image-top .textimage__image {
    margin-bottom: 15px;
  }
}
.module--textimage .textimage--image-bottom .textimage__image {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .module--textimage .textimage--image-bottom .textimage__image {
    margin-top: 15px;
  }
}
.module--textimage .textimage--image-left .textimage__image, .module--textimage .textimage--image-right .textimage__image {
  width: 23.0769230769%;
}
.module--textimage .textimage--image-left .textimage__image img, .module--textimage .textimage--image-right .textimage__image img {
  display: block;
  width: 100%;
  height: auto;
}
.module--textimage .textimage--image-left div.textimage__image:after, .module--textimage .textimage--image-right div.textimage__image:after {
  width: 180px;
}
.module--textimage .textimage--image-left .formatted-text, .module--textimage .textimage--image-right .formatted-text {
  width: 74.358974359%;
  float: left;
}
@media screen and (max-width: 480px) {
  .module--textimage .textimage--image-left .textimage__image, .module--textimage .textimage--image-right .textimage__image {
    float: none !important;
    margin: 0;
    width: 100%;
  }
  .module--textimage .textimage--image-left .textimage__image + .formatted-text, .module--textimage .textimage--image-right .textimage__image + .formatted-text {
    margin-top: 15px !important;
  }
  .module--textimage .textimage--image-left .formatted-text, .module--textimage .textimage--image-right .formatted-text {
    float: none;
    margin: 0;
    width: 100%;
  }
  .module--textimage .textimage--image-left .formatted-text + .textimage__image, .module--textimage .textimage--image-right .formatted-text + .textimage__image {
    margin-top: 15px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .module--textimage .textimage--image-left .textimage__image, .module--textimage .textimage--image-right .textimage__image {
    width: 48.7179487179%;
  }
  .module--textimage .textimage--image-left .formatted-text, .module--textimage .textimage--image-right .formatted-text {
    width: 48.7179487179%;
  }
}
.module--textimage .textimage--image-left .textimage__image {
  float: left;
  margin-right: 2.5641025641%;
}
.module--textimage .textimage--image-right .textimage__image {
  float: right;
  margin-left: 2.5641025641%;
}
.module--textimage .textimage--text-floating .formatted-text {
  width: 100%;
}
.module--textimage .textimage--text-floating .textimage__image {
  display: block;
  float: left;
  margin-bottom: 7.5px;
}
@media screen and (max-width: 480px) {
  .module--textimage .textimage--text-floating .textimage__image {
    margin: 0 !important;
    margin-bottom: 15px !important;
  }
}
.module--textimage .textimage--text-floating.textimage--image-right .textimage__image {
  float: right;
}

.module--video video {
  width: 100%;
  height: auto;
}

.section-heading--latest-news {
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}
@media screen and (max-width: 768px) {
  .section-heading--latest-news {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .section-heading--latest-news {
    margin-top: 15px !important;
  }
}

.latest-news {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  background: #f4f4f4;
}
.latest-news .outer-container {
  padding-top: 45px;
  padding-bottom: 45px;
}
@media screen and (max-width: 768px) {
  .latest-news .outer-container {
    padding-top: 22.5px;
    padding-bottom: 22.5px;
  }
}
.latest-news .outer-container:after {
  content: "";
  display: block;
  height: 23px;
  width: 23px;
}
.latest-news .outer-container--read-on {
  padding: 0;
  padding-bottom: 45px;
  padding-top: 30px;
  margin-top: -23px;
  text-align: right;
  border-top: 1px solid #ffffff;
}
@media screen and (max-width: 768px) {
  .latest-news .outer-container--read-on {
    padding-bottom: 22.5px;
  }
}
@media screen and (max-width: 768px) {
  .latest-news .outer-container--read-on {
    padding-top: 15px;
  }
}
.latest-news .outer-container--read-on:after {
  display: none;
  content: "";
}
.latest-news > a {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .latest-news > a {
    margin-top: 15px;
  }
}
.latest-news__child {
  position: relative;
  background: #ffffff;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .latest-news__child {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .latest-news__child:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .latest-news__child {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .latest-news__child:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .latest-news__child {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .latest-news__child:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 768px) {
  .latest-news__child {
    margin-bottom: 30px;
  }
}
.latest-news__child > a:first-of-type {
  display: block;
  margin-bottom: 30px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
@media screen and (max-width: 768px) {
  .latest-news__child > a:first-of-type {
    margin-bottom: 15px;
  }
}
.latest-news__child > a:first-of-type:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.latest-news__child > a:first-of-type:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.latest-news__child > a:first-of-type:hover:before, .latest-news__child > a:first-of-type:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.latest-news__child > a:first-of-type:hover:after {
  left: 4px;
}
.latest-news__child > a:last-of-type {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -23px;
}
.latest-news__child img {
  display: block;
  width: 100%;
  height: auto;
}
.latest-news__child h3 {
  padding-right: 30px;
  padding-left: 30px;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .latest-news__child h3 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.latest-news__child h3 a {
  text-transform: uppercase;
  font-weight: 700;
}
.latest-news__child h3:after {
  display: block;
  content: "";
  height: 2px;
  width: 60px;
  margin-bottom: -15px;
  margin-top: 7.5px;
  background: #dcdcdc;
}
@media screen and (max-width: 768px) {
  .latest-news__child h3 {
    margin-bottom: 15px;
  }
}
.latest-news__child p {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 53px;
}
@media screen and (max-width: 768px) {
  .latest-news__child p {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .latest-news__child p {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 768px) {
  .latest-news__child p {
    padding-bottom: 38px;
  }
}

.news-preview {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .news-preview {
    margin-bottom: 15px;
  }
}
.news-preview:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  .news-preview__image {
    width: 31.6239316239%;
    float: left;
    margin-right: 2.5641025641%;
  }
  .news-preview__text {
    width: 65.811965812%;
    float: left;
  }
}
@media screen and (max-width: 768px) {
  .news-preview__image {
    margin-bottom: 15px;
  }
}
.news-preview__image a {
  display: block;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.news-preview__image a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.news-preview__image a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.news-preview__image a:hover:before, .news-preview__image a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.news-preview__image a:hover:after {
  left: 4px;
}
.news-preview__image a:hover {
  text-decoration: none;
  border-bottom: 0;
}
.news-preview__image img {
  display: block;
  width: 100%;
  height: auto;
}
.news-preview__text > h2 {
  margin-bottom: 0;
}
.news-preview__text > h2 a {
  color: #575757;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}
.news-preview__text > h2 a:hover {
  color: #aac858;
}
.news-preview__text > small {
  display: none;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .news-preview__text > small {
    margin-bottom: 7.5px;
  }
}
.news-preview__text > p:last-of-type {
  margin-bottom: 0;
}
.news-preview__text > a {
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .news-preview__text > a {
    margin-top: 7.5px;
  }
}

.news-page__preview-text {
  margin-bottom: 15px;
}
.news-page__preview-text p {
  font-size: 1.25rem;
}
.news-page__text .news-page__image {
  display: block;
  float: right;
  margin-left: 15px;
  margin-bottom: 7.5px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.news-page__text .news-page__image:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.news-page__text .news-page__image:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.news-page__text .news-page__image:hover:before, .news-page__text .news-page__image:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.news-page__text .news-page__image:hover:after {
  left: 4px;
}
.news-page__text .news-page__image img {
  display: block;
  width: 100%;
  height: auto;
}
.news-page__meta {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  .news-page__meta {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .news-page__meta {
    padding-top: 15px;
  }
}
.news-page__meta ul {
  margin: 0;
  padding: 0;
}
.news-page__meta ul li {
  display: block;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .news-page__meta ul li {
    margin-top: 15px;
  }
}
.news-page__meta ul li.news-page__prev {
  float: left;
}
.news-page__meta ul li.news-page__prev a {
  padding-left: 0;
  padding-right: 15px;
}
.news-page__meta ul li.news-page__prev a:after {
  display: none;
}
.news-page__meta ul li.news-page__prev a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f177";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  background: #252525;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 15px;
  transition: background linear 0.2s;
}
.news-page__meta ul li.news-page__next {
  float: right;
}
.page-media {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .page-media {
    margin-top: 15px;
  }
}
.page-media__images {
  margin: 0;
  padding: 0;
}
.page-media__images li {
  list-style: none !important;
}
@media screen and (max-width: 768px) {
  .page-media__images li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .page-media__images li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .page-media__images li {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .page-media__images li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .page-media__images li {
    float: left;
    width: 14.5299145299%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .page-media__images li:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(6n+7) {
    clear: both;
  }
}
.page-media__images li a {
  display: block;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.page-media__images li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.page-media__images li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.page-media__images li a:hover:before, .page-media__images li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.page-media__images li a:hover:after {
  left: 4px;
}
.page-media__images li a img {
  display: block;
  width: 100%;
  height: auto;
}
.page-media__images + .page-media__files {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .page-media__images + .page-media__files {
    margin-top: 15px;
  }
}

.page-slider {
  border-top: 2px solid #aac858;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.page-slider .bx-wrapper {
  position: relative;
}
.page-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  display: block;
  color: #ffffff;
  font-size: 2.5rem;
  background: #aac858;
  line-height: 1;
  width: 45px;
  padding: 20px 0;
  text-align: center;
  text-decoration: none;
  transition: background linear 0.2s;
}
.page-slider .slick-arrow i {
  width: auto;
  display: block;
}
.page-slider .slick-arrow:hover {
  background: #839f34;
}
.page-slider .slick-arrow.fa-chevron-left {
  left: 23px;
}
.page-slider .slick-arrow.fa-chevron-right {
  right: 23px;
}
.page-slider .slick-arrow.disabled {
  display: none;
}
@media screen and (max-width: 1660px) {
  .page-slider .slick-arrow a {
    font-size: 2.5rem;
    width: 45px;
  }
  .page-slider .slick-arrow a.fa-chevron-left {
    left: 15px;
  }
  .page-slider .slick-arrow a.fa-chevron-right {
    right: 15px;
  }
}
@media screen and (max-width: 1340px) {
  .page-slider .slick-arrow a {
    font-size: 2rem;
    width: 45px;
  }
}
@media screen and (max-width: 1036px) {
  .page-slider .slick-arrow a {
    font-size: 2rem;
    width: 45px;
  }
}
@media screen and (max-width: 480px) {
  .page-slider .slick-arrow {
    display: none;
  }
}
.page-slider .bx-pager {
  position: absolute;
  z-index: 20;
  bottom: 15px;
  left: 0;
  right: 0;
  font-size: 0;
  text-align: center;
}
.page-slider .bx-pager .bx-pager-item {
  display: inline-block;
  margin-right: 2px;
}
.page-slider .bx-pager .bx-pager-item:last-of-type {
  margin-right: 0;
}
.page-slider .bx-pager .bx-pager-item:last-of-type:first-of-type {
  display: none;
}
.page-slider .bx-pager .bx-pager-item a {
  display: block;
  width: 10px;
  height: 10px;
  background: #f4f4f4;
  border-radius: 100%;
  font-size: 0;
}
.page-slider .bx-pager .bx-pager-item a:hover {
  background: #e8e8e8;
}
.page-slider .bx-pager .bx-pager-item a.active {
  background: #aac858;
}
.page-slider .bx-pager .bx-pager-item a.active:hover {
  background: #92b23b;
}
.page-slider__slide {
  position: relative;
}
.page-slider__slide img {
  display: block;
  width: 100%;
  height: auto;
}
.page-slider__caption {
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.page-slider__caption-content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1170px;
}
.page-slider__caption-content > h3 {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "basic-sans", "Inter", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1;
}
.page-slider__caption-content > h3 > span {
  display: inline-block;
  background: rgba(48, 48, 48, 0.9);
  color: #ffffff;
  padding: 15px 15px 10px 15px;
  line-height: 1;
}
.page-slider__caption-content > h3 > span span {
  color: #aac858;
}
.page-slider__caption-content > p {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #575757;
}
.page-slider__caption-content > p span {
  display: inline-block;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.9);
}
.page-slider__caption-content > a {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .page-slider__caption-content > a {
    margin-top: 15px;
  }
}
@media screen and (max-width: 1340px) {
  .page-slider__caption {
    left: 75px;
    right: 75px;
  }
  .page-slider__caption-content > h3 {
    font-size: 1.75rem;
  }
  .page-slider__caption-content > p {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 768px) {
  .page-slider__caption-content > h3 {
    font-size: 1.5rem;
  }
  .page-slider__caption-content > h3 > span {
    padding: 15px 15px 10px 15px;
  }
  .page-slider__caption-content > p {
    font-size: 1rem;
  }
  .page-slider__caption-content > p span {
    padding: 10px 15px;
  }
}
@media screen and (max-width: 480px) {
  .page-slider__caption {
    left: 7.5px;
    right: 7.5px;
  }
  .page-slider__caption-content > p {
    display: none;
  }
}

#Form_SearchForm {
  font-size: 1rem;
}
#Form_SearchForm .system-message + *, #Form_SearchForm .message:not([style*="display: none"]) + *, #Form_SearchForm :not([style="display: none"]).message + * {
  margin-top: 0 !important;
}

.search-results__list > h2 {
  color: #aac858;
  text-transform: uppercase;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .search-results__list > h2 {
    margin-bottom: 15px;
  }
}
.search-results__all-from-type {
  margin-top: 15px;
}
.search-results__item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  .search-results__item {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .search-results__item {
    padding-bottom: 15px;
  }
}
.search-results__item:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.search-results__item > h2, .search-results__item > h3, .search-results__item > h4, .search-results__item > h5, .search-results__item > h6 {
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
}
.search-results__item > h2 a, .search-results__item > h3 a, .search-results__item > h4 a, .search-results__item > h5 a, .search-results__item > h6 a {
  line-height: 1;
  color: #575757;
  text-decoration: none;
  font-weight: 700;
}
.search-results__item > h2 a:hover, .search-results__item > h3 a:hover, .search-results__item > h4 a:hover, .search-results__item > h5 a:hover, .search-results__item > h6 a:hover {
  color: #aac858;
}
.search-results__item > p {
  margin-top: 15px;
}
.search-results__query-instance {
  display: inline-block;
  padding: 0.25em 0.5em;
  background: #f4f4f4;
  font-style: italic;
}
.search-results__read-more {
  margin-top: 15px;
}

.calendar-event__text {
  float: left;
  width: calc(100% - 230px - 2.5641025641%);
  margin-right: 2.5641025641%;
}
.calendar-event .calendar-entry__dates {
  float: left;
  width: 230px;
  text-align: left !important;
  margin-right: 0 !important;
}
.calendar-event .dates__headline {
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.calendar-event #Form_SignUp {
  clear: both;
  width: calc(100% - 230px - 2.5641025641%);
  padding-top: 30px;
}
@media screen and (max-width: 768px) {
  .calendar-event #Form_SignUp {
    padding-top: 15px;
  }
}
.calendar-event #Form_SignUp #Form_SignUp_HeaderFieldAnmeldung {
  margin-bottom: 15px;
  text-transform: uppercase;
}

.page__text + .calendar,
.main__text + .calendar {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .page__text + .calendar,
  .main__text + .calendar {
    margin-top: 15px;
  }
}

.calendar--calendar-view {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .calendar--calendar-view {
    margin-bottom: 15px;
  }
}
.calendar--list-view {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .calendar--list-view {
    margin-top: 15px;
  }
}
.calendar .fc-event-container > a[href] .fc-title:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f05a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-right: 0.5em;
}
.calendar .fc-event {
  padding: 6px;
  border-radius: 0;
}

.calendar-categories > strong {
  display: block;
  text-transform: uppercase;
  margin-bottom: 7.5px;
}
.calendar-categories i {
  margin-right: 0.5em;
}
.calendar-categories > ul {
  margin: -4px 0 0 -4px;
  padding: 0;
  font-size: 0;
}
.calendar-categories > ul li {
  display: inline-block;
  font-size: 1rem;
  margin: 4px 0 0 4px;
  padding: 7.5px;
  border-radius: 0;
}
.calendar-categories > ul li:last-of-type {
  border: 1px solid #dcdcdc;
}
.calendar-categories > ul li:last-of-type:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f05a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-right: 0.5em;
}

.calendar-entry {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .calendar-entry {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.calendar-entry:nth-of-type(odd) {
  background: #f4f4f4;
}
.calendar-entry__location {
  margin-right: 15px;
}
.calendar-entry__location i {
  margin-right: 0.25em;
}
.calendar-entry__category i {
  margin-right: 0.25em;
}
.calendar-entry__dates {
  float: left;
  width: 230px;
  margin-right: 2.5641025641%;
  text-align: right;
}
.calendar-entry__dates .date__day {
  display: block;
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 7.5px;
  padding-top: 7.5px;
  border-top: 1px dashed #dcdcdc;
}
.calendar-entry__dates .date__day:first-of-type {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.calendar-entry__dates .date__time {
  display: block;
}
.calendar-entry__dates .dates__date {
  margin-top: 7.5px;
  padding-top: 7.5px;
  border-top: 1px dashed #dcdcdc;
}
.calendar-entry__dates .dates__date:first-of-type {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.calendar-entry__details {
  float: left;
  width: calc(100% - 230px - 2.5641025641%);
}
.calendar-entry__details h3 {
  text-transform: uppercase;
  margin-bottom: 7.5px;
}
.calendar-entry__details h3 a {
  font-weight: 700;
}
.calendar-entry__details h3 a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f05a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-right: 0.25em;
}
.calendar-entry__details > p {
  margin-top: 7.5px;
}
.calendar-entry__details > a {
  margin-top: 7.5px;
}
@media screen and (max-width: 768px) {
  .calendar-entry .calendar-entry__dates {
    width: 100%;
    margin-right: 0;
    text-align: left;
  }
  .calendar-entry .calendar-entry__dates span {
    font-weight: 400;
  }
  .calendar-entry .calendar-entry__details {
    width: 100%;
  }
  .calendar-entry:nth-of-type(odd) {
    margin-left: -7.5px;
    margin-right: -7.5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.contact-person {
  position: fixed;
  right: 45px;
  bottom: 45px;
  z-index: 99999;
}
.contact-person__toggle {
  display: block;
  position: relative;
  z-index: 10;
  cursor: pointer;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #aac858;
  border-radius: 100%;
  transition: background 0.2s linear;
  color: #ffffff;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.35);
}
.contact-person__toggle:hover {
  background: #839f34;
}
.contact-person__details {
  display: none;
  position: absolute;
  padding: 15px;
  width: 350px;
  top: 15px;
  left: 15px;
  transform: translateX(-100%) translateY(-100%);
  background: #f4f4f4;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.35);
}
.contact-person__details .team-member__image {
  float: left;
  width: 100px;
  margin-right: 15px;
}
.contact-person__details .team-member__title {
  font-weight: 700;
}
.contact-person__details .team-member__info {
  float: left;
  width: 205px;
}
.contact-person__details .team-member__info li.team-member__separator {
  display: block;
  width: 60px;
  height: 2px;
  margin: 7.5px 0 10px 0;
  background: #dcdcdc;
  line-height: 1;
}
.contact-person__details .team-member__info li i {
  margin-right: 0.5em;
  color: #aac858;
}
.contact-person__details .team-member__info li a {
  text-decoration: none;
  color: #575757;
  font-weight: 300;
}
.contact-person__details .team-member__info li a:hover {
  color: #aac858;
}

@media screen and (max-width: 480px) {
  .team-members .team-member,
  .search-results__list--TeamMember .search-results__list-items .team-member {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .team-members .team-member:nth-of-type(-n + 1),
  .search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .team-members .team-member:nth-of-type(1n+1),
  .search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .team-members .team-member:nth-of-type(1n+2),
  .search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  .team-members .team-member,
  .search-results__list--TeamMember .search-results__list-items .team-member {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .team-members .team-member:nth-of-type(-n + 2),
  .search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .team-members .team-member:nth-of-type(2n+2),
  .search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .team-members .team-member:nth-of-type(2n+3),
  .search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(2n+3) {
    clear: both;
  }
}
.team-members .team-member__image,
.search-results__list--TeamMember .search-results__list-items .team-member__image {
  position: relative;
  float: left;
  margin-right: 15px;
  width: 29.8245614035%;
  background: #f4f4f4;
  text-align: center;
}
.team-members .team-member__image img,
.search-results__list--TeamMember .search-results__list-items .team-member__image img {
  display: block;
  width: 100%;
  height: auto;
}
.team-members .team-member__image--no-image,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image {
  position: relative;
}
.team-members .team-member__image--no-image:before,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 133.5294117647%;
}
.team-members .team-member__image--no-image > *:first-child,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image > *:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.team-members .team-member__image--no-image > *:first-child,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image > *:first-child {
  font-size: 2.5rem;
  color: #dcdcdc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  right: initial;
  bottom: initial;
}
.team-members .team-member__info,
.search-results__list--TeamMember .search-results__list-items .team-member__info {
  float: left;
  width: 64.9122807018%;
}
.team-members .team-member__info i,
.search-results__list--TeamMember .search-results__list-items .team-member__info i {
  margin-right: 0.5em;
  color: #aac858;
}
.team-members .team-member__info a,
.search-results__list--TeamMember .search-results__list-items .team-member__info a {
  font-weight: 300;
}
.team-members .team-member__separator,
.search-results__list--TeamMember .search-results__list-items .team-member__separator {
  line-height: 1;
  height: 15px;
}
.team-members .team-member__title,
.search-results__list--TeamMember .search-results__list-items .team-member__title {
  font-weight: 700;
}

#TeamPage .main__text {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  #TeamPage .main__text {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  #TeamPage .main__text {
    padding-bottom: 15px;
  }
}
#TeamPage .team-members__group {
  margin-bottom: 30px;
}
#TeamPage .team-members__group-title {
  margin-bottom: 15px;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

img {
  vertical-align: top;
}

html {
  background: #303030;
}

body {
  background: #ffffff;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.main {
  display: block;
}

*:focus {
  outline: 0;
}

.responsive-iframe-container .responsive-iframe--1_1 {
  position: relative;
}
.responsive-iframe-container .responsive-iframe--1_1:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.responsive-iframe-container .responsive-iframe--1_1 > *:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.responsive-iframe-container .responsive-iframe--4_3 {
  position: relative;
}
.responsive-iframe-container .responsive-iframe--4_3:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 75%;
}
.responsive-iframe-container .responsive-iframe--4_3 > *:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.responsive-iframe-container .responsive-iframe--3_2 {
  position: relative;
}
.responsive-iframe-container .responsive-iframe--3_2:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 66.6666666667%;
}
.responsive-iframe-container .responsive-iframe--3_2 > *:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.responsive-iframe-container .responsive-iframe--5_3 {
  position: relative;
}
.responsive-iframe-container .responsive-iframe--5_3:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 60%;
}
.responsive-iframe-container .responsive-iframe--5_3 > *:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.responsive-iframe-container .responsive-iframe--16_9 {
  position: relative;
}
.responsive-iframe-container .responsive-iframe--16_9:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.responsive-iframe-container .responsive-iframe--16_9 > *:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.responsive-iframe-container .responsive-iframe--3_1 {
  position: relative;
}
.responsive-iframe-container .responsive-iframe--3_1:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 33.3333333333%;
}
.responsive-iframe-container .responsive-iframe--3_1 > *:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.responsive-iframe-container .responsive-iframe iframe {
  width: 100%;
  height: 100%;
}

.contact-data {
  margin-bottom: 45px;
  overflow-x: hidden;
}
@media screen and (max-width: 768px) {
  .contact-data {
    margin-bottom: 22.5px;
  }
}
.contact-data ul {
  position: relative;
  width: 50%;
  margin-left: -1rem;
  margin-top: -0.5rem;
  padding: 30px;
  float: left;
}
.contact-data ul::before {
  content: "";
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  width: calc(100% + 1rem);
  height: calc(100% - 0.5rem);
}
.contact-data ul:nth-of-type(2) li:nth-of-type(2) {
  display: flex;
  align-items: baseline;
}
@media screen and (max-width: 1036px) {
  .contact-data ul {
    padding: 15px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .contact-data ul li:first-of-type {
    float: none;
    margin-bottom: 0.5em;
  }
}
@media screen and (max-width: 768px) {
  .contact-data ul {
    float: none;
    width: 100%;
  }
}
.contact-data ul:first-of-type::before {
  background: #f4f4f4;
}
.contact-data ul:last-of-type::before {
  background: #e8e8e8;
}
.contact-data ul li {
  position: relative;
  z-index: 1;
  display: block;
  float: left;
  margin-top: 0.5rem;
  margin-left: 1rem;
  line-height: 1;
}
@media screen and (max-width: 480px) {
  .contact-data ul li {
    line-height: 1.35em;
  }
  .contact-data ul li:first-of-type {
    display: none;
  }
}
.contact-data ul li:first-of-type {
  margin-right: 1em;
}
.contact-data ul li p {
  display: inline-block;
  line-height: 1;
  margin-right: 1em;
}
.contact-data ul li p:last-of-type {
  margin-right: 0;
}
.contact-data ul li strong,
.contact-data ul li a,
.contact-data ul li i {
  line-height: 1;
}
.contact-data ul li i {
  margin-right: 0.5em;
  color: #aac858;
}
.contact-data ul li a {
  font-weight: 300;
}

.cookie-confirm {
  position: fixed;
  z-index: 999999999999;
  background: #ffffff;
  width: 100%;
  max-width: calc(100% - 15px);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  box-shadow: 5px 5px 20px -10px #000000;
  right: 7.5px;
  bottom: 7.5px;
  max-height: 90%;
  overflow: auto;
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    max-height: 75% !important;
  }
}
@media screen and (min-width: 769px) {
  .cookie-confirm {
    max-width: 50%;
    right: 15px;
    bottom: 15px;
  }
}
@media screen and (max-height: 750px) {
  .cookie-confirm {
    right: 3.75px;
    bottom: 3.75px;
    max-width: calc(100% - 7.5px);
    padding: 7.5px;
  }
}
.cookie-confirm__top > span {
  display: block;
  font-family: "basic-sans", "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1;
}
@media screen and (max-height: 750px) {
  .cookie-confirm__top > span {
    font-size: 1rem;
  }
}
.cookie-confirm__top > p {
  line-height: 1.35em;
}
@media screen and (max-height: 750px) {
  .cookie-confirm__top > p {
    display: none !important;
  }
}
.cookie-confirm__top > b {
  display: block;
  margin-top: 15px;
}
.cookie-confirm__top > b + p {
  margin-top: 0.5em;
}
.cookie-confirm__bottom {
  margin-top: 1rem;
}
.cookie-confirm__checkboxes {
  position: relative;
}
.cookie-confirm__checkboxes > * {
  position: relative;
  font-size: 1rem;
  display: block;
  margin-top: 1rem;
}
@media screen and (max-height: 750px) {
  .cookie-confirm__checkboxes > * {
    margin-top: 0.5rem;
  }
}
.cookie-confirm__checkboxes > *:first-of-type {
  margin-top: 0 !important;
}
.cookie-confirm__checkboxes > * span {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.5em;
  color: #acacac;
  padding-left: 2em;
}
.cookie-confirm #cookie-confirm__submit,
.cookie-confirm #cookie-confirm__cancel {
  margin-top: 1rem;
}
@media screen and (min-width: 769px) {
  .cookie-confirm #cookie-confirm__submit,
  .cookie-confirm #cookie-confirm__cancel {
    margin-top: 1.25rem;
  }
}
#cookie-revoke {
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  padding: 10px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px -10px #000000;
  box-shadow: 0px 0px 20px -10px #000000;
  cursor: pointer;
}

#cookie-revoke:hover {
  color: #aac858;
}

.department {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  .department {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .department {
    padding-bottom: 15px;
  }
}
.department:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.department__image {
  width: 23.0769230769%;
  float: left;
  margin-right: 2.5641025641%;
}
.department__image a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.department__image a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.department__image a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.department__image a:hover:before, .department__image a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.department__image a:hover:after {
  left: 4px;
}
.department__image img {
  display: block;
  width: 100%;
  height: auto;
}
.department__info {
  width: 74.358974359%;
  float: left;
}
.department__info > h2 a {
  font-weight: 700;
  text-transform: uppercase;
}
.department__info > h2 + p {
  margin-top: 15px;
}
.department__info > p + .basic-holder__grand-children, .department__info > h2 + .basic-holder__grand-children {
  margin-top: 15px !important;
}

.footer {
  margin-top: 45px;
}
@media screen and (max-width: 768px) {
  .footer {
    margin-top: 22.5px;
  }
}
.footer__top {
  background: #3a3a3a;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .footer__top {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 769px) {
  .footer__top .outer-container {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  .footer__top ul:nth-of-type(2) {
    margin: 30px 0;
  }
}
.footer__top ul:nth-of-type(2) li:nth-of-type(2) {
  max-width: 250px;
  padding-left: 1.5em;
}
.footer__top ul:nth-of-type(2) li:nth-of-type(2) i {
  margin-left: -1.5em;
}
.footer__top ul:nth-of-type(2) li:nth-of-type(n+3) {
  padding-left: 1.5em;
}
.footer__top ul:nth-of-type(3) li p {
  padding-left: 1.5em;
}
.footer__top ul:nth-of-type(3) li p:nth-of-type(1) {
  display: inline-block;
  padding-left: 0;
}
.footer__top ul li {
  color: #acacac;
}
.footer__top ul li:first-of-type {
  text-transform: uppercase;
  font-size: 1.25rem;
  margin-bottom: 15px;
}
.footer__top ul li i {
  margin-right: 0.5em;
}
.footer__top ul li a {
  color: #acacac;
  font-weight: 300;
}
.footer__bottom {
  background: #303030;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .footer__bottom {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer .navigation--footer a {
  color: #acacac;
  text-transform: uppercase;
  font-weight: 300;
}

.main__text + form {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .main__text + form {
    margin-top: 15px;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #acacac;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #acacac;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #acacac;
}

:-moz-placeholder { /* Firefox 18- */
  color: #acacac;
}

form input,
form textarea,
form select {
  font-family: inherit;
  font-weight: 300;
  font-style: inherit;
  font-size: inherit;
  color: inherit;
  border: 1px solid #dcdcdc;
  outline: 0;
  background: transparent;
}
form input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
form textarea:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
form select:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]) {
  display: block;
  padding: 7.5px;
  width: 100%;
}
form input:focus,
form textarea:focus,
form select:focus {
  outline: 0;
  border: 1px solid #aac858;
}
form input textarea,
form textarea textarea,
form select textarea {
  resize: horizontal;
}
form input[type=submit],
form input[type=reset] {
  width: auto;
  font-size: 1rem;
  padding: 0 15px;
}
form .clear {
  margin-top: -15px;
}
form .field {
  margin-bottom: 15px;
}
form .field:last-of-type {
  margin-bottom: 0;
}
form .field > label.left {
  display: block;
  margin-bottom: 7.5px;
  font-weight: 700;
  text-transform: uppercase;
}
form .field .message {
  display: block;
  margin-top: 7.5px;
}
form .field .message.required {
  color: #eb6446;
}
form .field .description {
  display: block;
  font-size: 0.75rem;
}
form .Actions {
  margin-top: 15px;
}
form .progress-title {
  text-transform: uppercase;
}

#UserForm_Form .clear {
  margin-top: 0;
}

#MemberLoginForm_LoginForm #ForgotPassword {
  margin-top: 7.5px;
}

div.field {
  position: relative;
}
div.field.required > label:after, div.field[aria-required=true] > label:after {
  content: " *";
  color: #eb6446;
}
div.field small.error {
  position: absolute;
  right: 10px;
  top: 7.5px;
  z-index: 10;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  background: #eb6446;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  border-radius: 100%;
}
div.field legend.left {
  text-transform: uppercase;
}
div.field .userformsgroup > legend {
  text-transform: uppercase;
}

html button.step-button-next:before, html button.step-button-next:after {
  font-size: 0.75rem !important;
}
html button.step-button-prev {
  padding-left: 0 !important;
  padding-right: 15px !important;
}
html button.step-button-prev:before, html button.step-button-prev:after {
  font-size: 0.75rem !important;
}
html button.step-button-prev:after {
  font-family: "basic-sans", "Inter", sans-serif !important;
  font-weight: 400 !important;
  margin-left: 0 !important;
  background: none !important;
}
html button.step-button-prev:before {
  font-size: 0.75rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f177";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  background: #252525;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 15px;
  transition: background linear 0.2s;
}

.header {
  font-size: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1036px) {
  .header {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.header .header__logo-container {
  float: left;
  display: block;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header .header__logo-container {
    display: inherit;
  }
}
.header .header__logo {
  text-decoration: none;
}
.header .header__logo2 {
  width: 170px;
  margin-left: 1.4rem;
}
.header .header__logo2 img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 480px) {
  .header .header__logo2 {
    width: 130px;
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
.header .header__search {
  display: block;
  float: right;
  width: 48.7179487179%;
  padding-top: 20.5px;
}
.header .header__search form#Form_SearchForm {
  border: none;
}
.header .header__search fieldset {
  border: none;
}
.header .header__search fieldset #Form_SearchForm_Search_Holder {
  background-color: red;
  float: left;
  width: calc(100% - 45px);
}
.header .header__search fieldset #Form_SearchForm_Search_Holder input {
  height: 45px;
  line-height: 45px;
  border: 0;
  background: #f4f4f4;
  padding: 0 15px;
  color: #575757;
}
.header .header__search fieldset #Form_SearchForm_action_results {
  display: block;
  width: 45px;
  height: 45px;
  border: 0;
  background: #aac858;
  color: #ffffff;
  float: left;
  padding: 0;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  transition: background 0.2s linear;
}
.header .header__search fieldset #Form_SearchForm_action_results:hover {
  background: #839f34;
}
.header .header__search fieldset #Form_SearchForm_action_results span {
  font-size: 1.5rem;
  transform: rotate(-45deg) !important;
}
@media screen and (max-width: 480px) {
  .header .header__logo {
    width: 150px;
  }
  .header .header__logo img {
    width: 150px;
    height: 48px;
  }
  .header .header__search {
    padding-top: 1px;
  }
}

.main > *:last-child.job-descriptions {
  padding-bottom: 23px;
}
.main > *:last-child.latest-news {
  margin-bottom: -45px;
}
@media screen and (max-width: 768px) {
  .main > *:last-child.latest-news {
    margin-bottom: -22.5px;
  }
}

.job-descriptions + .modular-content-wrapper {
  margin-top: 23px;
}
.job-descriptions + .section-heading {
  margin-top: 68px !important;
}
@media screen and (max-width: 768px) {
  .job-descriptions + .section-heading {
    margin-top: 45.5px !important;
  }
}
.job-descriptions__more-button {
  clear: both;
  text-align: center;
  padding-top: 5.2631578947%;
}
@media screen and (max-width: 480px) {
  .job-descriptions__more-button {
    padding-top: 9.2105263158%;
  }
}
.job-descriptions__more-button a {
  display: block;
  padding: 15px;
  text-transform: uppercase;
  background: #4b4b4b;
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
  transition: background 0.2s linear;
}
.job-descriptions__more-button a:hover {
  background: #252525;
}
.job-descriptions__more-button a i:first-of-type {
  margin-right: 15px;
}
.job-descriptions__more-button a i:last-of-type {
  margin-left: 15px;
}
.job-descriptions__more-button span:first-of-type {
  display: inline-block;
}
.job-descriptions__more-button span:last-of-type {
  display: none;
}
.job-descriptions__more-button--active span:first-of-type {
  display: none;
}
.job-descriptions__more-button--active span:last-of-type {
  display: inline-block;
}
.job-descriptions__more-items {
  display: none;
  padding-top: 30px;
}
@media screen and (max-width: 768px) {
  .job-descriptions__more-items {
    padding-top: 15px;
  }
}
.job-descriptions__more-items--active {
  padding-bottom: 23px;
}

.job-description {
  position: relative;
  background: #f4f4f4;
}
@media screen and (max-width: 350px) {
  .job-description {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 12.8205128205%;
  }
  .job-description:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .job-description:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .job-description:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 480px) {
  .job-description {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 10.2564102564%;
  }
  .job-description:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .job-description:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .job-description:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .job-description {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 6.4102564103%;
  }
  .job-description:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .job-description:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .job-description:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .job-description {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 4.4871794872%;
  }
  .job-description:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .job-description:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .job-description:nth-of-type(3n+4) {
    clear: both;
  }
}
.job-description > a:first-of-type {
  display: block;
  margin-bottom: 30px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
@media screen and (max-width: 768px) {
  .job-description > a:first-of-type {
    margin-bottom: 15px;
  }
}
.job-description > a:first-of-type:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #aac858;
  background: rgba(23, 23, 23, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.25rem;
  width: 100%;
  transition: all 0.2s linear;
}
.job-description > a:first-of-type:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(170, 200, 88, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.job-description > a:first-of-type:hover:before, .job-description > a:first-of-type:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.job-description > a:first-of-type:hover:after {
  left: 4px;
}
.job-description > a:last-of-type {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -23px;
}
.job-description img {
  display: block;
  width: 100%;
  height: auto;
}
.job-description h3 {
  padding-right: 30px;
  padding-left: 30px;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .job-description h3 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.job-description h3 a {
  text-transform: uppercase;
  font-weight: 700;
}
.job-description h3:after {
  display: block;
  content: "";
  height: 2px;
  width: 60px;
  margin-bottom: -15px;
  margin-top: 7.5px;
  background: #dcdcdc;
}
@media screen and (max-width: 768px) {
  .job-description h3 {
    margin-bottom: 15px;
  }
}
.job-description p {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 53px;
}
@media screen and (max-width: 768px) {
  .job-description p {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .job-description p {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 768px) {
  .job-description p {
    padding-bottom: 38px;
  }
}

.latest-events {
  text-align: right;
}
.latest-events ul {
  text-align: left;
}
@media screen and (max-width: 480px) {
  .latest-events ul li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .latest-events ul li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .latest-events ul li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .latest-events ul li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 1036px) {
  .latest-events ul li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .latest-events ul li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .latest-events ul li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .latest-events ul li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .latest-events ul li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .latest-events ul li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .latest-events ul li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .latest-events ul li:nth-of-type(4n+5) {
    clear: both;
  }
}
.latest-events__date i {
  font-size: 1.25rem;
  margin-right: 0.25em;
}
.latest-events__date .date__day {
  font-weight: 700;
  font-size: 1.25rem;
}
.latest-events__date .date__time {
  display: block;
  margin-left: 1.25rem;
  padding-left: 0.6em;
  font-weight: 700;
}
.latest-events__title {
  display: block;
  margin-top: 0.25em;
  text-transform: uppercase;
  margin-left: 1.25rem;
  padding-left: 0.6em;
  color: #aac858;
  font-weight: 400;
}
.latest-events__title a {
  text-decoration: none;
}
.latest-events__title a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f0fe";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}

.loader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: rgba(255, 255, 255, 0.8);
}
.loader .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -25px;
  display: block !important;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.loader .spinner > div {
  display: inline-block;
  height: 100%;
  width: 4px;
  background: #aac858;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.loader .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.mobile-navigation-scrollbarhider {
  display: none;
}
.mobile-navigation-scrollbarhider--force-deactived {
  display: none !important;
}

@media (max-resolution: 1dppx) {
  .mobile-navigation--force-active + .mobile-navigation-scrollbarhider {
    content: "";
    display: block;
    position: fixed;
    top: -10px;
    bottom: -10px;
    right: 268px;
    width: 17px;
    background: #ffffff;
    z-index: 9999;
  }
}
.mobile-navigation {
  position: fixed;
  overflow-y: scroll;
  z-index: 9998;
  top: 0;
  bottom: 0;
  width: 270px;
  transition: right 0.2s ease;
  right: -270px;
  background: #ffffff;
}
@media screen and (max-width: 1036px) {
  .mobile-navigation--active, .mobile-navigation--force-active {
    right: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 1036px) {
  .mobile-navigation:not(.mobile-navigation--active) {
    right: -270px !important;
  }
}
@media (max-resolution: 1dppx) {
  .mobile-navigation {
    width: 287px;
    margin-right: -17px;
  }
  .mobile-navigation--active, .mobile-navigation--force-active {
    margin-right: -17px;
  }
}

.mobile-navigation-overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;
  opacity: 0;
  visibility: hidden;
  background: #000000;
  transition: all 0.2s ease;
}
@media screen and (max-width: 1036px) {
  .mobile-navigation-overlay--active {
    opacity: 0.8;
    visibility: visible;
  }
}
.mobile-navigation-overlay--force-active {
  opacity: 0.8;
  visibility: visible;
}

.navigation {
  position: relative;
  z-index: 500;
}
.navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navigation ul li {
  position: relative;
  line-height: 1;
  font-size: 1rem;
}
.navigation ul li[class*="--current"] > a, .navigation ul li[class*="--section"] > a, .navigation ul li:hover > a {
  color: #aac858;
}
.navigation ul li a {
  display: block;
  color: #575757;
  text-decoration: none;
  line-height: 1;
  transition: color linear 0.2s;
}
.navigation ul li a[href="javascript:void(0);"] {
  cursor: default;
}
.navigation:not(.navigation--mobile) .navigation__sub {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s linear, visibility 0.1s linear;
  position: absolute;
  background: #3a3a3a;
  padding: 10px 0;
}
.navigation:not(.navigation--mobile) .navigation__sub a {
  padding: 10px 15px;
  font-weight: 300;
}
.navigation--vertical .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--vertical ul li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top {
  font-size: 0;
}
.navigation--horizontal .navigation__top-link {
  display: inline-block;
  margin-right: 15px;
}
.navigation--horizontal .navigation__top-link:last-of-type {
  margin-right: 0;
}
.navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
  top: 1rem;
}
.navigation--horizontal .navigation__top-link > .navigation__sub {
  left: 0;
}
.navigation--horizontal .navigation__top-link > .navigation__sub .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--horizontal .navigation__top-link .navigation__sub {
  min-width: 100%;
}
.navigation--horizontal .navigation__top-link .navigation__sub li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub {
  left: auto;
  right: 0;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub .navigation__sub {
  transform: translateX(-100%);
  right: auto;
  left: 0;
}
.navigation .mobile-navigation-button {
  position: relative;
  bottom: -1px;
  display: none;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  transition: color 0.2s linear;
}
.navigation .mobile-navigation-button:hover {
  color: #aac858;
}
.navigation .mobile-navigation-button span {
  display: none;
}
.navigation .mobile-navigation-button span:first-of-type {
  display: inline;
}
.navigation .mobile-navigation-button i {
  margin-right: 15px;
}
.navigation--main:not(.navigation--mobile) {
  display: flex;
  background: #303030;
}
.navigation--main:not(.navigation--mobile) .navigation__home-link {
  margin-right: 15px;
  width: 76px;
  flex-shrink: 0;
  float: left;
}
.navigation--main:not(.navigation--mobile) .navigation__home-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px 0;
  background: #3a3a3a;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  line-height: 1;
  transition: color linear 0.2s;
}
.navigation--main:not(.navigation--mobile) .navigation__home-link a:hover {
  color: #aac858;
}
.navigation--main:not(.navigation--mobile) .navigation__home-link--current a {
  color: #aac858;
}
.navigation--main:not(.navigation--mobile) .navigation__top {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  padding-right: 15px;
}
.navigation--main:not(.navigation--mobile) li[class*="--current"] > a, .navigation--main:not(.navigation--mobile) li[class*="--section"] > a, .navigation--main:not(.navigation--mobile) li:hover > a {
  color: #aac858;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link {
  display: block;
  font-size: 1rem;
  margin-right: 0;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link a {
  color: #ffffff;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link > a {
  height: 100%;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 0;
  text-transform: uppercase;
  border-right: calc(30px * 0.5) solid transparent;
  border-left: calc(30px * 0.5) solid transparent;
  color: #ffffff;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link:hover > a, .navigation--main:not(.navigation--mobile) .navigation__top-link[class*="--current"] > a, .navigation--main:not(.navigation--mobile) .navigation__top-link[class*="--section"] > a {
  background: #aac858;
  border-right-color: #aac858;
  border-left-color: #aac858;
  color: #ffffff !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link:hover > a:before, .navigation--main:not(.navigation--mobile) .navigation__top-link[class*="--current"] > a:before, .navigation--main:not(.navigation--mobile) .navigation__top-link[class*="--section"] > a:before {
  background: #aac858;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.no-color:hover > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.no-color[class*="--current"] > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.no-color[class*="--section"] > a {
  color: #ffffff !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.no-color .navigation__sub {
  border-top-color: #aac858 !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.no-color .navigation__sub::before {
  color: #aac858 !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.no-color .navigation__sub .navigation__sub-link:hover > a {
  color: #aac858 !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.color-light-green:hover > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-light-green[class*="--current"] > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-light-green[class*="--section"] > a {
  background: #aac858;
  border-right-color: #aac858;
  border-left-color: #aac858;
  color: #ffffff !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.color-red:hover > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-red[class*="--current"] > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-red[class*="--section"] > a {
  background: #eb6446;
  border-right-color: #eb6446;
  border-left-color: #eb6446;
  color: #ffffff !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.color-orange:hover > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-orange[class*="--current"] > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-orange[class*="--section"] > a {
  background: #de8530;
  border-right-color: #de8530;
  border-left-color: #de8530;
  color: #ffffff !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.color-blue:hover > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-blue[class*="--current"] > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-blue[class*="--section"] > a {
  background: #488ccb;
  border-right-color: #488ccb;
  border-left-color: #488ccb;
  color: #ffffff !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link.color-green:hover > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-green[class*="--current"] > a, .navigation--main:not(.navigation--mobile) .navigation__top-link.color-green[class*="--section"] > a {
  background: #5da55b;
  border-right-color: #5da55b;
  border-left-color: #5da55b;
  color: #ffffff !important;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link > .navigation__sub {
  border-top: 2px solid #839f34;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link > .navigation__sub:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f0d8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #839f34;
  position: absolute;
  top: -12px;
  left: 12px;
  text-align: left;
  vertical-align: bottom;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link > .navigation__sub .navigation__sub {
  border-left: 2px solid #303030;
  background: #474747;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub {
  left: auto;
  right: 0;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub:before {
  right: 12px;
  left: auto;
  text-align: right;
}
.navigation--main:not(.navigation--mobile) .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub .navigation__sub {
  border-left: 0;
  border-right: 2px solid #303030;
  transform: translateX(-100%);
  right: auto;
  left: 0;
}
.navigation--main:not(.navigation--mobile).navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  top: unset;
  bottom: 0;
  transform: translateY(100%);
}
@media screen and (max-width: 1036px) {
  .navigation--main:not(.navigation--mobile) .navigation__home-link {
    margin-right: 15px;
    width: 45px;
  }
  .navigation--main:not(.navigation--mobile) .navigation__home-link a {
    padding: 0;
    height: 45px;
    line-height: 45px;
  }
  .navigation--main:not(.navigation--mobile) .navigation__top {
    width: calc(100% - 60px);
    bottom: 0;
    padding-right: 15px;
  }
  .navigation--main:not(.navigation--mobile) .navigation__top-link > a {
    padding: 0;
    line-height: 45px;
    height: 45px;
  }
}
@media screen and (max-width: 1036px) {
  .navigation--main:not(.navigation--mobile) .mobile-navigation-button {
    display: block;
  }
  .navigation--main:not(.navigation--mobile) .navigation__top {
    display: none;
  }
}
.navigation--mobile ul li:hover > .navigation__sub {
  display: none;
  transform: translateX(0);
}
.navigation--mobile .navigation__sub {
  clear: both;
  position: static !important;
  display: none;
  border-top: 1px solid #dcdcdc;
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
  background: #e8e8e8;
}
.navigation--mobile .navigation__sub--active {
  display: block !important;
}
.navigation--mobile .navigation__sub .navigation__sub-link {
  border-bottom: 1px solid #dcdcdc;
}
.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 15px);
}
.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > .navigation__sub-toggle {
  padding: 7.5px;
  width: calc(1em + 15px);
}
.navigation--mobile .navigation__sub .navigation__sub-link:last-of-type {
  border-bottom: 0;
}
.navigation--mobile .navigation__sub-toggle {
  display: block;
  float: left;
  cursor: pointer;
  color: #acacac;
  line-height: 1;
}
.navigation--mobile .navigation__sub-toggle:hover, .navigation--mobile .navigation__sub-toggle--active {
  color: #575757;
}
.navigation--mobile .navigation__sub-toggle--active i:before {
  content: "\f056";
}
.navigation--mobile .navigation__top-link {
  border-bottom: 1px solid #dcdcdc;
}
.navigation--mobile .navigation__top-link > a {
  padding: 15px;
  font-weight: 700;
}
.navigation--mobile .navigation__top-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 15px);
}
.navigation--mobile .navigation__top-link > .navigation__sub-toggle {
  padding: 15px 7.5px;
  width: calc(1em + 15px);
}
.navigation--mobile .navigation__top-link > .navigation__sub a {
  padding: 7.5px 15px;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub {
  background: #cfcfcf;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > a {
  background: #cfcfcf;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > .navigation__sub-toggle {
  background: #cfcfcf;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > .navigation__sub {
  background: #b5b5b5;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > .navigation__sub > li > a {
  background: #b5b5b5;
}

html.mobile-navigation--open .mobile-navigation-button span:first-of-type {
  display: none;
}
html.mobile-navigation--open .mobile-navigation-button span:last-of-type {
  display: inline;
}

.page-title {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .page-title {
    margin-bottom: 15px;
  }
}
.page-title h1 {
  line-height: 1;
  text-transform: uppercase;
}
.page-title .breadcrumbs {
  margin-bottom: 7.5px;
  color: #acacac;
}
.page-title .breadcrumbs a {
  color: #acacac;
  font-weight: 300;
}
.page-title .breadcrumbs a:hover {
  color: #aac858;
}

.pagination {
  text-align: center;
  padding-top: 30px;
}
@media screen and (max-width: 768px) {
  .pagination {
    padding-top: 15px;
  }
}
.pagination:before {
  display: block;
  content: "";
  border-top: 1px solid #dcdcdc;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .pagination:before {
    margin-bottom: 15px;
  }
}
.pagination a,
.pagination span {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #f4f4f4;
  border-radius: 0;
}
.pagination a {
  color: #575757;
  text-decoration: none;
  transition: all 0.2s;
}
.pagination a:hover {
  background: #aac858;
  color: #FFFFFF;
}
.pagination span.pagination__current {
  background: #aac858;
  color: #FFFFFF;
}

.table-box-wrapper,
.table-scroll-wrapper {
  border-radius: 0;
  overflow: hidden;
  border: 1px solid #dcdcdc;
}
.table-box-wrapper td:last-of-type,
.table-box-wrapper th:last-of-type,
.table-scroll-wrapper td:last-of-type,
.table-scroll-wrapper th:last-of-type {
  border-right: 0;
}
.table-box-wrapper table:not(.table--resp) tbody tr:last-of-type td,
.table-scroll-wrapper table:not(.table--resp) tbody tr:last-of-type td {
  border-bottom: 0;
}
.table-box-wrapper .table--resp tbody tr:last-of-type td:last-of-type,
.table-scroll-wrapper .table--resp tbody tr:last-of-type td:last-of-type {
  border-bottom: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  float: none;
}
table tr {
  position: relative;
}
table tr th {
  text-align: left;
  font-weight: 700;
}
table tr th,
table tr td {
  padding: 7.5px;
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}
table.table--unstyled {
  width: auto;
  border-collapse: collapse;
  border-spacing: 0;
  float: none;
  border: 0;
}
table.table--unstyled tr th {
  text-align: left;
  font-weight: 400;
}
table.table--unstyled tr th,
table.table--unstyled tr td {
  padding: 0 15px 0 0;
  border-bottom: 0;
  border-right: 0;
}
table.table--unstyled tr th:last-of-type,
table.table--unstyled tr td:last-of-type {
  padding-right: 0;
}
table.table--resp {
  border-bottom: 0;
}
table.table--resp td,
table.table--resp th {
  border-right: 0;
}

.table--no-head tr:nth-of-type(even) {
  background: #f4f4f4;
}

.table--head-left.table--resp thead tr:first-of-type,
.table--head-top thead tr:first-of-type {
  background: #f4f4f4;
}
.table--head-left.table--resp tbody tr:nth-of-type(even),
.table--head-top tbody tr:nth-of-type(even) {
  background: #f4f4f4;
}
.table--head-left.table--resp.table--resp tr td:before,
.table--head-left.table--resp.table--resp tr th:before,
.table--head-top.table--resp tr td:before,
.table--head-top.table--resp tr th:before {
  font-size: 0.75rem;
}

.table--head-left.table--resp td:empty,
.table--head-left.table--resp th:empty,
.table--head-top.table--resp td:empty,
.table--head-top.table--resp th:empty {
  visibility: hidden;
  opacity: 0;
  padding: 0;
  border: 0;
}
.table--head-left.table--resp td:empty:before,
.table--head-left.table--resp th:empty:before,
.table--head-top.table--resp td:empty:before,
.table--head-top.table--resp th:empty:before {
  display: none;
}

.school-pages {
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
  .school-pages {
    margin-bottom: 22.5px;
  }
}
.school-pages__school {
  position: relative;
  float: left;
  width: 48.7179487179%;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .school-pages__school {
    margin-top: 15px;
  }
}
.school-pages__school:nth-of-type(-n+2) {
  margin-top: 0;
}
.school-pages__school:nth-of-type(2n+2) {
  margin-left: 2.5641025641%;
}
.school-pages__school:nth-of-type(2n+3) {
  clear: both;
}
.school-pages__school:hover h3 {
  color: #aac858;
}
.school-pages__school--red:hover h3 {
  color: #eb6446;
}
.school-pages__school--red .school-pages__school-details:after {
  background: #eb6446;
}
.school-pages__school--blue:hover h3 {
  color: #488ccb;
}
.school-pages__school--blue .school-pages__school-details:after {
  background: #488ccb;
}
.school-pages__school--green:hover h3 {
  color: #5da55b;
}
.school-pages__school--green .school-pages__school-details:after {
  background: #5da55b;
}
.school-pages__school--light-green:hover h3 {
  color: #aac858;
}
.school-pages__school--light-green .school-pages__school-details:after {
  background: #aac858;
}
.school-pages__school a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0;
  opacity: 0;
  z-index: 10;
}
.school-pages__school-image {
  width: 52.6315789474%;
}
.school-pages__school-image img {
  display: block;
  width: 100%;
  height: auto;
}
.school-pages__school-details {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  right: 0;
  margin-left: -10.5263157895%;
  padding: 15px 15px 15px 30px;
  width: 57.8947368421%;
  border: 1px solid #dcdcdc;
  background: #ffffff;
}
.school-pages__school-details:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
  z-index: 5;
  display: block;
  content: "";
  width: 12px;
  height: 75px;
  background: #aac858;
}
.school-pages__school-details:before {
  position: absolute;
  z-index: 1;
  left: -1px;
  top: -1px;
  bottom: -1px;
  content: "";
  display: block;
  width: 18.15263%;
  background: #ffffff;
}
.school-pages__school-details > * {
  position: relative;
  z-index: 10;
}
.school-pages__school-details h3 {
  text-transform: uppercase;
  transition: color 0.2s linear;
}
.school-pages__school-details h3:after {
  display: block;
  content: "";
  margin: 13px 0 15px 0;
  height: 2px;
  width: 60px;
  background: #dcdcdc;
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .school-pages__school-details {
    margin-left: -21.0526315789%;
    width: 68.4210526316%;
  }
  .school-pages__school-details:before {
    width: 30.89655%;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .school-pages__school {
    width: 100%;
    margin-left: 0 !important;
  }
  .school-pages__school:nth-of-type(-n+1) {
    margin-top: 0;
  }
  .school-pages__school:nth-of-type(2) {
    margin-top: 30px;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) and (max-width: 768px) {
  .school-pages__school:nth-of-type(2) {
    margin-top: 15px;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .school-pages__school-details {
    margin-left: -21.0526315789%;
    width: 68.4210526316%;
  }
  .school-pages__school-details:before {
    width: 30.89655%;
  }
}
@media screen and (max-width: 480px) {
  .school-pages__school {
    width: 100%;
    margin-left: 0 !important;
  }
  .school-pages__school:nth-of-type(-n+1) {
    margin-top: 0;
  }
  .school-pages__school:nth-of-type(2) {
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) and (max-width: 768px) {
  .school-pages__school:nth-of-type(2) {
    margin-top: 15px;
  }
}
@media screen and (max-width: 480px) {
  .school-pages__school-image {
    width: 100%;
  }
  .school-pages__school-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: calc(100% - 60px);
    margin-left: 0;
    border: 0;
    background: rgba(255, 255, 255, 0.8);
  }
  .school-pages__school-details:before {
    display: none;
  }
  .school-pages__school-details h3:after {
    background: #ffffff;
  }
}

.section-heading {
  text-align: center;
  font-size: 1.75rem;
  text-transform: uppercase;
  margin-bottom: 45px;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .section-heading {
    margin-bottom: 22.5px;
  }
}

.back-to-top {
  display: none;
  position: fixed;
  z-index: 9999;
  right: 13.3333333333px;
  bottom: 13.3333333333px;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  background: #e8e8e8;
  color: #575757 !important;
  border-radius: 100%;
  transition: background 0.2s linear;
}
.back-to-top:hover {
  background: #cfcfcf;
}
.back-to-top i:before {
  position: relative;
  top: -1px;
}

html .userform-progress {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  html .userform-progress {
    margin-bottom: 15px;
  }
}
html .userform-progress > p {
  margin-bottom: 7.5px;
}
html .userform-progress .progress {
  background: #f4f4f4;
  height: 20px;
}
html .userform-progress .progress-bar {
  background: #aac858;
  height: 20px;
}
html .userform-progress > nav {
  margin-top: -20px;
}
html .userform-progress > nav ul {
  font-size: 0;
}
html .userform-progress > nav ul li {
  font-size: 1rem;
  height: 20px;
}
html .userform-progress > nav ul li button {
  display: block;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 20px;
  min-width: 20px;
  text-align: center;
  background: #f4f4f4;
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  color: #575757;
}
html .userform-progress > nav ul li:first-of-type button {
  border-left: 0 !important;
}
html .userform-progress > nav ul li:last-of-type button {
  border-right: 0 !important;
}
html .userform-progress > nav ul li.viewed button {
  cursor: pointer;
  border-right: 1px solid #839f34;
  border-left: 1px solid #839f34;
  background: #aac858;
  color: #ffffff;
}
html .userform-progress > nav ul li.current button {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #839f34;
  background: #f4f4f4;
  color: #575757;
}
html .userform legend.left {
  font-weight: 700;
  margin-bottom: 7.5px;
}
html .userform .right-title {
  margin-top: 7.5px;
  font-style: italic;
  color: #acacac;
}
html .userform fieldset.field {
  margin-bottom: 15px;
}
html .userform .FormHeading {
  margin-bottom: 15px;
}
html .userform .step-navigation ul {
  font-size: 0;
}
html .userform .step-navigation ul li {
  font-size: 1rem;
}
html .userform .step-navigation ul li button.step-button-prev:not([style="display: none;"]) {
  margin-right: 7.5px;
}
html .userform .step-navigation ul li button.step-button-prev {
  font-size: 0;
}
html .userform .step-navigation ul li button.step-button-prev:before {
  font-size: 1rem;
}
html .userform .step-navigation ul li button.step-button-prev:after {
  display: inline;
  content: "zurück";
  font-size: 1rem;
}
html .userform .step-navigation ul li button.step-button-next {
  font-size: 0;
}
html .userform .step-navigation ul li button.step-button-next:after {
  font-size: 1rem;
}
html .userform .step-navigation ul li button.step-button-next:before {
  display: inline;
  content: "weiter";
  font-size: 1rem;
}
html .userform .userformsgroup {
  margin-top: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  html .userform .userformsgroup {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  html .userform .userformsgroup {
    padding-right: 15px;
    padding-left: 15px;
  }
}
html .userform .userformsgroup > legend {
  padding-left: 7.5px;
  padding-right: 7.5px;
  margin-left: -7.5px;
  border: 0;
  width: auto;
  font-weight: 700;
}
html .userform .step-button-wrapper.Actions {
  margin-top: 0;
  float: right;
}

.basic-holder__child {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdcdc;
}
@media screen and (max-width: 768px) {
  .basic-holder__child {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .basic-holder__child {
    padding-bottom: 15px;
  }
}
.basic-holder__child:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.basic-holder__child > h3 a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
}
.basic-holder__child > p + .basic-holder__grand-children, .basic-holder__child > h3 + .basic-holder__grand-children {
  margin-top: 15px !important;
}
#HomePage .latest-events {
  margin-top: 45px;
}
@media screen and (max-width: 768px) {
  #HomePage .latest-events {
    margin-top: 22.5px;
  }
}

#HomePage .main__text,
.announcement__text {
  margin-bottom: 45px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  background: #eb6446;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  #HomePage .main__text,
  .announcement__text {
    margin-bottom: 22.5px;
  }
}
@media screen and (max-width: 768px) {
  #HomePage .main__text,
  .announcement__text {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  #HomePage .main__text,
  .announcement__text {
    padding-right: 15px;
    padding-left: 15px;
  }
}
#HomePage .main__text a,
.announcement__text a {
  color: #ffffff;
  text-decoration: underline;
}
#HomePage .main__text a.button,
.announcement__text a.button {
  text-decoration: none;
  background: #a02b11;
}
#HomePage .main__text a.button:after,
.announcement__text a.button:after {
  background: #721f0c;
}
#HomePage .main__text a.button:hover,
.announcement__text a.button:hover {
  background: #721f0c;
}
#HomePage .main__text a.button:hover:after,
.announcement__text a.button:hover:after {
  background: #441207;
}

#JobDescriptionPage .main {
  width: 100% !important;
  max-width: unset !important;
}
#JobDescriptionPage .section-heading--latest-news {
  margin-top: 45px;
}
@media screen and (max-width: 768px) {
  #JobDescriptionPage .section-heading--latest-news {
    margin-top: 22.5px;
  }
}

#SchoolPage .main__text {
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
  #SchoolPage .main__text {
    margin-bottom: 22.5px;
  }
}
#SchoolPage .page-title {
  text-align: center;
}
#SchoolPage .section-heading--latest-news {
  margin-top: 45px;
}
@media screen and (max-width: 768px) {
  #SchoolPage .section-heading--latest-news {
    margin-top: 22.5px;
  }
}

.modular-content-wrapper {
  padding-top: 45px;
}
@media screen and (max-width: 768px) {
  .modular-content-wrapper {
    padding-top: 22.5px;
  }
}
.modular-content-wrapper .module__title {
  text-align: center;
}
.modular-content-wrapper .module__title h3 {
  font-size: 1.75rem;
}
.modular-content-wrapper .module--headline {
  text-align: center;
}
.modular-content-wrapper .module--headline h2 {
  font-size: 1.75rem;
}

#UserDefinedForm .main__text {
  float: left;
  width: 48.7179487179%;
  margin-right: 2.5641025641%;
}
#UserDefinedForm .main__text + #UserForm_Form {
  margin-top: 30px;
  float: left;
  width: 48.7179487179%;
  clear: none;
}
@media screen and (min-width: 769px) {
  #UserDefinedForm .main__text + #UserForm_Form {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  #UserDefinedForm .main__text {
    width: 100%;
    margin-right: 0;
  }
  #UserDefinedForm .main__text + #UserForm_Form {
    width: 100%;
  }
}

.color-red ::selection {
  background: #eb6446;
}
.color-red ::-moz-selection {
  background: #eb6446;
}
.color-red .change-color, html .color-red .textimage__image a:after,
html .color-red .module--gallery ul li a:after,
html .color-red .grid__header--image a:after,
html .color-red .page-media__images a:after,
html .color-red .news-preview__image a:after,
html .color-red .news-page__image:after,
html .color-red .department__image a:after,
.color-red html .color-blue .textimage__image a:after,
html .color-blue .textimage__image .color-red a:after,
.color-red html .color-blue .module--gallery ul li a:after,
html .color-blue .module--gallery ul li .color-red a:after,
.color-red html .color-blue .grid__header--image a:after,
html .color-blue .grid__header--image .color-red a:after,
.color-red html .color-blue .page-media__images a:after,
html .color-blue .page-media__images .color-red a:after,
.color-red html .color-blue .news-preview__image a:after,
html .color-blue .news-preview__image .color-red a:after,
.color-red html .color-blue .news-page__image:after,
html .color-blue .color-red .news-page__image:after,
.color-red html .color-blue .department__image a:after,
html .color-blue .department__image .color-red a:after,
.color-red html .color-green .textimage__image a:after,
html .color-green .textimage__image .color-red a:after,
.color-red html .color-green .module--gallery ul li a:after,
html .color-green .module--gallery ul li .color-red a:after,
.color-red html .color-green .grid__header--image a:after,
html .color-green .grid__header--image .color-red a:after,
.color-red html .color-green .page-media__images a:after,
html .color-green .page-media__images .color-red a:after,
.color-red html .color-green .news-preview__image a:after,
html .color-green .news-preview__image .color-red a:after,
.color-red html .color-green .news-page__image:after,
html .color-green .color-red .news-page__image:after,
.color-red html .color-green .department__image a:after,
html .color-green .department__image .color-red a:after,
.color-red html .color-orange .textimage__image a:after,
html .color-orange .textimage__image .color-red a:after,
.color-red html .color-orange .module--gallery ul li a:after,
html .color-orange .module--gallery ul li .color-red a:after,
.color-red html .color-orange .grid__header--image a:after,
html .color-orange .grid__header--image .color-red a:after,
.color-red html .color-orange .page-media__images a:after,
html .color-orange .page-media__images .color-red a:after,
.color-red html .color-orange .news-preview__image a:after,
html .color-orange .news-preview__image .color-red a:after,
.color-red html .color-orange .news-page__image:after,
html .color-orange .color-red .news-page__image:after,
.color-red html .color-orange .department__image a:after,
html .color-orange .department__image .color-red a:after, html .color-red .basic-holder__grand-children li a,
.color-red html .color-blue .basic-holder__grand-children li a,
html .color-blue .basic-holder__grand-children li .color-red a,
.color-red html .color-green .basic-holder__grand-children li a,
html .color-green .basic-holder__grand-children li .color-red a,
.color-red html .color-orange .basic-holder__grand-children li a,
html .color-orange .basic-holder__grand-children li .color-red a, html .color-red .module--downloads span a,
.color-red html .color-blue .module--downloads span a,
html .color-blue .module--downloads span .color-red a,
.color-red html .color-green .module--downloads span a,
html .color-green .module--downloads span .color-red a,
.color-red html .color-orange .module--downloads span a,
html .color-orange .module--downloads span .color-red a, html .color-red .team-member i,
.color-red html .color-blue .team-member i,
html .color-blue .team-member .color-red i,
.color-red html .color-green .team-member i,
html .color-green .team-member .color-red i,
.color-red html .color-orange .team-member i,
html .color-orange .team-member .color-red i, html .color-red .job-description > a:first-of-type:after,
html .color-red .latest-news__child > a:first-of-type:after,
.color-red html .color-blue .job-description > a:first-of-type:after,
html .color-blue .color-red .job-description > a:first-of-type:after,
.color-red html .color-blue .latest-news__child > a:first-of-type:after,
html .color-blue .color-red .latest-news__child > a:first-of-type:after,
.color-red html .color-green .job-description > a:first-of-type:after,
html .color-green .color-red .job-description > a:first-of-type:after,
.color-red html .color-green .latest-news__child > a:first-of-type:after,
html .color-green .color-red .latest-news__child > a:first-of-type:after,
.color-red html .color-orange .job-description > a:first-of-type:after,
html .color-orange .color-red .job-description > a:first-of-type:after,
.color-red html .color-orange .latest-news__child > a:first-of-type:after,
html .color-orange .color-red .latest-news__child > a:first-of-type:after, html .color-red .accordion__title i,
.color-red html .color-blue .accordion__title i,
html .color-blue .accordion__title .color-red i,
.color-red html .color-green .accordion__title i,
html .color-green .accordion__title .color-red i,
.color-red html .color-orange .accordion__title i,
html .color-orange .accordion__title .color-red i, html .color-red .main__text .text--highlight,
html .color-red .formatted-text .text--highlight,
.color-red html .color-blue .main__text .text--highlight,
html .color-blue .main__text .color-red .text--highlight,
.color-red html .color-blue .formatted-text .text--highlight,
html .color-blue .formatted-text .color-red .text--highlight,
.color-red html .color-green .main__text .text--highlight,
html .color-green .main__text .color-red .text--highlight,
.color-red html .color-green .formatted-text .text--highlight,
html .color-green .formatted-text .color-red .text--highlight,
.color-red html .color-orange .main__text .text--highlight,
html .color-orange .main__text .color-red .text--highlight,
.color-red html .color-orange .formatted-text .text--highlight,
html .color-orange .formatted-text .color-red .text--highlight, html .color-red .main__text a:not(.button),
html .color-red .formatted-text a:not(.button),
.color-red html .color-blue .main__text a:not(.button),
html .color-blue .main__text .color-red a:not(.button),
.color-red html .color-blue .formatted-text a:not(.button),
html .color-blue .formatted-text .color-red a:not(.button),
.color-red html .color-green .main__text a:not(.button),
html .color-green .main__text .color-red a:not(.button),
.color-red html .color-green .formatted-text a:not(.button),
html .color-green .formatted-text .color-red a:not(.button),
.color-red html .color-orange .main__text a:not(.button),
html .color-orange .main__text .color-red a:not(.button),
.color-red html .color-orange .formatted-text a:not(.button),
html .color-orange .formatted-text .color-red a:not(.button), html .color-red .navigation--mobile .navigation__top-link:hover > a,
html .color-red .navigation--mobile .navigation__sub-link:hover > a,
html .color-red .navigation--mobile .navigation__top-link--section > a,
html .color-red .navigation--mobile .navigation__top-link--current > a,
html .color-red .navigation--mobile .mobile-navigation-button:hover,
html .color-red .navigation--mobile .navigation__sub-link--section > a,
html .color-red .navigation--mobile .navigation__sub-link--current > a,
.color-red html .color-blue .navigation--mobile .navigation__top-link:hover > a,
html .color-blue .navigation--mobile .color-red .navigation__top-link:hover > a,
.color-red html .color-blue .navigation--mobile .navigation__sub-link:hover > a,
html .color-blue .navigation--mobile .color-red .navigation__sub-link:hover > a,
.color-red html .color-blue .navigation--mobile .navigation__top-link--section > a,
html .color-blue .navigation--mobile .color-red .navigation__top-link--section > a,
.color-red html .color-blue .navigation--mobile .navigation__top-link--current > a,
html .color-blue .navigation--mobile .color-red .navigation__top-link--current > a,
.color-red html .color-blue .navigation--mobile .mobile-navigation-button:hover,
html .color-blue .navigation--mobile .color-red .mobile-navigation-button:hover,
.color-red html .color-blue .navigation--mobile .navigation__sub-link--section > a,
html .color-blue .navigation--mobile .color-red .navigation__sub-link--section > a,
.color-red html .color-blue .navigation--mobile .navigation__sub-link--current > a,
html .color-blue .navigation--mobile .color-red .navigation__sub-link--current > a,
.color-red html .color-green .navigation--mobile .navigation__top-link:hover > a,
html .color-green .navigation--mobile .color-red .navigation__top-link:hover > a,
.color-red html .color-green .navigation--mobile .navigation__sub-link:hover > a,
html .color-green .navigation--mobile .color-red .navigation__sub-link:hover > a,
.color-red html .color-green .navigation--mobile .navigation__top-link--section > a,
html .color-green .navigation--mobile .color-red .navigation__top-link--section > a,
.color-red html .color-green .navigation--mobile .navigation__top-link--current > a,
html .color-green .navigation--mobile .color-red .navigation__top-link--current > a,
.color-red html .color-green .navigation--mobile .mobile-navigation-button:hover,
html .color-green .navigation--mobile .color-red .mobile-navigation-button:hover,
.color-red html .color-green .navigation--mobile .navigation__sub-link--section > a,
html .color-green .navigation--mobile .color-red .navigation__sub-link--section > a,
.color-red html .color-green .navigation--mobile .navigation__sub-link--current > a,
html .color-green .navigation--mobile .color-red .navigation__sub-link--current > a,
.color-red html .color-orange .navigation--mobile .navigation__top-link:hover > a,
html .color-orange .navigation--mobile .color-red .navigation__top-link:hover > a,
.color-red html .color-orange .navigation--mobile .navigation__sub-link:hover > a,
html .color-orange .navigation--mobile .color-red .navigation__sub-link:hover > a,
.color-red html .color-orange .navigation--mobile .navigation__top-link--section > a,
html .color-orange .navigation--mobile .color-red .navigation__top-link--section > a,
.color-red html .color-orange .navigation--mobile .navigation__top-link--current > a,
html .color-orange .navigation--mobile .color-red .navigation__top-link--current > a,
.color-red html .color-orange .navigation--mobile .mobile-navigation-button:hover,
html .color-orange .navigation--mobile .color-red .mobile-navigation-button:hover,
.color-red html .color-orange .navigation--mobile .navigation__sub-link--section > a,
html .color-orange .navigation--mobile .color-red .navigation__sub-link--section > a,
.color-red html .color-orange .navigation--mobile .navigation__sub-link--current > a,
html .color-orange .navigation--mobile .color-red .navigation__sub-link--current > a, html .color-red .navigation--main .navigation__top-link:hover > a,
html .color-red .navigation--main .navigation__sub-link:hover > a,
html .color-red .navigation--main .navigation__top-link--section > a,
html .color-red .navigation--main .navigation__top-link--current > a,
html .color-red .navigation--main .mobile-navigation-button:hover,
html .color-red .navigation--main .navigation__home-link:hover a,
.color-red html .color-blue .navigation--main .navigation__top-link:hover > a,
html .color-blue .navigation--main .color-red .navigation__top-link:hover > a,
.color-red html .color-blue .navigation--main .navigation__sub-link:hover > a,
html .color-blue .navigation--main .color-red .navigation__sub-link:hover > a,
.color-red html .color-blue .navigation--main .navigation__top-link--section > a,
html .color-blue .navigation--main .color-red .navigation__top-link--section > a,
.color-red html .color-blue .navigation--main .navigation__top-link--current > a,
html .color-blue .navigation--main .color-red .navigation__top-link--current > a,
.color-red html .color-blue .navigation--main .mobile-navigation-button:hover,
html .color-blue .navigation--main .color-red .mobile-navigation-button:hover,
.color-red html .color-blue .navigation--main .navigation__home-link:hover a,
html .color-blue .navigation--main .navigation__home-link:hover .color-red a,
.color-red html .color-green .navigation--main .navigation__top-link:hover > a,
html .color-green .navigation--main .color-red .navigation__top-link:hover > a,
.color-red html .color-green .navigation--main .navigation__sub-link:hover > a,
html .color-green .navigation--main .color-red .navigation__sub-link:hover > a,
.color-red html .color-green .navigation--main .navigation__top-link--section > a,
html .color-green .navigation--main .color-red .navigation__top-link--section > a,
.color-red html .color-green .navigation--main .navigation__top-link--current > a,
html .color-green .navigation--main .color-red .navigation__top-link--current > a,
.color-red html .color-green .navigation--main .mobile-navigation-button:hover,
html .color-green .navigation--main .color-red .mobile-navigation-button:hover,
.color-red html .color-green .navigation--main .navigation__home-link:hover a,
html .color-green .navigation--main .navigation__home-link:hover .color-red a,
.color-red html .color-orange .navigation--main .navigation__top-link:hover > a,
html .color-orange .navigation--main .color-red .navigation__top-link:hover > a,
.color-red html .color-orange .navigation--main .navigation__sub-link:hover > a,
html .color-orange .navigation--main .color-red .navigation__sub-link:hover > a,
.color-red html .color-orange .navigation--main .navigation__top-link--section > a,
html .color-orange .navigation--main .color-red .navigation__top-link--section > a,
.color-red html .color-orange .navigation--main .navigation__top-link--current > a,
html .color-orange .navigation--main .color-red .navigation__top-link--current > a,
.color-red html .color-orange .navigation--main .mobile-navigation-button:hover,
html .color-orange .navigation--main .color-red .mobile-navigation-button:hover,
.color-red html .color-orange .navigation--main .navigation__home-link:hover a,
html .color-orange .navigation--main .navigation__home-link:hover .color-red a, html .color-red .navigation--main .navigation__sub:before,
html .color-red .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-red .navigation--main .navigation__sub .navigation__sub-link--current > a,
.color-red html .color-blue .navigation--main .navigation__sub:before,
html .color-blue .navigation--main .color-red .navigation__sub:before,
.color-red html .color-blue .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-blue .navigation--main .navigation__sub .color-red .navigation__sub-link--section > a,
.color-red html .color-blue .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-blue .navigation--main .navigation__sub .color-red .navigation__sub-link--current > a,
.color-red html .color-green .navigation--main .navigation__sub:before,
html .color-green .navigation--main .color-red .navigation__sub:before,
.color-red html .color-green .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-green .navigation--main .navigation__sub .color-red .navigation__sub-link--section > a,
.color-red html .color-green .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-green .navigation--main .navigation__sub .color-red .navigation__sub-link--current > a,
.color-red html .color-orange .navigation--main .navigation__sub:before,
html .color-orange .navigation--main .color-red .navigation__sub:before,
.color-red html .color-orange .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-orange .navigation--main .navigation__sub .color-red .navigation__sub-link--section > a,
.color-red html .color-orange .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-orange .navigation--main .navigation__sub .color-red .navigation__sub-link--current > a, html .color-red .page-slider__caption-content > h3 > span span,
.color-red html .color-blue .page-slider__caption-content > h3 > span span,
html .color-blue .page-slider__caption-content > h3 > span .color-red span,
.color-red html .color-green .page-slider__caption-content > h3 > span span,
html .color-green .page-slider__caption-content > h3 > span .color-red span,
.color-red html .color-orange .page-slider__caption-content > h3 > span span,
html .color-orange .page-slider__caption-content > h3 > span .color-red span {
  color: #eb6446 !important;
}
.color-red .change-color-dark, html .color-red .accordion__title:hover i,
.color-red html .color-blue .accordion__title:hover i,
html .color-blue .accordion__title:hover .color-red i,
.color-red html .color-green .accordion__title:hover i,
html .color-green .accordion__title:hover .color-red i,
.color-red html .color-orange .accordion__title:hover i,
html .color-orange .accordion__title:hover .color-red i {
  color: #ce3816 !important;
}
.color-red .change-color-hover:hover, html .color-red .grid__header--icon a:hover,
html .color-red .news-preview__text > h2 a:hover,
html .color-red .basic-holder__child > h3 a:hover,
html .color-red .department__info > h2 a:hover,
.color-red html .color-blue .grid__header--icon a:hover,
html .color-blue .grid__header--icon .color-red a:hover,
.color-red html .color-blue .news-preview__text > h2 a:hover,
html .color-blue .news-preview__text > h2 .color-red a:hover,
.color-red html .color-blue .basic-holder__child > h3 a:hover,
html .color-blue .basic-holder__child > h3 .color-red a:hover,
.color-red html .color-blue .department__info > h2 a:hover,
html .color-blue .department__info > h2 .color-red a:hover,
.color-red html .color-green .grid__header--icon a:hover,
html .color-green .grid__header--icon .color-red a:hover,
.color-red html .color-green .news-preview__text > h2 a:hover,
html .color-green .news-preview__text > h2 .color-red a:hover,
.color-red html .color-green .basic-holder__child > h3 a:hover,
html .color-green .basic-holder__child > h3 .color-red a:hover,
.color-red html .color-green .department__info > h2 a:hover,
html .color-green .department__info > h2 .color-red a:hover,
.color-red html .color-orange .grid__header--icon a:hover,
html .color-orange .grid__header--icon .color-red a:hover,
.color-red html .color-orange .news-preview__text > h2 a:hover,
html .color-orange .news-preview__text > h2 .color-red a:hover,
.color-red html .color-orange .basic-holder__child > h3 a:hover,
html .color-orange .basic-holder__child > h3 .color-red a:hover,
.color-red html .color-orange .department__info > h2 a:hover,
html .color-orange .department__info > h2 .color-red a:hover, html .color-red .module--downloads li > a:hover,
.color-red html .color-blue .module--downloads li > a:hover,
html .color-blue .module--downloads .color-red li > a:hover,
.color-red html .color-green .module--downloads li > a:hover,
html .color-green .module--downloads .color-red li > a:hover,
.color-red html .color-orange .module--downloads li > a:hover,
html .color-orange .module--downloads .color-red li > a:hover, html .color-red .breadcrumbs a:hover,
.color-red html .color-blue .breadcrumbs a:hover,
html .color-blue .breadcrumbs .color-red a:hover,
.color-red html .color-green .breadcrumbs a:hover,
html .color-green .breadcrumbs .color-red a:hover,
.color-red html .color-orange .breadcrumbs a:hover,
html .color-orange .breadcrumbs .color-red a:hover, html .color-red .team-member a:hover,
.color-red html .color-blue .team-member a:hover,
html .color-blue .team-member .color-red a:hover,
.color-red html .color-green .team-member a:hover,
html .color-green .team-member .color-red a:hover,
.color-red html .color-orange .team-member a:hover,
html .color-orange .team-member .color-red a:hover, html .color-red .footer a:hover,
.color-red html .color-blue .footer a:hover,
html .color-blue .footer .color-red a:hover,
.color-red html .color-green .footer a:hover,
html .color-green .footer .color-red a:hover,
.color-red html .color-orange .footer a:hover,
html .color-orange .footer .color-red a:hover, html .color-red .job-description h3 a:hover,
html .color-red .latest-news__child h3 a:hover,
.color-red html .color-blue .job-description h3 a:hover,
html .color-blue .job-description h3 .color-red a:hover,
.color-red html .color-blue .latest-news__child h3 a:hover,
html .color-blue .latest-news__child h3 .color-red a:hover,
.color-red html .color-green .job-description h3 a:hover,
html .color-green .job-description h3 .color-red a:hover,
.color-red html .color-green .latest-news__child h3 a:hover,
html .color-green .latest-news__child h3 .color-red a:hover,
.color-red html .color-orange .job-description h3 a:hover,
html .color-orange .job-description h3 .color-red a:hover,
.color-red html .color-orange .latest-news__child h3 a:hover,
html .color-orange .latest-news__child h3 .color-red a:hover {
  color: #eb6446 !important;
}
.color-red .change-bg, html .color-red .textimage__image a:before,
html .color-red .module--gallery ul li a:before,
html .color-red .grid__header--image a:before,
html .color-red .page-media__images a:before,
html .color-red .news-preview__image a:before,
html .color-red .news-page__image:before,
html .color-red .department__image a:before,
.color-red html .color-blue .textimage__image a:before,
html .color-blue .textimage__image .color-red a:before,
.color-red html .color-blue .module--gallery ul li a:before,
html .color-blue .module--gallery ul li .color-red a:before,
.color-red html .color-blue .grid__header--image a:before,
html .color-blue .grid__header--image .color-red a:before,
.color-red html .color-blue .page-media__images a:before,
html .color-blue .page-media__images .color-red a:before,
.color-red html .color-blue .news-preview__image a:before,
html .color-blue .news-preview__image .color-red a:before,
.color-red html .color-blue .news-page__image:before,
html .color-blue .color-red .news-page__image:before,
.color-red html .color-blue .department__image a:before,
html .color-blue .department__image .color-red a:before,
.color-red html .color-green .textimage__image a:before,
html .color-green .textimage__image .color-red a:before,
.color-red html .color-green .module--gallery ul li a:before,
html .color-green .module--gallery ul li .color-red a:before,
.color-red html .color-green .grid__header--image a:before,
html .color-green .grid__header--image .color-red a:before,
.color-red html .color-green .page-media__images a:before,
html .color-green .page-media__images .color-red a:before,
.color-red html .color-green .news-preview__image a:before,
html .color-green .news-preview__image .color-red a:before,
.color-red html .color-green .news-page__image:before,
html .color-green .color-red .news-page__image:before,
.color-red html .color-green .department__image a:before,
html .color-green .department__image .color-red a:before,
.color-red html .color-orange .textimage__image a:before,
html .color-orange .textimage__image .color-red a:before,
.color-red html .color-orange .module--gallery ul li a:before,
html .color-orange .module--gallery ul li .color-red a:before,
.color-red html .color-orange .grid__header--image a:before,
html .color-orange .grid__header--image .color-red a:before,
.color-red html .color-orange .page-media__images a:before,
html .color-orange .page-media__images .color-red a:before,
.color-red html .color-orange .news-preview__image a:before,
html .color-orange .news-preview__image .color-red a:before,
.color-red html .color-orange .news-page__image:before,
html .color-orange .color-red .news-page__image:before,
.color-red html .color-orange .department__image a:before,
html .color-orange .department__image .color-red a:before, html .color-red .grid__link,
html .color-red .news-preview__text > a,
.color-red html .color-blue .grid__link,
.color-red html .color-blue .news-preview__text > a,
.color-red html .color-green .grid__link,
.color-red html .color-green .news-preview__text > a,
.color-red html .color-orange .grid__link,
.color-red html .color-orange .news-preview__text > a, html .color-red form input[type=submit],
.color-red html .color-blue form input[type=submit],
html .color-blue form .color-red input[type=submit],
.color-red html .color-green form input[type=submit],
html .color-green form .color-red input[type=submit],
.color-red html .color-orange form input[type=submit],
html .color-orange form .color-red input[type=submit], html .color-red .pagination__current,
.color-red html .color-blue .pagination__current,
.color-red html .color-green .pagination__current,
.color-red html .color-orange .pagination__current, html .color-red .textimage__image:after,
.color-red html .color-blue .textimage__image:after,
.color-red html .color-green .textimage__image:after,
.color-red html .color-orange .textimage__image:after, html .color-red #Form_SearchForm_action_results,
.color-red html .color-blue #Form_SearchForm_action_results,
.color-red html .color-green #Form_SearchForm_action_results,
.color-red html .color-orange #Form_SearchForm_action_results, html .color-red .job-description > a:first-of-type:before,
html .color-red .latest-news__child > a:first-of-type:before,
.color-red html .color-blue .job-description > a:first-of-type:before,
.color-red html .color-blue .latest-news__child > a:first-of-type:before,
.color-red html .color-green .job-description > a:first-of-type:before,
.color-red html .color-green .latest-news__child > a:first-of-type:before,
.color-red html .color-orange .job-description > a:first-of-type:before,
.color-red html .color-orange .latest-news__child > a:first-of-type:before, html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-red html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-red html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-red html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), html .color-red .main__text .text--background,
html .color-red .formatted-text .text--background,
.color-red html .color-blue .main__text .text--background,
html .color-blue .main__text .color-red .text--background,
.color-red html .color-blue .formatted-text .text--background,
html .color-blue .formatted-text .color-red .text--background,
.color-red html .color-green .main__text .text--background,
html .color-green .main__text .color-red .text--background,
.color-red html .color-green .formatted-text .text--background,
html .color-green .formatted-text .color-red .text--background,
.color-red html .color-orange .main__text .text--background,
html .color-orange .main__text .color-red .text--background,
.color-red html .color-orange .formatted-text .text--background,
html .color-orange .formatted-text .color-red .text--background, html .color-red .contact-person__toggle,
html .color-red .page-slider .bx-controls .bx-controls-direction a,
.color-red html .color-blue .contact-person__toggle,
.color-red html .color-blue .page-slider .bx-controls .bx-controls-direction a,
html .color-blue .page-slider .bx-controls .bx-controls-direction .color-red a,
.color-red html .color-green .contact-person__toggle,
.color-red html .color-green .page-slider .bx-controls .bx-controls-direction a,
html .color-green .page-slider .bx-controls .bx-controls-direction .color-red a,
.color-red html .color-orange .contact-person__toggle,
.color-red html .color-orange .page-slider .bx-controls .bx-controls-direction a,
html .color-orange .page-slider .bx-controls .bx-controls-direction .color-red a {
  background: #eb6446 !important;
}
.color-red .change-bg-hover:hover, html .color-red .grid__link:hover,
html .color-red .news-preview__text > a:hover,
.color-red html .color-blue .grid__link:hover,
.color-red html .color-blue .news-preview__text > a:hover,
.color-red html .color-green .grid__link:hover,
.color-red html .color-green .news-preview__text > a:hover,
.color-red html .color-orange .grid__link:hover,
.color-red html .color-orange .news-preview__text > a:hover, html .color-red form input[type=submit]:hover,
.color-red html .color-blue form input[type=submit]:hover,
html .color-blue form .color-red input[type=submit]:hover,
.color-red html .color-green form input[type=submit]:hover,
html .color-green form .color-red input[type=submit]:hover,
.color-red html .color-orange form input[type=submit]:hover,
html .color-orange form .color-red input[type=submit]:hover, html .color-red .pagination__link:hover,
html .color-red .pagination__next:hover,
html .color-red .pagination__prev:hover,
.color-red html .color-blue .pagination__link:hover,
.color-red html .color-blue .pagination__next:hover,
.color-red html .color-blue .pagination__prev:hover,
.color-red html .color-green .pagination__link:hover,
.color-red html .color-green .pagination__next:hover,
.color-red html .color-green .pagination__prev:hover,
.color-red html .color-orange .pagination__link:hover,
.color-red html .color-orange .pagination__next:hover,
.color-red html .color-orange .pagination__prev:hover, html .color-red #Form_SearchForm_action_results:hover,
.color-red html .color-blue #Form_SearchForm_action_results:hover,
.color-red html .color-green #Form_SearchForm_action_results:hover,
.color-red html .color-orange #Form_SearchForm_action_results:hover, html .color-red .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-red html .color-blue .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-red html .color-green .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-red html .color-orange .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), html .color-red .contact-person__toggle:hover,
html .color-red .page-slider .bx-controls .bx-controls-direction a:hover,
.color-red html .color-blue .contact-person__toggle:hover,
.color-red html .color-blue .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-blue .page-slider .bx-controls .bx-controls-direction .color-red a:hover,
.color-red html .color-green .contact-person__toggle:hover,
.color-red html .color-green .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-green .page-slider .bx-controls .bx-controls-direction .color-red a:hover,
.color-red html .color-orange .contact-person__toggle:hover,
.color-red html .color-orange .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-orange .page-slider .bx-controls .bx-controls-direction .color-red a:hover {
  background: #ce3816 !important;
}
.color-red .change-bg-dark, html .color-red .grid__link:after,
html .color-red .news-preview__text > a:after,
.color-red html .color-blue .grid__link:after,
.color-red html .color-blue .news-preview__text > a:after,
.color-red html .color-green .grid__link:after,
.color-red html .color-green .news-preview__text > a:after,
.color-red html .color-orange .grid__link:after,
.color-red html .color-orange .news-preview__text > a:after, html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-red html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-red html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-red html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after {
  background: #ce3816 !important;
}
.color-red .change-color-hover-dark:hover, html .color-red .basic-holder__grand-children li a:hover,
.color-red html .color-blue .basic-holder__grand-children li a:hover,
html .color-blue .basic-holder__grand-children li .color-red a:hover,
.color-red html .color-green .basic-holder__grand-children li a:hover,
html .color-green .basic-holder__grand-children li .color-red a:hover,
.color-red html .color-orange .basic-holder__grand-children li a:hover,
html .color-orange .basic-holder__grand-children li .color-red a:hover, html .color-red .module--downloads span a:hover,
.color-red html .color-blue .module--downloads span a:hover,
html .color-blue .module--downloads span .color-red a:hover,
.color-red html .color-green .module--downloads span a:hover,
html .color-green .module--downloads span .color-red a:hover,
.color-red html .color-orange .module--downloads span a:hover,
html .color-orange .module--downloads span .color-red a:hover, html .color-red .accordion__title i:hover,
.color-red html .color-blue .accordion__title i:hover,
html .color-blue .accordion__title .color-red i:hover,
.color-red html .color-green .accordion__title i:hover,
html .color-green .accordion__title .color-red i:hover,
.color-red html .color-orange .accordion__title i:hover,
html .color-orange .accordion__title .color-red i:hover, html .color-red .main__text a:hover:not(.button),
html .color-red .formatted-text a:hover:not(.button),
.color-red html .color-blue .main__text a:hover:not(.button),
html .color-blue .main__text .color-red a:hover:not(.button),
.color-red html .color-blue .formatted-text a:hover:not(.button),
html .color-blue .formatted-text .color-red a:hover:not(.button),
.color-red html .color-green .main__text a:hover:not(.button),
html .color-green .main__text .color-red a:hover:not(.button),
.color-red html .color-green .formatted-text a:hover:not(.button),
html .color-green .formatted-text .color-red a:hover:not(.button),
.color-red html .color-orange .main__text a:hover:not(.button),
html .color-orange .main__text .color-red a:hover:not(.button),
.color-red html .color-orange .formatted-text a:hover:not(.button),
html .color-orange .formatted-text .color-red a:hover:not(.button) {
  color: #ce3816 !important;
}
.color-red .change-border, html .color-red form input:not([type=submit]):not([type=reset]):focus,
.color-red html .color-blue form input:not([type=submit]):not([type=reset]):focus,
html .color-blue form .color-red input:not([type=submit]):not([type=reset]):focus,
.color-red html .color-green form input:not([type=submit]):not([type=reset]):focus,
html .color-green form .color-red input:not([type=submit]):not([type=reset]):focus,
.color-red html .color-orange form input:not([type=submit]):not([type=reset]):focus,
html .color-orange form .color-red input:not([type=submit]):not([type=reset]):focus, html .color-red .page-slider,
.color-red html .color-blue .page-slider,
.color-red html .color-green .page-slider,
.color-red html .color-orange .page-slider {
  border-color: #eb6446 !important;
}
.color-red .change-border-top, html .color-red .navigation--main .navigation__sub,
.color-red html .color-blue .navigation--main .navigation__sub,
html .color-blue .navigation--main .color-red .navigation__sub,
.color-red html .color-green .navigation--main .navigation__sub,
html .color-green .navigation--main .color-red .navigation__sub,
.color-red html .color-orange .navigation--main .navigation__sub,
html .color-orange .navigation--main .color-red .navigation__sub {
  border-top-color: #eb6446 !important;
}

.color-blue ::selection {
  background: #488ccb;
}
.color-blue ::-moz-selection {
  background: #488ccb;
}
.color-blue .change-color, .color-blue html .color-red .textimage__image a:after, html .color-red .textimage__image .color-blue a:after,
.color-blue html .color-red .module--gallery ul li a:after,
html .color-red .module--gallery ul li .color-blue a:after,
.color-blue html .color-red .grid__header--image a:after,
html .color-red .grid__header--image .color-blue a:after,
.color-blue html .color-red .page-media__images a:after,
html .color-red .page-media__images .color-blue a:after,
.color-blue html .color-red .news-preview__image a:after,
html .color-red .news-preview__image .color-blue a:after,
.color-blue html .color-red .news-page__image:after,
html .color-red .color-blue .news-page__image:after,
.color-blue html .color-red .department__image a:after,
html .color-red .department__image .color-blue a:after,
html .color-blue .textimage__image a:after,
html .color-blue .module--gallery ul li a:after,
html .color-blue .grid__header--image a:after,
html .color-blue .page-media__images a:after,
html .color-blue .news-preview__image a:after,
html .color-blue .news-page__image:after,
html .color-blue .department__image a:after,
.color-blue html .color-green .textimage__image a:after,
html .color-green .textimage__image .color-blue a:after,
.color-blue html .color-green .module--gallery ul li a:after,
html .color-green .module--gallery ul li .color-blue a:after,
.color-blue html .color-green .grid__header--image a:after,
html .color-green .grid__header--image .color-blue a:after,
.color-blue html .color-green .page-media__images a:after,
html .color-green .page-media__images .color-blue a:after,
.color-blue html .color-green .news-preview__image a:after,
html .color-green .news-preview__image .color-blue a:after,
.color-blue html .color-green .news-page__image:after,
html .color-green .color-blue .news-page__image:after,
.color-blue html .color-green .department__image a:after,
html .color-green .department__image .color-blue a:after,
.color-blue html .color-orange .textimage__image a:after,
html .color-orange .textimage__image .color-blue a:after,
.color-blue html .color-orange .module--gallery ul li a:after,
html .color-orange .module--gallery ul li .color-blue a:after,
.color-blue html .color-orange .grid__header--image a:after,
html .color-orange .grid__header--image .color-blue a:after,
.color-blue html .color-orange .page-media__images a:after,
html .color-orange .page-media__images .color-blue a:after,
.color-blue html .color-orange .news-preview__image a:after,
html .color-orange .news-preview__image .color-blue a:after,
.color-blue html .color-orange .news-page__image:after,
html .color-orange .color-blue .news-page__image:after,
.color-blue html .color-orange .department__image a:after,
html .color-orange .department__image .color-blue a:after, .color-blue html .color-red .basic-holder__grand-children li a, html .color-red .basic-holder__grand-children li .color-blue a,
html .color-blue .basic-holder__grand-children li a,
.color-blue html .color-green .basic-holder__grand-children li a,
html .color-green .basic-holder__grand-children li .color-blue a,
.color-blue html .color-orange .basic-holder__grand-children li a,
html .color-orange .basic-holder__grand-children li .color-blue a, .color-blue html .color-red .module--downloads span a, html .color-red .module--downloads span .color-blue a,
html .color-blue .module--downloads span a,
.color-blue html .color-green .module--downloads span a,
html .color-green .module--downloads span .color-blue a,
.color-blue html .color-orange .module--downloads span a,
html .color-orange .module--downloads span .color-blue a, .color-blue html .color-red .team-member i, html .color-red .team-member .color-blue i,
html .color-blue .team-member i,
.color-blue html .color-green .team-member i,
html .color-green .team-member .color-blue i,
.color-blue html .color-orange .team-member i,
html .color-orange .team-member .color-blue i, .color-blue html .color-red .job-description > a:first-of-type:after, html .color-red .color-blue .job-description > a:first-of-type:after,
.color-blue html .color-red .latest-news__child > a:first-of-type:after,
html .color-red .color-blue .latest-news__child > a:first-of-type:after,
html .color-blue .job-description > a:first-of-type:after,
html .color-blue .latest-news__child > a:first-of-type:after,
.color-blue html .color-green .job-description > a:first-of-type:after,
html .color-green .color-blue .job-description > a:first-of-type:after,
.color-blue html .color-green .latest-news__child > a:first-of-type:after,
html .color-green .color-blue .latest-news__child > a:first-of-type:after,
.color-blue html .color-orange .job-description > a:first-of-type:after,
html .color-orange .color-blue .job-description > a:first-of-type:after,
.color-blue html .color-orange .latest-news__child > a:first-of-type:after,
html .color-orange .color-blue .latest-news__child > a:first-of-type:after, .color-blue html .color-red .accordion__title i, html .color-red .accordion__title .color-blue i,
html .color-blue .accordion__title i,
.color-blue html .color-green .accordion__title i,
html .color-green .accordion__title .color-blue i,
.color-blue html .color-orange .accordion__title i,
html .color-orange .accordion__title .color-blue i, .color-blue html .color-red .main__text .text--highlight, html .color-red .main__text .color-blue .text--highlight,
.color-blue html .color-red .formatted-text .text--highlight,
html .color-red .formatted-text .color-blue .text--highlight,
html .color-blue .main__text .text--highlight,
html .color-blue .formatted-text .text--highlight,
.color-blue html .color-green .main__text .text--highlight,
html .color-green .main__text .color-blue .text--highlight,
.color-blue html .color-green .formatted-text .text--highlight,
html .color-green .formatted-text .color-blue .text--highlight,
.color-blue html .color-orange .main__text .text--highlight,
html .color-orange .main__text .color-blue .text--highlight,
.color-blue html .color-orange .formatted-text .text--highlight,
html .color-orange .formatted-text .color-blue .text--highlight, .color-blue html .color-red .main__text a:not(.button), html .color-red .main__text .color-blue a:not(.button),
.color-blue html .color-red .formatted-text a:not(.button),
html .color-red .formatted-text .color-blue a:not(.button),
html .color-blue .main__text a:not(.button),
html .color-blue .formatted-text a:not(.button),
.color-blue html .color-green .main__text a:not(.button),
html .color-green .main__text .color-blue a:not(.button),
.color-blue html .color-green .formatted-text a:not(.button),
html .color-green .formatted-text .color-blue a:not(.button),
.color-blue html .color-orange .main__text a:not(.button),
html .color-orange .main__text .color-blue a:not(.button),
.color-blue html .color-orange .formatted-text a:not(.button),
html .color-orange .formatted-text .color-blue a:not(.button), .color-blue html .color-red .navigation--mobile .navigation__top-link:hover > a, html .color-red .navigation--mobile .color-blue .navigation__top-link:hover > a,
.color-blue html .color-red .navigation--mobile .navigation__sub-link:hover > a,
html .color-red .navigation--mobile .color-blue .navigation__sub-link:hover > a,
.color-blue html .color-red .navigation--mobile .navigation__top-link--section > a,
html .color-red .navigation--mobile .color-blue .navigation__top-link--section > a,
.color-blue html .color-red .navigation--mobile .navigation__top-link--current > a,
html .color-red .navigation--mobile .color-blue .navigation__top-link--current > a,
.color-blue html .color-red .navigation--mobile .mobile-navigation-button:hover,
html .color-red .navigation--mobile .color-blue .mobile-navigation-button:hover,
.color-blue html .color-red .navigation--mobile .navigation__sub-link--section > a,
html .color-red .navigation--mobile .color-blue .navigation__sub-link--section > a,
.color-blue html .color-red .navigation--mobile .navigation__sub-link--current > a,
html .color-red .navigation--mobile .color-blue .navigation__sub-link--current > a,
html .color-blue .navigation--mobile .navigation__top-link:hover > a,
html .color-blue .navigation--mobile .navigation__sub-link:hover > a,
html .color-blue .navigation--mobile .navigation__top-link--section > a,
html .color-blue .navigation--mobile .navigation__top-link--current > a,
html .color-blue .navigation--mobile .mobile-navigation-button:hover,
html .color-blue .navigation--mobile .navigation__sub-link--section > a,
html .color-blue .navigation--mobile .navigation__sub-link--current > a,
.color-blue html .color-green .navigation--mobile .navigation__top-link:hover > a,
html .color-green .navigation--mobile .color-blue .navigation__top-link:hover > a,
.color-blue html .color-green .navigation--mobile .navigation__sub-link:hover > a,
html .color-green .navigation--mobile .color-blue .navigation__sub-link:hover > a,
.color-blue html .color-green .navigation--mobile .navigation__top-link--section > a,
html .color-green .navigation--mobile .color-blue .navigation__top-link--section > a,
.color-blue html .color-green .navigation--mobile .navigation__top-link--current > a,
html .color-green .navigation--mobile .color-blue .navigation__top-link--current > a,
.color-blue html .color-green .navigation--mobile .mobile-navigation-button:hover,
html .color-green .navigation--mobile .color-blue .mobile-navigation-button:hover,
.color-blue html .color-green .navigation--mobile .navigation__sub-link--section > a,
html .color-green .navigation--mobile .color-blue .navigation__sub-link--section > a,
.color-blue html .color-green .navigation--mobile .navigation__sub-link--current > a,
html .color-green .navigation--mobile .color-blue .navigation__sub-link--current > a,
.color-blue html .color-orange .navigation--mobile .navigation__top-link:hover > a,
html .color-orange .navigation--mobile .color-blue .navigation__top-link:hover > a,
.color-blue html .color-orange .navigation--mobile .navigation__sub-link:hover > a,
html .color-orange .navigation--mobile .color-blue .navigation__sub-link:hover > a,
.color-blue html .color-orange .navigation--mobile .navigation__top-link--section > a,
html .color-orange .navigation--mobile .color-blue .navigation__top-link--section > a,
.color-blue html .color-orange .navigation--mobile .navigation__top-link--current > a,
html .color-orange .navigation--mobile .color-blue .navigation__top-link--current > a,
.color-blue html .color-orange .navigation--mobile .mobile-navigation-button:hover,
html .color-orange .navigation--mobile .color-blue .mobile-navigation-button:hover,
.color-blue html .color-orange .navigation--mobile .navigation__sub-link--section > a,
html .color-orange .navigation--mobile .color-blue .navigation__sub-link--section > a,
.color-blue html .color-orange .navigation--mobile .navigation__sub-link--current > a,
html .color-orange .navigation--mobile .color-blue .navigation__sub-link--current > a, .color-blue html .color-red .navigation--main .navigation__top-link:hover > a, html .color-red .navigation--main .color-blue .navigation__top-link:hover > a,
.color-blue html .color-red .navigation--main .navigation__sub-link:hover > a,
html .color-red .navigation--main .color-blue .navigation__sub-link:hover > a,
.color-blue html .color-red .navigation--main .navigation__top-link--section > a,
html .color-red .navigation--main .color-blue .navigation__top-link--section > a,
.color-blue html .color-red .navigation--main .navigation__top-link--current > a,
html .color-red .navigation--main .color-blue .navigation__top-link--current > a,
.color-blue html .color-red .navigation--main .mobile-navigation-button:hover,
html .color-red .navigation--main .color-blue .mobile-navigation-button:hover,
.color-blue html .color-red .navigation--main .navigation__home-link:hover a,
html .color-red .navigation--main .navigation__home-link:hover .color-blue a,
html .color-blue .navigation--main .navigation__top-link:hover > a,
html .color-blue .navigation--main .navigation__sub-link:hover > a,
html .color-blue .navigation--main .navigation__top-link--section > a,
html .color-blue .navigation--main .navigation__top-link--current > a,
html .color-blue .navigation--main .mobile-navigation-button:hover,
html .color-blue .navigation--main .navigation__home-link:hover a,
.color-blue html .color-green .navigation--main .navigation__top-link:hover > a,
html .color-green .navigation--main .color-blue .navigation__top-link:hover > a,
.color-blue html .color-green .navigation--main .navigation__sub-link:hover > a,
html .color-green .navigation--main .color-blue .navigation__sub-link:hover > a,
.color-blue html .color-green .navigation--main .navigation__top-link--section > a,
html .color-green .navigation--main .color-blue .navigation__top-link--section > a,
.color-blue html .color-green .navigation--main .navigation__top-link--current > a,
html .color-green .navigation--main .color-blue .navigation__top-link--current > a,
.color-blue html .color-green .navigation--main .mobile-navigation-button:hover,
html .color-green .navigation--main .color-blue .mobile-navigation-button:hover,
.color-blue html .color-green .navigation--main .navigation__home-link:hover a,
html .color-green .navigation--main .navigation__home-link:hover .color-blue a,
.color-blue html .color-orange .navigation--main .navigation__top-link:hover > a,
html .color-orange .navigation--main .color-blue .navigation__top-link:hover > a,
.color-blue html .color-orange .navigation--main .navigation__sub-link:hover > a,
html .color-orange .navigation--main .color-blue .navigation__sub-link:hover > a,
.color-blue html .color-orange .navigation--main .navigation__top-link--section > a,
html .color-orange .navigation--main .color-blue .navigation__top-link--section > a,
.color-blue html .color-orange .navigation--main .navigation__top-link--current > a,
html .color-orange .navigation--main .color-blue .navigation__top-link--current > a,
.color-blue html .color-orange .navigation--main .mobile-navigation-button:hover,
html .color-orange .navigation--main .color-blue .mobile-navigation-button:hover,
.color-blue html .color-orange .navigation--main .navigation__home-link:hover a,
html .color-orange .navigation--main .navigation__home-link:hover .color-blue a, .color-blue html .color-red .navigation--main .navigation__sub:before, html .color-red .navigation--main .color-blue .navigation__sub:before,
.color-blue html .color-red .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-red .navigation--main .navigation__sub .color-blue .navigation__sub-link--section > a,
.color-blue html .color-red .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-red .navigation--main .navigation__sub .color-blue .navigation__sub-link--current > a,
html .color-blue .navigation--main .navigation__sub:before,
html .color-blue .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-blue .navigation--main .navigation__sub .navigation__sub-link--current > a,
.color-blue html .color-green .navigation--main .navigation__sub:before,
html .color-green .navigation--main .color-blue .navigation__sub:before,
.color-blue html .color-green .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-green .navigation--main .navigation__sub .color-blue .navigation__sub-link--section > a,
.color-blue html .color-green .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-green .navigation--main .navigation__sub .color-blue .navigation__sub-link--current > a,
.color-blue html .color-orange .navigation--main .navigation__sub:before,
html .color-orange .navigation--main .color-blue .navigation__sub:before,
.color-blue html .color-orange .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-orange .navigation--main .navigation__sub .color-blue .navigation__sub-link--section > a,
.color-blue html .color-orange .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-orange .navigation--main .navigation__sub .color-blue .navigation__sub-link--current > a, .color-blue html .color-red .page-slider__caption-content > h3 > span span, html .color-red .page-slider__caption-content > h3 > span .color-blue span,
html .color-blue .page-slider__caption-content > h3 > span span,
.color-blue html .color-green .page-slider__caption-content > h3 > span span,
html .color-green .page-slider__caption-content > h3 > span .color-blue span,
.color-blue html .color-orange .page-slider__caption-content > h3 > span span,
html .color-orange .page-slider__caption-content > h3 > span .color-blue span {
  color: #488ccb !important;
}
.color-blue .change-color-dark, .color-blue html .color-red .accordion__title:hover i, html .color-red .accordion__title:hover .color-blue i,
html .color-blue .accordion__title:hover i,
.color-blue html .color-green .accordion__title:hover i,
html .color-green .accordion__title:hover .color-blue i,
.color-blue html .color-orange .accordion__title:hover i,
html .color-orange .accordion__title:hover .color-blue i {
  color: #2c659b !important;
}
.color-blue .change-color-hover:hover, .color-blue html .color-red .grid__header--icon a:hover, html .color-red .grid__header--icon .color-blue a:hover,
.color-blue html .color-red .news-preview__text > h2 a:hover,
html .color-red .news-preview__text > h2 .color-blue a:hover,
.color-blue html .color-red .basic-holder__child > h3 a:hover,
html .color-red .basic-holder__child > h3 .color-blue a:hover,
.color-blue html .color-red .department__info > h2 a:hover,
html .color-red .department__info > h2 .color-blue a:hover,
html .color-blue .grid__header--icon a:hover,
html .color-blue .news-preview__text > h2 a:hover,
html .color-blue .basic-holder__child > h3 a:hover,
html .color-blue .department__info > h2 a:hover,
.color-blue html .color-green .grid__header--icon a:hover,
html .color-green .grid__header--icon .color-blue a:hover,
.color-blue html .color-green .news-preview__text > h2 a:hover,
html .color-green .news-preview__text > h2 .color-blue a:hover,
.color-blue html .color-green .basic-holder__child > h3 a:hover,
html .color-green .basic-holder__child > h3 .color-blue a:hover,
.color-blue html .color-green .department__info > h2 a:hover,
html .color-green .department__info > h2 .color-blue a:hover,
.color-blue html .color-orange .grid__header--icon a:hover,
html .color-orange .grid__header--icon .color-blue a:hover,
.color-blue html .color-orange .news-preview__text > h2 a:hover,
html .color-orange .news-preview__text > h2 .color-blue a:hover,
.color-blue html .color-orange .basic-holder__child > h3 a:hover,
html .color-orange .basic-holder__child > h3 .color-blue a:hover,
.color-blue html .color-orange .department__info > h2 a:hover,
html .color-orange .department__info > h2 .color-blue a:hover, .color-blue html .color-red .module--downloads li > a:hover, html .color-red .module--downloads .color-blue li > a:hover,
html .color-blue .module--downloads li > a:hover,
.color-blue html .color-green .module--downloads li > a:hover,
html .color-green .module--downloads .color-blue li > a:hover,
.color-blue html .color-orange .module--downloads li > a:hover,
html .color-orange .module--downloads .color-blue li > a:hover, .color-blue html .color-red .breadcrumbs a:hover, html .color-red .breadcrumbs .color-blue a:hover,
html .color-blue .breadcrumbs a:hover,
.color-blue html .color-green .breadcrumbs a:hover,
html .color-green .breadcrumbs .color-blue a:hover,
.color-blue html .color-orange .breadcrumbs a:hover,
html .color-orange .breadcrumbs .color-blue a:hover, .color-blue html .color-red .team-member a:hover, html .color-red .team-member .color-blue a:hover,
html .color-blue .team-member a:hover,
.color-blue html .color-green .team-member a:hover,
html .color-green .team-member .color-blue a:hover,
.color-blue html .color-orange .team-member a:hover,
html .color-orange .team-member .color-blue a:hover, .color-blue html .color-red .footer a:hover, html .color-red .footer .color-blue a:hover,
html .color-blue .footer a:hover,
.color-blue html .color-green .footer a:hover,
html .color-green .footer .color-blue a:hover,
.color-blue html .color-orange .footer a:hover,
html .color-orange .footer .color-blue a:hover, .color-blue html .color-red .job-description h3 a:hover, html .color-red .job-description h3 .color-blue a:hover,
.color-blue html .color-red .latest-news__child h3 a:hover,
html .color-red .latest-news__child h3 .color-blue a:hover,
html .color-blue .job-description h3 a:hover,
html .color-blue .latest-news__child h3 a:hover,
.color-blue html .color-green .job-description h3 a:hover,
html .color-green .job-description h3 .color-blue a:hover,
.color-blue html .color-green .latest-news__child h3 a:hover,
html .color-green .latest-news__child h3 .color-blue a:hover,
.color-blue html .color-orange .job-description h3 a:hover,
html .color-orange .job-description h3 .color-blue a:hover,
.color-blue html .color-orange .latest-news__child h3 a:hover,
html .color-orange .latest-news__child h3 .color-blue a:hover {
  color: #488ccb !important;
}
.color-blue .change-color-hover-dark:hover, .color-blue html .color-red .basic-holder__grand-children li a:hover, html .color-red .basic-holder__grand-children li .color-blue a:hover,
html .color-blue .basic-holder__grand-children li a:hover,
.color-blue html .color-green .basic-holder__grand-children li a:hover,
html .color-green .basic-holder__grand-children li .color-blue a:hover,
.color-blue html .color-orange .basic-holder__grand-children li a:hover,
html .color-orange .basic-holder__grand-children li .color-blue a:hover, .color-blue html .color-red .module--downloads span a:hover, html .color-red .module--downloads span .color-blue a:hover,
html .color-blue .module--downloads span a:hover,
.color-blue html .color-green .module--downloads span a:hover,
html .color-green .module--downloads span .color-blue a:hover,
.color-blue html .color-orange .module--downloads span a:hover,
html .color-orange .module--downloads span .color-blue a:hover, .color-blue html .color-red .accordion__title i:hover, html .color-red .accordion__title .color-blue i:hover,
html .color-blue .accordion__title i:hover,
.color-blue html .color-green .accordion__title i:hover,
html .color-green .accordion__title .color-blue i:hover,
.color-blue html .color-orange .accordion__title i:hover,
html .color-orange .accordion__title .color-blue i:hover, .color-blue html .color-red .main__text a:hover:not(.button), html .color-red .main__text .color-blue a:hover:not(.button),
.color-blue html .color-red .formatted-text a:hover:not(.button),
html .color-red .formatted-text .color-blue a:hover:not(.button),
html .color-blue .main__text a:hover:not(.button),
html .color-blue .formatted-text a:hover:not(.button),
.color-blue html .color-green .main__text a:hover:not(.button),
html .color-green .main__text .color-blue a:hover:not(.button),
.color-blue html .color-green .formatted-text a:hover:not(.button),
html .color-green .formatted-text .color-blue a:hover:not(.button),
.color-blue html .color-orange .main__text a:hover:not(.button),
html .color-orange .main__text .color-blue a:hover:not(.button),
.color-blue html .color-orange .formatted-text a:hover:not(.button),
html .color-orange .formatted-text .color-blue a:hover:not(.button) {
  color: #2c659b !important;
}
.color-blue .change-bg, .color-blue html .color-red .textimage__image a:before, html .color-red .textimage__image .color-blue a:before,
.color-blue html .color-red .module--gallery ul li a:before,
html .color-red .module--gallery ul li .color-blue a:before,
.color-blue html .color-red .grid__header--image a:before,
html .color-red .grid__header--image .color-blue a:before,
.color-blue html .color-red .page-media__images a:before,
html .color-red .page-media__images .color-blue a:before,
.color-blue html .color-red .news-preview__image a:before,
html .color-red .news-preview__image .color-blue a:before,
.color-blue html .color-red .news-page__image:before,
html .color-red .color-blue .news-page__image:before,
.color-blue html .color-red .department__image a:before,
html .color-red .department__image .color-blue a:before,
html .color-blue .textimage__image a:before,
html .color-blue .module--gallery ul li a:before,
html .color-blue .grid__header--image a:before,
html .color-blue .page-media__images a:before,
html .color-blue .news-preview__image a:before,
html .color-blue .news-page__image:before,
html .color-blue .department__image a:before,
.color-blue html .color-green .textimage__image a:before,
html .color-green .textimage__image .color-blue a:before,
.color-blue html .color-green .module--gallery ul li a:before,
html .color-green .module--gallery ul li .color-blue a:before,
.color-blue html .color-green .grid__header--image a:before,
html .color-green .grid__header--image .color-blue a:before,
.color-blue html .color-green .page-media__images a:before,
html .color-green .page-media__images .color-blue a:before,
.color-blue html .color-green .news-preview__image a:before,
html .color-green .news-preview__image .color-blue a:before,
.color-blue html .color-green .news-page__image:before,
html .color-green .color-blue .news-page__image:before,
.color-blue html .color-green .department__image a:before,
html .color-green .department__image .color-blue a:before,
.color-blue html .color-orange .textimage__image a:before,
html .color-orange .textimage__image .color-blue a:before,
.color-blue html .color-orange .module--gallery ul li a:before,
html .color-orange .module--gallery ul li .color-blue a:before,
.color-blue html .color-orange .grid__header--image a:before,
html .color-orange .grid__header--image .color-blue a:before,
.color-blue html .color-orange .page-media__images a:before,
html .color-orange .page-media__images .color-blue a:before,
.color-blue html .color-orange .news-preview__image a:before,
html .color-orange .news-preview__image .color-blue a:before,
.color-blue html .color-orange .news-page__image:before,
html .color-orange .color-blue .news-page__image:before,
.color-blue html .color-orange .department__image a:before,
html .color-orange .department__image .color-blue a:before, .color-blue html .color-red .grid__link,
.color-blue html .color-red .news-preview__text > a,
html .color-blue .grid__link,
html .color-blue .news-preview__text > a,
.color-blue html .color-green .grid__link,
.color-blue html .color-green .news-preview__text > a,
.color-blue html .color-orange .grid__link,
.color-blue html .color-orange .news-preview__text > a, .color-blue html .color-red form input[type=submit], html .color-red form .color-blue input[type=submit],
html .color-blue form input[type=submit],
.color-blue html .color-green form input[type=submit],
html .color-green form .color-blue input[type=submit],
.color-blue html .color-orange form input[type=submit],
html .color-orange form .color-blue input[type=submit], .color-blue html .color-red .pagination__current,
html .color-blue .pagination__current,
.color-blue html .color-green .pagination__current,
.color-blue html .color-orange .pagination__current, .color-blue html .color-red .textimage__image:after,
html .color-blue .textimage__image:after,
.color-blue html .color-green .textimage__image:after,
.color-blue html .color-orange .textimage__image:after, .color-blue html .color-red #Form_SearchForm_action_results,
html .color-blue #Form_SearchForm_action_results,
.color-blue html .color-green #Form_SearchForm_action_results,
.color-blue html .color-orange #Form_SearchForm_action_results, .color-blue html .color-red .job-description > a:first-of-type:before,
.color-blue html .color-red .latest-news__child > a:first-of-type:before,
html .color-blue .job-description > a:first-of-type:before,
html .color-blue .latest-news__child > a:first-of-type:before,
.color-blue html .color-green .job-description > a:first-of-type:before,
.color-blue html .color-green .latest-news__child > a:first-of-type:before,
.color-blue html .color-orange .job-description > a:first-of-type:before,
.color-blue html .color-orange .latest-news__child > a:first-of-type:before, .color-blue html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-blue html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-blue html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), .color-blue html .color-red .main__text .text--background, html .color-red .main__text .color-blue .text--background,
.color-blue html .color-red .formatted-text .text--background,
html .color-red .formatted-text .color-blue .text--background,
html .color-blue .main__text .text--background,
html .color-blue .formatted-text .text--background,
.color-blue html .color-green .main__text .text--background,
html .color-green .main__text .color-blue .text--background,
.color-blue html .color-green .formatted-text .text--background,
html .color-green .formatted-text .color-blue .text--background,
.color-blue html .color-orange .main__text .text--background,
html .color-orange .main__text .color-blue .text--background,
.color-blue html .color-orange .formatted-text .text--background,
html .color-orange .formatted-text .color-blue .text--background, .color-blue html .color-red .contact-person__toggle,
.color-blue html .color-red .page-slider .bx-controls .bx-controls-direction a,
html .color-red .page-slider .bx-controls .bx-controls-direction .color-blue a,
html .color-blue .contact-person__toggle,
html .color-blue .page-slider .bx-controls .bx-controls-direction a,
.color-blue html .color-green .contact-person__toggle,
.color-blue html .color-green .page-slider .bx-controls .bx-controls-direction a,
html .color-green .page-slider .bx-controls .bx-controls-direction .color-blue a,
.color-blue html .color-orange .contact-person__toggle,
.color-blue html .color-orange .page-slider .bx-controls .bx-controls-direction a,
html .color-orange .page-slider .bx-controls .bx-controls-direction .color-blue a {
  background: #488ccb !important;
}
.color-blue .change-bg-dark, .color-blue html .color-red .grid__link:after,
.color-blue html .color-red .news-preview__text > a:after,
html .color-blue .grid__link:after,
html .color-blue .news-preview__text > a:after,
.color-blue html .color-green .grid__link:after,
.color-blue html .color-green .news-preview__text > a:after,
.color-blue html .color-orange .grid__link:after,
.color-blue html .color-orange .news-preview__text > a:after, .color-blue html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-blue html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-blue html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after {
  background: #2c659b !important;
}
.color-blue .change-bg-hover:hover, .color-blue html .color-red .grid__link:hover,
.color-blue html .color-red .news-preview__text > a:hover,
html .color-blue .grid__link:hover,
html .color-blue .news-preview__text > a:hover,
.color-blue html .color-green .grid__link:hover,
.color-blue html .color-green .news-preview__text > a:hover,
.color-blue html .color-orange .grid__link:hover,
.color-blue html .color-orange .news-preview__text > a:hover, .color-blue html .color-red form input[type=submit]:hover, html .color-red form .color-blue input[type=submit]:hover,
html .color-blue form input[type=submit]:hover,
.color-blue html .color-green form input[type=submit]:hover,
html .color-green form .color-blue input[type=submit]:hover,
.color-blue html .color-orange form input[type=submit]:hover,
html .color-orange form .color-blue input[type=submit]:hover, .color-blue html .color-red .pagination__link:hover,
.color-blue html .color-red .pagination__next:hover,
.color-blue html .color-red .pagination__prev:hover,
html .color-blue .pagination__link:hover,
html .color-blue .pagination__next:hover,
html .color-blue .pagination__prev:hover,
.color-blue html .color-green .pagination__link:hover,
.color-blue html .color-green .pagination__next:hover,
.color-blue html .color-green .pagination__prev:hover,
.color-blue html .color-orange .pagination__link:hover,
.color-blue html .color-orange .pagination__next:hover,
.color-blue html .color-orange .pagination__prev:hover, .color-blue html .color-red #Form_SearchForm_action_results:hover,
html .color-blue #Form_SearchForm_action_results:hover,
.color-blue html .color-green #Form_SearchForm_action_results:hover,
.color-blue html .color-orange #Form_SearchForm_action_results:hover, .color-blue html .color-red .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
html .color-blue .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-blue html .color-green .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-blue html .color-orange .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), .color-blue html .color-red .contact-person__toggle:hover,
.color-blue html .color-red .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-red .page-slider .bx-controls .bx-controls-direction .color-blue a:hover,
html .color-blue .contact-person__toggle:hover,
html .color-blue .page-slider .bx-controls .bx-controls-direction a:hover,
.color-blue html .color-green .contact-person__toggle:hover,
.color-blue html .color-green .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-green .page-slider .bx-controls .bx-controls-direction .color-blue a:hover,
.color-blue html .color-orange .contact-person__toggle:hover,
.color-blue html .color-orange .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-orange .page-slider .bx-controls .bx-controls-direction .color-blue a:hover {
  background: #2c659b !important;
}
.color-blue .change-border, .color-blue html .color-red form input:not([type=submit]):not([type=reset]):focus, html .color-red form .color-blue input:not([type=submit]):not([type=reset]):focus,
html .color-blue form input:not([type=submit]):not([type=reset]):focus,
.color-blue html .color-green form input:not([type=submit]):not([type=reset]):focus,
html .color-green form .color-blue input:not([type=submit]):not([type=reset]):focus,
.color-blue html .color-orange form input:not([type=submit]):not([type=reset]):focus,
html .color-orange form .color-blue input:not([type=submit]):not([type=reset]):focus, .color-blue html .color-red .page-slider,
html .color-blue .page-slider,
.color-blue html .color-green .page-slider,
.color-blue html .color-orange .page-slider {
  border-color: #488ccb !important;
}
.color-blue .change-border-top, .color-blue html .color-red .navigation--main .navigation__sub, html .color-red .navigation--main .color-blue .navigation__sub,
html .color-blue .navigation--main .navigation__sub,
.color-blue html .color-green .navigation--main .navigation__sub,
html .color-green .navigation--main .color-blue .navigation__sub,
.color-blue html .color-orange .navigation--main .navigation__sub,
html .color-orange .navigation--main .color-blue .navigation__sub {
  border-top-color: #488ccb !important;
}

.color-green ::selection {
  background: #5da55b;
}
.color-green ::-moz-selection {
  background: #5da55b;
}
.color-green .change-color, .color-green html .color-red .textimage__image a:after, html .color-red .textimage__image .color-green a:after,
.color-green html .color-red .module--gallery ul li a:after,
html .color-red .module--gallery ul li .color-green a:after,
.color-green html .color-red .grid__header--image a:after,
html .color-red .grid__header--image .color-green a:after,
.color-green html .color-red .page-media__images a:after,
html .color-red .page-media__images .color-green a:after,
.color-green html .color-red .news-preview__image a:after,
html .color-red .news-preview__image .color-green a:after,
.color-green html .color-red .news-page__image:after,
html .color-red .color-green .news-page__image:after,
.color-green html .color-red .department__image a:after,
html .color-red .department__image .color-green a:after,
.color-green html .color-blue .textimage__image a:after,
html .color-blue .textimage__image .color-green a:after,
.color-green html .color-blue .module--gallery ul li a:after,
html .color-blue .module--gallery ul li .color-green a:after,
.color-green html .color-blue .grid__header--image a:after,
html .color-blue .grid__header--image .color-green a:after,
.color-green html .color-blue .page-media__images a:after,
html .color-blue .page-media__images .color-green a:after,
.color-green html .color-blue .news-preview__image a:after,
html .color-blue .news-preview__image .color-green a:after,
.color-green html .color-blue .news-page__image:after,
html .color-blue .color-green .news-page__image:after,
.color-green html .color-blue .department__image a:after,
html .color-blue .department__image .color-green a:after,
html .color-green .textimage__image a:after,
html .color-green .module--gallery ul li a:after,
html .color-green .grid__header--image a:after,
html .color-green .page-media__images a:after,
html .color-green .news-preview__image a:after,
html .color-green .news-page__image:after,
html .color-green .department__image a:after,
.color-green html .color-orange .textimage__image a:after,
html .color-orange .textimage__image .color-green a:after,
.color-green html .color-orange .module--gallery ul li a:after,
html .color-orange .module--gallery ul li .color-green a:after,
.color-green html .color-orange .grid__header--image a:after,
html .color-orange .grid__header--image .color-green a:after,
.color-green html .color-orange .page-media__images a:after,
html .color-orange .page-media__images .color-green a:after,
.color-green html .color-orange .news-preview__image a:after,
html .color-orange .news-preview__image .color-green a:after,
.color-green html .color-orange .news-page__image:after,
html .color-orange .color-green .news-page__image:after,
.color-green html .color-orange .department__image a:after,
html .color-orange .department__image .color-green a:after, .color-green html .color-red .basic-holder__grand-children li a, html .color-red .basic-holder__grand-children li .color-green a,
.color-green html .color-blue .basic-holder__grand-children li a,
html .color-blue .basic-holder__grand-children li .color-green a,
html .color-green .basic-holder__grand-children li a,
.color-green html .color-orange .basic-holder__grand-children li a,
html .color-orange .basic-holder__grand-children li .color-green a, .color-green html .color-red .module--downloads span a, html .color-red .module--downloads span .color-green a,
.color-green html .color-blue .module--downloads span a,
html .color-blue .module--downloads span .color-green a,
html .color-green .module--downloads span a,
.color-green html .color-orange .module--downloads span a,
html .color-orange .module--downloads span .color-green a, .color-green html .color-red .team-member i, html .color-red .team-member .color-green i,
.color-green html .color-blue .team-member i,
html .color-blue .team-member .color-green i,
html .color-green .team-member i,
.color-green html .color-orange .team-member i,
html .color-orange .team-member .color-green i, .color-green html .color-red .job-description > a:first-of-type:after, html .color-red .color-green .job-description > a:first-of-type:after,
.color-green html .color-red .latest-news__child > a:first-of-type:after,
html .color-red .color-green .latest-news__child > a:first-of-type:after,
.color-green html .color-blue .job-description > a:first-of-type:after,
html .color-blue .color-green .job-description > a:first-of-type:after,
.color-green html .color-blue .latest-news__child > a:first-of-type:after,
html .color-blue .color-green .latest-news__child > a:first-of-type:after,
html .color-green .job-description > a:first-of-type:after,
html .color-green .latest-news__child > a:first-of-type:after,
.color-green html .color-orange .job-description > a:first-of-type:after,
html .color-orange .color-green .job-description > a:first-of-type:after,
.color-green html .color-orange .latest-news__child > a:first-of-type:after,
html .color-orange .color-green .latest-news__child > a:first-of-type:after, .color-green html .color-red .accordion__title i, html .color-red .accordion__title .color-green i,
.color-green html .color-blue .accordion__title i,
html .color-blue .accordion__title .color-green i,
html .color-green .accordion__title i,
.color-green html .color-orange .accordion__title i,
html .color-orange .accordion__title .color-green i, .color-green html .color-red .main__text .text--highlight, html .color-red .main__text .color-green .text--highlight,
.color-green html .color-red .formatted-text .text--highlight,
html .color-red .formatted-text .color-green .text--highlight,
.color-green html .color-blue .main__text .text--highlight,
html .color-blue .main__text .color-green .text--highlight,
.color-green html .color-blue .formatted-text .text--highlight,
html .color-blue .formatted-text .color-green .text--highlight,
html .color-green .main__text .text--highlight,
html .color-green .formatted-text .text--highlight,
.color-green html .color-orange .main__text .text--highlight,
html .color-orange .main__text .color-green .text--highlight,
.color-green html .color-orange .formatted-text .text--highlight,
html .color-orange .formatted-text .color-green .text--highlight, .color-green html .color-red .main__text a:not(.button), html .color-red .main__text .color-green a:not(.button),
.color-green html .color-red .formatted-text a:not(.button),
html .color-red .formatted-text .color-green a:not(.button),
.color-green html .color-blue .main__text a:not(.button),
html .color-blue .main__text .color-green a:not(.button),
.color-green html .color-blue .formatted-text a:not(.button),
html .color-blue .formatted-text .color-green a:not(.button),
html .color-green .main__text a:not(.button),
html .color-green .formatted-text a:not(.button),
.color-green html .color-orange .main__text a:not(.button),
html .color-orange .main__text .color-green a:not(.button),
.color-green html .color-orange .formatted-text a:not(.button),
html .color-orange .formatted-text .color-green a:not(.button), .color-green html .color-red .navigation--mobile .navigation__top-link:hover > a, html .color-red .navigation--mobile .color-green .navigation__top-link:hover > a,
.color-green html .color-red .navigation--mobile .navigation__sub-link:hover > a,
html .color-red .navigation--mobile .color-green .navigation__sub-link:hover > a,
.color-green html .color-red .navigation--mobile .navigation__top-link--section > a,
html .color-red .navigation--mobile .color-green .navigation__top-link--section > a,
.color-green html .color-red .navigation--mobile .navigation__top-link--current > a,
html .color-red .navigation--mobile .color-green .navigation__top-link--current > a,
.color-green html .color-red .navigation--mobile .mobile-navigation-button:hover,
html .color-red .navigation--mobile .color-green .mobile-navigation-button:hover,
.color-green html .color-red .navigation--mobile .navigation__sub-link--section > a,
html .color-red .navigation--mobile .color-green .navigation__sub-link--section > a,
.color-green html .color-red .navigation--mobile .navigation__sub-link--current > a,
html .color-red .navigation--mobile .color-green .navigation__sub-link--current > a,
.color-green html .color-blue .navigation--mobile .navigation__top-link:hover > a,
html .color-blue .navigation--mobile .color-green .navigation__top-link:hover > a,
.color-green html .color-blue .navigation--mobile .navigation__sub-link:hover > a,
html .color-blue .navigation--mobile .color-green .navigation__sub-link:hover > a,
.color-green html .color-blue .navigation--mobile .navigation__top-link--section > a,
html .color-blue .navigation--mobile .color-green .navigation__top-link--section > a,
.color-green html .color-blue .navigation--mobile .navigation__top-link--current > a,
html .color-blue .navigation--mobile .color-green .navigation__top-link--current > a,
.color-green html .color-blue .navigation--mobile .mobile-navigation-button:hover,
html .color-blue .navigation--mobile .color-green .mobile-navigation-button:hover,
.color-green html .color-blue .navigation--mobile .navigation__sub-link--section > a,
html .color-blue .navigation--mobile .color-green .navigation__sub-link--section > a,
.color-green html .color-blue .navigation--mobile .navigation__sub-link--current > a,
html .color-blue .navigation--mobile .color-green .navigation__sub-link--current > a,
html .color-green .navigation--mobile .navigation__top-link:hover > a,
html .color-green .navigation--mobile .navigation__sub-link:hover > a,
html .color-green .navigation--mobile .navigation__top-link--section > a,
html .color-green .navigation--mobile .navigation__top-link--current > a,
html .color-green .navigation--mobile .mobile-navigation-button:hover,
html .color-green .navigation--mobile .navigation__sub-link--section > a,
html .color-green .navigation--mobile .navigation__sub-link--current > a,
.color-green html .color-orange .navigation--mobile .navigation__top-link:hover > a,
html .color-orange .navigation--mobile .color-green .navigation__top-link:hover > a,
.color-green html .color-orange .navigation--mobile .navigation__sub-link:hover > a,
html .color-orange .navigation--mobile .color-green .navigation__sub-link:hover > a,
.color-green html .color-orange .navigation--mobile .navigation__top-link--section > a,
html .color-orange .navigation--mobile .color-green .navigation__top-link--section > a,
.color-green html .color-orange .navigation--mobile .navigation__top-link--current > a,
html .color-orange .navigation--mobile .color-green .navigation__top-link--current > a,
.color-green html .color-orange .navigation--mobile .mobile-navigation-button:hover,
html .color-orange .navigation--mobile .color-green .mobile-navigation-button:hover,
.color-green html .color-orange .navigation--mobile .navigation__sub-link--section > a,
html .color-orange .navigation--mobile .color-green .navigation__sub-link--section > a,
.color-green html .color-orange .navigation--mobile .navigation__sub-link--current > a,
html .color-orange .navigation--mobile .color-green .navigation__sub-link--current > a, .color-green html .color-red .navigation--main .navigation__top-link:hover > a, html .color-red .navigation--main .color-green .navigation__top-link:hover > a,
.color-green html .color-red .navigation--main .navigation__sub-link:hover > a,
html .color-red .navigation--main .color-green .navigation__sub-link:hover > a,
.color-green html .color-red .navigation--main .navigation__top-link--section > a,
html .color-red .navigation--main .color-green .navigation__top-link--section > a,
.color-green html .color-red .navigation--main .navigation__top-link--current > a,
html .color-red .navigation--main .color-green .navigation__top-link--current > a,
.color-green html .color-red .navigation--main .mobile-navigation-button:hover,
html .color-red .navigation--main .color-green .mobile-navigation-button:hover,
.color-green html .color-red .navigation--main .navigation__home-link:hover a,
html .color-red .navigation--main .navigation__home-link:hover .color-green a,
.color-green html .color-blue .navigation--main .navigation__top-link:hover > a,
html .color-blue .navigation--main .color-green .navigation__top-link:hover > a,
.color-green html .color-blue .navigation--main .navigation__sub-link:hover > a,
html .color-blue .navigation--main .color-green .navigation__sub-link:hover > a,
.color-green html .color-blue .navigation--main .navigation__top-link--section > a,
html .color-blue .navigation--main .color-green .navigation__top-link--section > a,
.color-green html .color-blue .navigation--main .navigation__top-link--current > a,
html .color-blue .navigation--main .color-green .navigation__top-link--current > a,
.color-green html .color-blue .navigation--main .mobile-navigation-button:hover,
html .color-blue .navigation--main .color-green .mobile-navigation-button:hover,
.color-green html .color-blue .navigation--main .navigation__home-link:hover a,
html .color-blue .navigation--main .navigation__home-link:hover .color-green a,
html .color-green .navigation--main .navigation__top-link:hover > a,
html .color-green .navigation--main .navigation__sub-link:hover > a,
html .color-green .navigation--main .navigation__top-link--section > a,
html .color-green .navigation--main .navigation__top-link--current > a,
html .color-green .navigation--main .mobile-navigation-button:hover,
html .color-green .navigation--main .navigation__home-link:hover a,
.color-green html .color-orange .navigation--main .navigation__top-link:hover > a,
html .color-orange .navigation--main .color-green .navigation__top-link:hover > a,
.color-green html .color-orange .navigation--main .navigation__sub-link:hover > a,
html .color-orange .navigation--main .color-green .navigation__sub-link:hover > a,
.color-green html .color-orange .navigation--main .navigation__top-link--section > a,
html .color-orange .navigation--main .color-green .navigation__top-link--section > a,
.color-green html .color-orange .navigation--main .navigation__top-link--current > a,
html .color-orange .navigation--main .color-green .navigation__top-link--current > a,
.color-green html .color-orange .navigation--main .mobile-navigation-button:hover,
html .color-orange .navigation--main .color-green .mobile-navigation-button:hover,
.color-green html .color-orange .navigation--main .navigation__home-link:hover a,
html .color-orange .navigation--main .navigation__home-link:hover .color-green a, .color-green html .color-red .navigation--main .navigation__sub:before, html .color-red .navigation--main .color-green .navigation__sub:before,
.color-green html .color-red .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-red .navigation--main .navigation__sub .color-green .navigation__sub-link--section > a,
.color-green html .color-red .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-red .navigation--main .navigation__sub .color-green .navigation__sub-link--current > a,
.color-green html .color-blue .navigation--main .navigation__sub:before,
html .color-blue .navigation--main .color-green .navigation__sub:before,
.color-green html .color-blue .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-blue .navigation--main .navigation__sub .color-green .navigation__sub-link--section > a,
.color-green html .color-blue .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-blue .navigation--main .navigation__sub .color-green .navigation__sub-link--current > a,
html .color-green .navigation--main .navigation__sub:before,
html .color-green .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-green .navigation--main .navigation__sub .navigation__sub-link--current > a,
.color-green html .color-orange .navigation--main .navigation__sub:before,
html .color-orange .navigation--main .color-green .navigation__sub:before,
.color-green html .color-orange .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-orange .navigation--main .navigation__sub .color-green .navigation__sub-link--section > a,
.color-green html .color-orange .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-orange .navigation--main .navigation__sub .color-green .navigation__sub-link--current > a, .color-green html .color-red .page-slider__caption-content > h3 > span span, html .color-red .page-slider__caption-content > h3 > span .color-green span,
.color-green html .color-blue .page-slider__caption-content > h3 > span span,
html .color-blue .page-slider__caption-content > h3 > span .color-green span,
html .color-green .page-slider__caption-content > h3 > span span,
.color-green html .color-orange .page-slider__caption-content > h3 > span span,
html .color-orange .page-slider__caption-content > h3 > span .color-green span {
  color: #5da55b !important;
}
.color-green .change-color-dark, .color-green html .color-red .accordion__title:hover i, html .color-red .accordion__title:hover .color-green i,
.color-green html .color-blue .accordion__title:hover i,
html .color-blue .accordion__title:hover .color-green i,
html .color-green .accordion__title:hover i,
.color-green html .color-orange .accordion__title:hover i,
html .color-orange .accordion__title:hover .color-green i {
  color: #417440 !important;
}
.color-green .change-color-hover:hover, .color-green html .color-red .grid__header--icon a:hover, html .color-red .grid__header--icon .color-green a:hover,
.color-green html .color-red .news-preview__text > h2 a:hover,
html .color-red .news-preview__text > h2 .color-green a:hover,
.color-green html .color-red .basic-holder__child > h3 a:hover,
html .color-red .basic-holder__child > h3 .color-green a:hover,
.color-green html .color-red .department__info > h2 a:hover,
html .color-red .department__info > h2 .color-green a:hover,
.color-green html .color-blue .grid__header--icon a:hover,
html .color-blue .grid__header--icon .color-green a:hover,
.color-green html .color-blue .news-preview__text > h2 a:hover,
html .color-blue .news-preview__text > h2 .color-green a:hover,
.color-green html .color-blue .basic-holder__child > h3 a:hover,
html .color-blue .basic-holder__child > h3 .color-green a:hover,
.color-green html .color-blue .department__info > h2 a:hover,
html .color-blue .department__info > h2 .color-green a:hover,
html .color-green .grid__header--icon a:hover,
html .color-green .news-preview__text > h2 a:hover,
html .color-green .basic-holder__child > h3 a:hover,
html .color-green .department__info > h2 a:hover,
.color-green html .color-orange .grid__header--icon a:hover,
html .color-orange .grid__header--icon .color-green a:hover,
.color-green html .color-orange .news-preview__text > h2 a:hover,
html .color-orange .news-preview__text > h2 .color-green a:hover,
.color-green html .color-orange .basic-holder__child > h3 a:hover,
html .color-orange .basic-holder__child > h3 .color-green a:hover,
.color-green html .color-orange .department__info > h2 a:hover,
html .color-orange .department__info > h2 .color-green a:hover, .color-green html .color-red .module--downloads li > a:hover, html .color-red .module--downloads .color-green li > a:hover,
.color-green html .color-blue .module--downloads li > a:hover,
html .color-blue .module--downloads .color-green li > a:hover,
html .color-green .module--downloads li > a:hover,
.color-green html .color-orange .module--downloads li > a:hover,
html .color-orange .module--downloads .color-green li > a:hover, .color-green html .color-red .breadcrumbs a:hover, html .color-red .breadcrumbs .color-green a:hover,
.color-green html .color-blue .breadcrumbs a:hover,
html .color-blue .breadcrumbs .color-green a:hover,
html .color-green .breadcrumbs a:hover,
.color-green html .color-orange .breadcrumbs a:hover,
html .color-orange .breadcrumbs .color-green a:hover, .color-green html .color-red .team-member a:hover, html .color-red .team-member .color-green a:hover,
.color-green html .color-blue .team-member a:hover,
html .color-blue .team-member .color-green a:hover,
html .color-green .team-member a:hover,
.color-green html .color-orange .team-member a:hover,
html .color-orange .team-member .color-green a:hover, .color-green html .color-red .footer a:hover, html .color-red .footer .color-green a:hover,
.color-green html .color-blue .footer a:hover,
html .color-blue .footer .color-green a:hover,
html .color-green .footer a:hover,
.color-green html .color-orange .footer a:hover,
html .color-orange .footer .color-green a:hover, .color-green html .color-red .job-description h3 a:hover, html .color-red .job-description h3 .color-green a:hover,
.color-green html .color-red .latest-news__child h3 a:hover,
html .color-red .latest-news__child h3 .color-green a:hover,
.color-green html .color-blue .job-description h3 a:hover,
html .color-blue .job-description h3 .color-green a:hover,
.color-green html .color-blue .latest-news__child h3 a:hover,
html .color-blue .latest-news__child h3 .color-green a:hover,
html .color-green .job-description h3 a:hover,
html .color-green .latest-news__child h3 a:hover,
.color-green html .color-orange .job-description h3 a:hover,
html .color-orange .job-description h3 .color-green a:hover,
.color-green html .color-orange .latest-news__child h3 a:hover,
html .color-orange .latest-news__child h3 .color-green a:hover {
  color: #5da55b !important;
}
.color-green .change-color-hover-dark:hover, .color-green html .color-red .basic-holder__grand-children li a:hover, html .color-red .basic-holder__grand-children li .color-green a:hover,
.color-green html .color-blue .basic-holder__grand-children li a:hover,
html .color-blue .basic-holder__grand-children li .color-green a:hover,
html .color-green .basic-holder__grand-children li a:hover,
.color-green html .color-orange .basic-holder__grand-children li a:hover,
html .color-orange .basic-holder__grand-children li .color-green a:hover, .color-green html .color-red .module--downloads span a:hover, html .color-red .module--downloads span .color-green a:hover,
.color-green html .color-blue .module--downloads span a:hover,
html .color-blue .module--downloads span .color-green a:hover,
html .color-green .module--downloads span a:hover,
.color-green html .color-orange .module--downloads span a:hover,
html .color-orange .module--downloads span .color-green a:hover, .color-green html .color-red .accordion__title i:hover, html .color-red .accordion__title .color-green i:hover,
.color-green html .color-blue .accordion__title i:hover,
html .color-blue .accordion__title .color-green i:hover,
html .color-green .accordion__title i:hover,
.color-green html .color-orange .accordion__title i:hover,
html .color-orange .accordion__title .color-green i:hover, .color-green html .color-red .main__text a:hover:not(.button), html .color-red .main__text .color-green a:hover:not(.button),
.color-green html .color-red .formatted-text a:hover:not(.button),
html .color-red .formatted-text .color-green a:hover:not(.button),
.color-green html .color-blue .main__text a:hover:not(.button),
html .color-blue .main__text .color-green a:hover:not(.button),
.color-green html .color-blue .formatted-text a:hover:not(.button),
html .color-blue .formatted-text .color-green a:hover:not(.button),
html .color-green .main__text a:hover:not(.button),
html .color-green .formatted-text a:hover:not(.button),
.color-green html .color-orange .main__text a:hover:not(.button),
html .color-orange .main__text .color-green a:hover:not(.button),
.color-green html .color-orange .formatted-text a:hover:not(.button),
html .color-orange .formatted-text .color-green a:hover:not(.button) {
  color: #417440 !important;
}
.color-green .change-bg-dark, .color-green html .color-red .grid__link:after,
.color-green html .color-red .news-preview__text > a:after,
.color-green html .color-blue .grid__link:after,
.color-green html .color-blue .news-preview__text > a:after,
html .color-green .grid__link:after,
html .color-green .news-preview__text > a:after,
.color-green html .color-orange .grid__link:after,
.color-green html .color-orange .news-preview__text > a:after, .color-green html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-green html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-green html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after {
  background: #417440 !important;
}
.color-green .change-bg, .color-green html .color-red .textimage__image a:before, html .color-red .textimage__image .color-green a:before,
.color-green html .color-red .module--gallery ul li a:before,
html .color-red .module--gallery ul li .color-green a:before,
.color-green html .color-red .grid__header--image a:before,
html .color-red .grid__header--image .color-green a:before,
.color-green html .color-red .page-media__images a:before,
html .color-red .page-media__images .color-green a:before,
.color-green html .color-red .news-preview__image a:before,
html .color-red .news-preview__image .color-green a:before,
.color-green html .color-red .news-page__image:before,
html .color-red .color-green .news-page__image:before,
.color-green html .color-red .department__image a:before,
html .color-red .department__image .color-green a:before,
.color-green html .color-blue .textimage__image a:before,
html .color-blue .textimage__image .color-green a:before,
.color-green html .color-blue .module--gallery ul li a:before,
html .color-blue .module--gallery ul li .color-green a:before,
.color-green html .color-blue .grid__header--image a:before,
html .color-blue .grid__header--image .color-green a:before,
.color-green html .color-blue .page-media__images a:before,
html .color-blue .page-media__images .color-green a:before,
.color-green html .color-blue .news-preview__image a:before,
html .color-blue .news-preview__image .color-green a:before,
.color-green html .color-blue .news-page__image:before,
html .color-blue .color-green .news-page__image:before,
.color-green html .color-blue .department__image a:before,
html .color-blue .department__image .color-green a:before,
html .color-green .textimage__image a:before,
html .color-green .module--gallery ul li a:before,
html .color-green .grid__header--image a:before,
html .color-green .page-media__images a:before,
html .color-green .news-preview__image a:before,
html .color-green .news-page__image:before,
html .color-green .department__image a:before,
.color-green html .color-orange .textimage__image a:before,
html .color-orange .textimage__image .color-green a:before,
.color-green html .color-orange .module--gallery ul li a:before,
html .color-orange .module--gallery ul li .color-green a:before,
.color-green html .color-orange .grid__header--image a:before,
html .color-orange .grid__header--image .color-green a:before,
.color-green html .color-orange .page-media__images a:before,
html .color-orange .page-media__images .color-green a:before,
.color-green html .color-orange .news-preview__image a:before,
html .color-orange .news-preview__image .color-green a:before,
.color-green html .color-orange .news-page__image:before,
html .color-orange .color-green .news-page__image:before,
.color-green html .color-orange .department__image a:before,
html .color-orange .department__image .color-green a:before, .color-green html .color-red .grid__link,
.color-green html .color-red .news-preview__text > a,
.color-green html .color-blue .grid__link,
.color-green html .color-blue .news-preview__text > a,
html .color-green .grid__link,
html .color-green .news-preview__text > a,
.color-green html .color-orange .grid__link,
.color-green html .color-orange .news-preview__text > a, .color-green html .color-red form input[type=submit], html .color-red form .color-green input[type=submit],
.color-green html .color-blue form input[type=submit],
html .color-blue form .color-green input[type=submit],
html .color-green form input[type=submit],
.color-green html .color-orange form input[type=submit],
html .color-orange form .color-green input[type=submit], .color-green html .color-red .pagination__current,
.color-green html .color-blue .pagination__current,
html .color-green .pagination__current,
.color-green html .color-orange .pagination__current, .color-green html .color-red .textimage__image:after,
.color-green html .color-blue .textimage__image:after,
html .color-green .textimage__image:after,
.color-green html .color-orange .textimage__image:after, .color-green html .color-red #Form_SearchForm_action_results,
.color-green html .color-blue #Form_SearchForm_action_results,
html .color-green #Form_SearchForm_action_results,
.color-green html .color-orange #Form_SearchForm_action_results, .color-green html .color-red .job-description > a:first-of-type:before,
.color-green html .color-red .latest-news__child > a:first-of-type:before,
.color-green html .color-blue .job-description > a:first-of-type:before,
.color-green html .color-blue .latest-news__child > a:first-of-type:before,
html .color-green .job-description > a:first-of-type:before,
html .color-green .latest-news__child > a:first-of-type:before,
.color-green html .color-orange .job-description > a:first-of-type:before,
.color-green html .color-orange .latest-news__child > a:first-of-type:before, .color-green html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-green html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-green html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), .color-green html .color-red .main__text .text--background, html .color-red .main__text .color-green .text--background,
.color-green html .color-red .formatted-text .text--background,
html .color-red .formatted-text .color-green .text--background,
.color-green html .color-blue .main__text .text--background,
html .color-blue .main__text .color-green .text--background,
.color-green html .color-blue .formatted-text .text--background,
html .color-blue .formatted-text .color-green .text--background,
html .color-green .main__text .text--background,
html .color-green .formatted-text .text--background,
.color-green html .color-orange .main__text .text--background,
html .color-orange .main__text .color-green .text--background,
.color-green html .color-orange .formatted-text .text--background,
html .color-orange .formatted-text .color-green .text--background, .color-green html .color-red .contact-person__toggle,
.color-green html .color-red .page-slider .bx-controls .bx-controls-direction a,
html .color-red .page-slider .bx-controls .bx-controls-direction .color-green a,
.color-green html .color-blue .contact-person__toggle,
.color-green html .color-blue .page-slider .bx-controls .bx-controls-direction a,
html .color-blue .page-slider .bx-controls .bx-controls-direction .color-green a,
html .color-green .contact-person__toggle,
html .color-green .page-slider .bx-controls .bx-controls-direction a,
.color-green html .color-orange .contact-person__toggle,
.color-green html .color-orange .page-slider .bx-controls .bx-controls-direction a,
html .color-orange .page-slider .bx-controls .bx-controls-direction .color-green a {
  background: #5da55b !important;
}
.color-green .change-bg-hover:hover, .color-green html .color-red .grid__link:hover,
.color-green html .color-red .news-preview__text > a:hover,
.color-green html .color-blue .grid__link:hover,
.color-green html .color-blue .news-preview__text > a:hover,
html .color-green .grid__link:hover,
html .color-green .news-preview__text > a:hover,
.color-green html .color-orange .grid__link:hover,
.color-green html .color-orange .news-preview__text > a:hover, .color-green html .color-red form input[type=submit]:hover, html .color-red form .color-green input[type=submit]:hover,
.color-green html .color-blue form input[type=submit]:hover,
html .color-blue form .color-green input[type=submit]:hover,
html .color-green form input[type=submit]:hover,
.color-green html .color-orange form input[type=submit]:hover,
html .color-orange form .color-green input[type=submit]:hover, .color-green html .color-red .pagination__link:hover,
.color-green html .color-red .pagination__next:hover,
.color-green html .color-red .pagination__prev:hover,
.color-green html .color-blue .pagination__link:hover,
.color-green html .color-blue .pagination__next:hover,
.color-green html .color-blue .pagination__prev:hover,
html .color-green .pagination__link:hover,
html .color-green .pagination__next:hover,
html .color-green .pagination__prev:hover,
.color-green html .color-orange .pagination__link:hover,
.color-green html .color-orange .pagination__next:hover,
.color-green html .color-orange .pagination__prev:hover, .color-green html .color-red #Form_SearchForm_action_results:hover,
.color-green html .color-blue #Form_SearchForm_action_results:hover,
html .color-green #Form_SearchForm_action_results:hover,
.color-green html .color-orange #Form_SearchForm_action_results:hover, .color-green html .color-red .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-green html .color-blue .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
html .color-green .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-green html .color-orange .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), .color-green html .color-red .contact-person__toggle:hover,
.color-green html .color-red .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-red .page-slider .bx-controls .bx-controls-direction .color-green a:hover,
.color-green html .color-blue .contact-person__toggle:hover,
.color-green html .color-blue .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-blue .page-slider .bx-controls .bx-controls-direction .color-green a:hover,
html .color-green .contact-person__toggle:hover,
html .color-green .page-slider .bx-controls .bx-controls-direction a:hover,
.color-green html .color-orange .contact-person__toggle:hover,
.color-green html .color-orange .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-orange .page-slider .bx-controls .bx-controls-direction .color-green a:hover {
  background: #417440 !important;
}
.color-green .change-border, .color-green html .color-red form input:not([type=submit]):not([type=reset]):focus, html .color-red form .color-green input:not([type=submit]):not([type=reset]):focus,
.color-green html .color-blue form input:not([type=submit]):not([type=reset]):focus,
html .color-blue form .color-green input:not([type=submit]):not([type=reset]):focus,
html .color-green form input:not([type=submit]):not([type=reset]):focus,
.color-green html .color-orange form input:not([type=submit]):not([type=reset]):focus,
html .color-orange form .color-green input:not([type=submit]):not([type=reset]):focus, .color-green html .color-red .page-slider,
.color-green html .color-blue .page-slider,
html .color-green .page-slider,
.color-green html .color-orange .page-slider {
  border-color: #5da55b !important;
}
.color-green .change-border-top, .color-green html .color-red .navigation--main .navigation__sub, html .color-red .navigation--main .color-green .navigation__sub,
.color-green html .color-blue .navigation--main .navigation__sub,
html .color-blue .navigation--main .color-green .navigation__sub,
html .color-green .navigation--main .navigation__sub,
.color-green html .color-orange .navigation--main .navigation__sub,
html .color-orange .navigation--main .color-green .navigation__sub {
  border-top-color: #5da55b !important;
}

.color-orange ::selection {
  background: #de8530;
}
.color-orange ::-moz-selection {
  background: #de8530;
}
.color-orange .change-color, .color-orange html .color-red .textimage__image a:after, html .color-red .textimage__image .color-orange a:after,
.color-orange html .color-red .module--gallery ul li a:after,
html .color-red .module--gallery ul li .color-orange a:after,
.color-orange html .color-red .grid__header--image a:after,
html .color-red .grid__header--image .color-orange a:after,
.color-orange html .color-red .page-media__images a:after,
html .color-red .page-media__images .color-orange a:after,
.color-orange html .color-red .news-preview__image a:after,
html .color-red .news-preview__image .color-orange a:after,
.color-orange html .color-red .news-page__image:after,
html .color-red .color-orange .news-page__image:after,
.color-orange html .color-red .department__image a:after,
html .color-red .department__image .color-orange a:after,
.color-orange html .color-blue .textimage__image a:after,
html .color-blue .textimage__image .color-orange a:after,
.color-orange html .color-blue .module--gallery ul li a:after,
html .color-blue .module--gallery ul li .color-orange a:after,
.color-orange html .color-blue .grid__header--image a:after,
html .color-blue .grid__header--image .color-orange a:after,
.color-orange html .color-blue .page-media__images a:after,
html .color-blue .page-media__images .color-orange a:after,
.color-orange html .color-blue .news-preview__image a:after,
html .color-blue .news-preview__image .color-orange a:after,
.color-orange html .color-blue .news-page__image:after,
html .color-blue .color-orange .news-page__image:after,
.color-orange html .color-blue .department__image a:after,
html .color-blue .department__image .color-orange a:after,
.color-orange html .color-green .textimage__image a:after,
html .color-green .textimage__image .color-orange a:after,
.color-orange html .color-green .module--gallery ul li a:after,
html .color-green .module--gallery ul li .color-orange a:after,
.color-orange html .color-green .grid__header--image a:after,
html .color-green .grid__header--image .color-orange a:after,
.color-orange html .color-green .page-media__images a:after,
html .color-green .page-media__images .color-orange a:after,
.color-orange html .color-green .news-preview__image a:after,
html .color-green .news-preview__image .color-orange a:after,
.color-orange html .color-green .news-page__image:after,
html .color-green .color-orange .news-page__image:after,
.color-orange html .color-green .department__image a:after,
html .color-green .department__image .color-orange a:after,
html .color-orange .textimage__image a:after,
html .color-orange .module--gallery ul li a:after,
html .color-orange .grid__header--image a:after,
html .color-orange .page-media__images a:after,
html .color-orange .news-preview__image a:after,
html .color-orange .news-page__image:after,
html .color-orange .department__image a:after, .color-orange html .color-red .basic-holder__grand-children li a, html .color-red .basic-holder__grand-children li .color-orange a,
.color-orange html .color-blue .basic-holder__grand-children li a,
html .color-blue .basic-holder__grand-children li .color-orange a,
.color-orange html .color-green .basic-holder__grand-children li a,
html .color-green .basic-holder__grand-children li .color-orange a,
html .color-orange .basic-holder__grand-children li a, .color-orange html .color-red .module--downloads span a, html .color-red .module--downloads span .color-orange a,
.color-orange html .color-blue .module--downloads span a,
html .color-blue .module--downloads span .color-orange a,
.color-orange html .color-green .module--downloads span a,
html .color-green .module--downloads span .color-orange a,
html .color-orange .module--downloads span a, .color-orange html .color-red .team-member i, html .color-red .team-member .color-orange i,
.color-orange html .color-blue .team-member i,
html .color-blue .team-member .color-orange i,
.color-orange html .color-green .team-member i,
html .color-green .team-member .color-orange i,
html .color-orange .team-member i, .color-orange html .color-red .job-description > a:first-of-type:after, html .color-red .color-orange .job-description > a:first-of-type:after,
.color-orange html .color-red .latest-news__child > a:first-of-type:after,
html .color-red .color-orange .latest-news__child > a:first-of-type:after,
.color-orange html .color-blue .job-description > a:first-of-type:after,
html .color-blue .color-orange .job-description > a:first-of-type:after,
.color-orange html .color-blue .latest-news__child > a:first-of-type:after,
html .color-blue .color-orange .latest-news__child > a:first-of-type:after,
.color-orange html .color-green .job-description > a:first-of-type:after,
html .color-green .color-orange .job-description > a:first-of-type:after,
.color-orange html .color-green .latest-news__child > a:first-of-type:after,
html .color-green .color-orange .latest-news__child > a:first-of-type:after,
html .color-orange .job-description > a:first-of-type:after,
html .color-orange .latest-news__child > a:first-of-type:after, .color-orange html .color-red .accordion__title i, html .color-red .accordion__title .color-orange i,
.color-orange html .color-blue .accordion__title i,
html .color-blue .accordion__title .color-orange i,
.color-orange html .color-green .accordion__title i,
html .color-green .accordion__title .color-orange i,
html .color-orange .accordion__title i, .color-orange html .color-red .main__text .text--highlight, html .color-red .main__text .color-orange .text--highlight,
.color-orange html .color-red .formatted-text .text--highlight,
html .color-red .formatted-text .color-orange .text--highlight,
.color-orange html .color-blue .main__text .text--highlight,
html .color-blue .main__text .color-orange .text--highlight,
.color-orange html .color-blue .formatted-text .text--highlight,
html .color-blue .formatted-text .color-orange .text--highlight,
.color-orange html .color-green .main__text .text--highlight,
html .color-green .main__text .color-orange .text--highlight,
.color-orange html .color-green .formatted-text .text--highlight,
html .color-green .formatted-text .color-orange .text--highlight,
html .color-orange .main__text .text--highlight,
html .color-orange .formatted-text .text--highlight, .color-orange html .color-red .main__text a:not(.button), html .color-red .main__text .color-orange a:not(.button),
.color-orange html .color-red .formatted-text a:not(.button),
html .color-red .formatted-text .color-orange a:not(.button),
.color-orange html .color-blue .main__text a:not(.button),
html .color-blue .main__text .color-orange a:not(.button),
.color-orange html .color-blue .formatted-text a:not(.button),
html .color-blue .formatted-text .color-orange a:not(.button),
.color-orange html .color-green .main__text a:not(.button),
html .color-green .main__text .color-orange a:not(.button),
.color-orange html .color-green .formatted-text a:not(.button),
html .color-green .formatted-text .color-orange a:not(.button),
html .color-orange .main__text a:not(.button),
html .color-orange .formatted-text a:not(.button), .color-orange html .color-red .navigation--mobile .navigation__top-link:hover > a, html .color-red .navigation--mobile .color-orange .navigation__top-link:hover > a,
.color-orange html .color-red .navigation--mobile .navigation__sub-link:hover > a,
html .color-red .navigation--mobile .color-orange .navigation__sub-link:hover > a,
.color-orange html .color-red .navigation--mobile .navigation__top-link--section > a,
html .color-red .navigation--mobile .color-orange .navigation__top-link--section > a,
.color-orange html .color-red .navigation--mobile .navigation__top-link--current > a,
html .color-red .navigation--mobile .color-orange .navigation__top-link--current > a,
.color-orange html .color-red .navigation--mobile .mobile-navigation-button:hover,
html .color-red .navigation--mobile .color-orange .mobile-navigation-button:hover,
.color-orange html .color-red .navigation--mobile .navigation__sub-link--section > a,
html .color-red .navigation--mobile .color-orange .navigation__sub-link--section > a,
.color-orange html .color-red .navigation--mobile .navigation__sub-link--current > a,
html .color-red .navigation--mobile .color-orange .navigation__sub-link--current > a,
.color-orange html .color-blue .navigation--mobile .navigation__top-link:hover > a,
html .color-blue .navigation--mobile .color-orange .navigation__top-link:hover > a,
.color-orange html .color-blue .navigation--mobile .navigation__sub-link:hover > a,
html .color-blue .navigation--mobile .color-orange .navigation__sub-link:hover > a,
.color-orange html .color-blue .navigation--mobile .navigation__top-link--section > a,
html .color-blue .navigation--mobile .color-orange .navigation__top-link--section > a,
.color-orange html .color-blue .navigation--mobile .navigation__top-link--current > a,
html .color-blue .navigation--mobile .color-orange .navigation__top-link--current > a,
.color-orange html .color-blue .navigation--mobile .mobile-navigation-button:hover,
html .color-blue .navigation--mobile .color-orange .mobile-navigation-button:hover,
.color-orange html .color-blue .navigation--mobile .navigation__sub-link--section > a,
html .color-blue .navigation--mobile .color-orange .navigation__sub-link--section > a,
.color-orange html .color-blue .navigation--mobile .navigation__sub-link--current > a,
html .color-blue .navigation--mobile .color-orange .navigation__sub-link--current > a,
.color-orange html .color-green .navigation--mobile .navigation__top-link:hover > a,
html .color-green .navigation--mobile .color-orange .navigation__top-link:hover > a,
.color-orange html .color-green .navigation--mobile .navigation__sub-link:hover > a,
html .color-green .navigation--mobile .color-orange .navigation__sub-link:hover > a,
.color-orange html .color-green .navigation--mobile .navigation__top-link--section > a,
html .color-green .navigation--mobile .color-orange .navigation__top-link--section > a,
.color-orange html .color-green .navigation--mobile .navigation__top-link--current > a,
html .color-green .navigation--mobile .color-orange .navigation__top-link--current > a,
.color-orange html .color-green .navigation--mobile .mobile-navigation-button:hover,
html .color-green .navigation--mobile .color-orange .mobile-navigation-button:hover,
.color-orange html .color-green .navigation--mobile .navigation__sub-link--section > a,
html .color-green .navigation--mobile .color-orange .navigation__sub-link--section > a,
.color-orange html .color-green .navigation--mobile .navigation__sub-link--current > a,
html .color-green .navigation--mobile .color-orange .navigation__sub-link--current > a,
html .color-orange .navigation--mobile .navigation__top-link:hover > a,
html .color-orange .navigation--mobile .navigation__sub-link:hover > a,
html .color-orange .navigation--mobile .navigation__top-link--section > a,
html .color-orange .navigation--mobile .navigation__top-link--current > a,
html .color-orange .navigation--mobile .mobile-navigation-button:hover,
html .color-orange .navigation--mobile .navigation__sub-link--section > a,
html .color-orange .navigation--mobile .navigation__sub-link--current > a, .color-orange html .color-red .navigation--main .navigation__top-link:hover > a, html .color-red .navigation--main .color-orange .navigation__top-link:hover > a,
.color-orange html .color-red .navigation--main .navigation__sub-link:hover > a,
html .color-red .navigation--main .color-orange .navigation__sub-link:hover > a,
.color-orange html .color-red .navigation--main .navigation__top-link--section > a,
html .color-red .navigation--main .color-orange .navigation__top-link--section > a,
.color-orange html .color-red .navigation--main .navigation__top-link--current > a,
html .color-red .navigation--main .color-orange .navigation__top-link--current > a,
.color-orange html .color-red .navigation--main .mobile-navigation-button:hover,
html .color-red .navigation--main .color-orange .mobile-navigation-button:hover,
.color-orange html .color-red .navigation--main .navigation__home-link:hover a,
html .color-red .navigation--main .navigation__home-link:hover .color-orange a,
.color-orange html .color-blue .navigation--main .navigation__top-link:hover > a,
html .color-blue .navigation--main .color-orange .navigation__top-link:hover > a,
.color-orange html .color-blue .navigation--main .navigation__sub-link:hover > a,
html .color-blue .navigation--main .color-orange .navigation__sub-link:hover > a,
.color-orange html .color-blue .navigation--main .navigation__top-link--section > a,
html .color-blue .navigation--main .color-orange .navigation__top-link--section > a,
.color-orange html .color-blue .navigation--main .navigation__top-link--current > a,
html .color-blue .navigation--main .color-orange .navigation__top-link--current > a,
.color-orange html .color-blue .navigation--main .mobile-navigation-button:hover,
html .color-blue .navigation--main .color-orange .mobile-navigation-button:hover,
.color-orange html .color-blue .navigation--main .navigation__home-link:hover a,
html .color-blue .navigation--main .navigation__home-link:hover .color-orange a,
.color-orange html .color-green .navigation--main .navigation__top-link:hover > a,
html .color-green .navigation--main .color-orange .navigation__top-link:hover > a,
.color-orange html .color-green .navigation--main .navigation__sub-link:hover > a,
html .color-green .navigation--main .color-orange .navigation__sub-link:hover > a,
.color-orange html .color-green .navigation--main .navigation__top-link--section > a,
html .color-green .navigation--main .color-orange .navigation__top-link--section > a,
.color-orange html .color-green .navigation--main .navigation__top-link--current > a,
html .color-green .navigation--main .color-orange .navigation__top-link--current > a,
.color-orange html .color-green .navigation--main .mobile-navigation-button:hover,
html .color-green .navigation--main .color-orange .mobile-navigation-button:hover,
.color-orange html .color-green .navigation--main .navigation__home-link:hover a,
html .color-green .navigation--main .navigation__home-link:hover .color-orange a,
html .color-orange .navigation--main .navigation__top-link:hover > a,
html .color-orange .navigation--main .navigation__sub-link:hover > a,
html .color-orange .navigation--main .navigation__top-link--section > a,
html .color-orange .navigation--main .navigation__top-link--current > a,
html .color-orange .navigation--main .mobile-navigation-button:hover,
html .color-orange .navigation--main .navigation__home-link:hover a, .color-orange html .color-red .navigation--main .navigation__sub:before, html .color-red .navigation--main .color-orange .navigation__sub:before,
.color-orange html .color-red .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-red .navigation--main .navigation__sub .color-orange .navigation__sub-link--section > a,
.color-orange html .color-red .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-red .navigation--main .navigation__sub .color-orange .navigation__sub-link--current > a,
.color-orange html .color-blue .navigation--main .navigation__sub:before,
html .color-blue .navigation--main .color-orange .navigation__sub:before,
.color-orange html .color-blue .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-blue .navigation--main .navigation__sub .color-orange .navigation__sub-link--section > a,
.color-orange html .color-blue .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-blue .navigation--main .navigation__sub .color-orange .navigation__sub-link--current > a,
.color-orange html .color-green .navigation--main .navigation__sub:before,
html .color-green .navigation--main .color-orange .navigation__sub:before,
.color-orange html .color-green .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-green .navigation--main .navigation__sub .color-orange .navigation__sub-link--section > a,
.color-orange html .color-green .navigation--main .navigation__sub .navigation__sub-link--current > a,
html .color-green .navigation--main .navigation__sub .color-orange .navigation__sub-link--current > a,
html .color-orange .navigation--main .navigation__sub:before,
html .color-orange .navigation--main .navigation__sub .navigation__sub-link--section > a,
html .color-orange .navigation--main .navigation__sub .navigation__sub-link--current > a, .color-orange html .color-red .page-slider__caption-content > h3 > span span, html .color-red .page-slider__caption-content > h3 > span .color-orange span,
.color-orange html .color-blue .page-slider__caption-content > h3 > span span,
html .color-blue .page-slider__caption-content > h3 > span .color-orange span,
.color-orange html .color-green .page-slider__caption-content > h3 > span span,
html .color-green .page-slider__caption-content > h3 > span .color-orange span,
html .color-orange .page-slider__caption-content > h3 > span span {
  color: #de8530 !important;
}
.color-orange .change-color-dark, .color-orange html .color-red .accordion__title:hover i, html .color-red .accordion__title:hover .color-orange i,
.color-orange html .color-blue .accordion__title:hover i,
html .color-blue .accordion__title:hover .color-orange i,
.color-orange html .color-green .accordion__title:hover i,
html .color-green .accordion__title:hover .color-orange i,
html .color-orange .accordion__title:hover i {
  color: #a75f1b !important;
}
.color-orange .change-color-hover:hover, .color-orange html .color-red .grid__header--icon a:hover, html .color-red .grid__header--icon .color-orange a:hover,
.color-orange html .color-red .news-preview__text > h2 a:hover,
html .color-red .news-preview__text > h2 .color-orange a:hover,
.color-orange html .color-red .basic-holder__child > h3 a:hover,
html .color-red .basic-holder__child > h3 .color-orange a:hover,
.color-orange html .color-red .department__info > h2 a:hover,
html .color-red .department__info > h2 .color-orange a:hover,
.color-orange html .color-blue .grid__header--icon a:hover,
html .color-blue .grid__header--icon .color-orange a:hover,
.color-orange html .color-blue .news-preview__text > h2 a:hover,
html .color-blue .news-preview__text > h2 .color-orange a:hover,
.color-orange html .color-blue .basic-holder__child > h3 a:hover,
html .color-blue .basic-holder__child > h3 .color-orange a:hover,
.color-orange html .color-blue .department__info > h2 a:hover,
html .color-blue .department__info > h2 .color-orange a:hover,
.color-orange html .color-green .grid__header--icon a:hover,
html .color-green .grid__header--icon .color-orange a:hover,
.color-orange html .color-green .news-preview__text > h2 a:hover,
html .color-green .news-preview__text > h2 .color-orange a:hover,
.color-orange html .color-green .basic-holder__child > h3 a:hover,
html .color-green .basic-holder__child > h3 .color-orange a:hover,
.color-orange html .color-green .department__info > h2 a:hover,
html .color-green .department__info > h2 .color-orange a:hover,
html .color-orange .grid__header--icon a:hover,
html .color-orange .news-preview__text > h2 a:hover,
html .color-orange .basic-holder__child > h3 a:hover,
html .color-orange .department__info > h2 a:hover, .color-orange html .color-red .module--downloads li > a:hover, html .color-red .module--downloads .color-orange li > a:hover,
.color-orange html .color-blue .module--downloads li > a:hover,
html .color-blue .module--downloads .color-orange li > a:hover,
.color-orange html .color-green .module--downloads li > a:hover,
html .color-green .module--downloads .color-orange li > a:hover,
html .color-orange .module--downloads li > a:hover, .color-orange html .color-red .breadcrumbs a:hover, html .color-red .breadcrumbs .color-orange a:hover,
.color-orange html .color-blue .breadcrumbs a:hover,
html .color-blue .breadcrumbs .color-orange a:hover,
.color-orange html .color-green .breadcrumbs a:hover,
html .color-green .breadcrumbs .color-orange a:hover,
html .color-orange .breadcrumbs a:hover, .color-orange html .color-red .team-member a:hover, html .color-red .team-member .color-orange a:hover,
.color-orange html .color-blue .team-member a:hover,
html .color-blue .team-member .color-orange a:hover,
.color-orange html .color-green .team-member a:hover,
html .color-green .team-member .color-orange a:hover,
html .color-orange .team-member a:hover, .color-orange html .color-red .footer a:hover, html .color-red .footer .color-orange a:hover,
.color-orange html .color-blue .footer a:hover,
html .color-blue .footer .color-orange a:hover,
.color-orange html .color-green .footer a:hover,
html .color-green .footer .color-orange a:hover,
html .color-orange .footer a:hover, .color-orange html .color-red .job-description h3 a:hover, html .color-red .job-description h3 .color-orange a:hover,
.color-orange html .color-red .latest-news__child h3 a:hover,
html .color-red .latest-news__child h3 .color-orange a:hover,
.color-orange html .color-blue .job-description h3 a:hover,
html .color-blue .job-description h3 .color-orange a:hover,
.color-orange html .color-blue .latest-news__child h3 a:hover,
html .color-blue .latest-news__child h3 .color-orange a:hover,
.color-orange html .color-green .job-description h3 a:hover,
html .color-green .job-description h3 .color-orange a:hover,
.color-orange html .color-green .latest-news__child h3 a:hover,
html .color-green .latest-news__child h3 .color-orange a:hover,
html .color-orange .job-description h3 a:hover,
html .color-orange .latest-news__child h3 a:hover {
  color: #de8530 !important;
}
.color-orange .change-bg, .color-orange html .color-red .textimage__image a:before, html .color-red .textimage__image .color-orange a:before,
.color-orange html .color-red .module--gallery ul li a:before,
html .color-red .module--gallery ul li .color-orange a:before,
.color-orange html .color-red .grid__header--image a:before,
html .color-red .grid__header--image .color-orange a:before,
.color-orange html .color-red .page-media__images a:before,
html .color-red .page-media__images .color-orange a:before,
.color-orange html .color-red .news-preview__image a:before,
html .color-red .news-preview__image .color-orange a:before,
.color-orange html .color-red .news-page__image:before,
html .color-red .color-orange .news-page__image:before,
.color-orange html .color-red .department__image a:before,
html .color-red .department__image .color-orange a:before,
.color-orange html .color-blue .textimage__image a:before,
html .color-blue .textimage__image .color-orange a:before,
.color-orange html .color-blue .module--gallery ul li a:before,
html .color-blue .module--gallery ul li .color-orange a:before,
.color-orange html .color-blue .grid__header--image a:before,
html .color-blue .grid__header--image .color-orange a:before,
.color-orange html .color-blue .page-media__images a:before,
html .color-blue .page-media__images .color-orange a:before,
.color-orange html .color-blue .news-preview__image a:before,
html .color-blue .news-preview__image .color-orange a:before,
.color-orange html .color-blue .news-page__image:before,
html .color-blue .color-orange .news-page__image:before,
.color-orange html .color-blue .department__image a:before,
html .color-blue .department__image .color-orange a:before,
.color-orange html .color-green .textimage__image a:before,
html .color-green .textimage__image .color-orange a:before,
.color-orange html .color-green .module--gallery ul li a:before,
html .color-green .module--gallery ul li .color-orange a:before,
.color-orange html .color-green .grid__header--image a:before,
html .color-green .grid__header--image .color-orange a:before,
.color-orange html .color-green .page-media__images a:before,
html .color-green .page-media__images .color-orange a:before,
.color-orange html .color-green .news-preview__image a:before,
html .color-green .news-preview__image .color-orange a:before,
.color-orange html .color-green .news-page__image:before,
html .color-green .color-orange .news-page__image:before,
.color-orange html .color-green .department__image a:before,
html .color-green .department__image .color-orange a:before,
html .color-orange .textimage__image a:before,
html .color-orange .module--gallery ul li a:before,
html .color-orange .grid__header--image a:before,
html .color-orange .page-media__images a:before,
html .color-orange .news-preview__image a:before,
html .color-orange .news-page__image:before,
html .color-orange .department__image a:before, .color-orange html .color-red .grid__link,
.color-orange html .color-red .news-preview__text > a,
.color-orange html .color-blue .grid__link,
.color-orange html .color-blue .news-preview__text > a,
.color-orange html .color-green .grid__link,
.color-orange html .color-green .news-preview__text > a,
html .color-orange .grid__link,
html .color-orange .news-preview__text > a, .color-orange html .color-red form input[type=submit], html .color-red form .color-orange input[type=submit],
.color-orange html .color-blue form input[type=submit],
html .color-blue form .color-orange input[type=submit],
.color-orange html .color-green form input[type=submit],
html .color-green form .color-orange input[type=submit],
html .color-orange form input[type=submit], .color-orange html .color-red .pagination__current,
.color-orange html .color-blue .pagination__current,
.color-orange html .color-green .pagination__current,
html .color-orange .pagination__current, .color-orange html .color-red .textimage__image:after,
.color-orange html .color-blue .textimage__image:after,
.color-orange html .color-green .textimage__image:after,
html .color-orange .textimage__image:after, .color-orange html .color-red #Form_SearchForm_action_results,
.color-orange html .color-blue #Form_SearchForm_action_results,
.color-orange html .color-green #Form_SearchForm_action_results,
html .color-orange #Form_SearchForm_action_results, .color-orange html .color-red .job-description > a:first-of-type:before,
.color-orange html .color-red .latest-news__child > a:first-of-type:before,
.color-orange html .color-blue .job-description > a:first-of-type:before,
.color-orange html .color-blue .latest-news__child > a:first-of-type:before,
.color-orange html .color-green .job-description > a:first-of-type:before,
.color-orange html .color-green .latest-news__child > a:first-of-type:before,
html .color-orange .job-description > a:first-of-type:before,
html .color-orange .latest-news__child > a:first-of-type:before, .color-orange html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-orange html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-orange html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), .color-orange html .color-red .main__text .text--background, html .color-red .main__text .color-orange .text--background,
.color-orange html .color-red .formatted-text .text--background,
html .color-red .formatted-text .color-orange .text--background,
.color-orange html .color-blue .main__text .text--background,
html .color-blue .main__text .color-orange .text--background,
.color-orange html .color-blue .formatted-text .text--background,
html .color-blue .formatted-text .color-orange .text--background,
.color-orange html .color-green .main__text .text--background,
html .color-green .main__text .color-orange .text--background,
.color-orange html .color-green .formatted-text .text--background,
html .color-green .formatted-text .color-orange .text--background,
html .color-orange .main__text .text--background,
html .color-orange .formatted-text .text--background, .color-orange html .color-red .contact-person__toggle,
.color-orange html .color-red .page-slider .bx-controls .bx-controls-direction a,
html .color-red .page-slider .bx-controls .bx-controls-direction .color-orange a,
.color-orange html .color-blue .contact-person__toggle,
.color-orange html .color-blue .page-slider .bx-controls .bx-controls-direction a,
html .color-blue .page-slider .bx-controls .bx-controls-direction .color-orange a,
.color-orange html .color-green .contact-person__toggle,
.color-orange html .color-green .page-slider .bx-controls .bx-controls-direction a,
html .color-green .page-slider .bx-controls .bx-controls-direction .color-orange a,
html .color-orange .contact-person__toggle,
html .color-orange .page-slider .bx-controls .bx-controls-direction a {
  background: #de8530 !important;
}
.color-orange .change-bg-hover:hover, .color-orange html .color-red .grid__link:hover,
.color-orange html .color-red .news-preview__text > a:hover,
.color-orange html .color-blue .grid__link:hover,
.color-orange html .color-blue .news-preview__text > a:hover,
.color-orange html .color-green .grid__link:hover,
.color-orange html .color-green .news-preview__text > a:hover,
html .color-orange .grid__link:hover,
html .color-orange .news-preview__text > a:hover, .color-orange html .color-red form input[type=submit]:hover, html .color-red form .color-orange input[type=submit]:hover,
.color-orange html .color-blue form input[type=submit]:hover,
html .color-blue form .color-orange input[type=submit]:hover,
.color-orange html .color-green form input[type=submit]:hover,
html .color-green form .color-orange input[type=submit]:hover,
html .color-orange form input[type=submit]:hover, .color-orange html .color-red .pagination__link:hover,
.color-orange html .color-red .pagination__next:hover,
.color-orange html .color-red .pagination__prev:hover,
.color-orange html .color-blue .pagination__link:hover,
.color-orange html .color-blue .pagination__next:hover,
.color-orange html .color-blue .pagination__prev:hover,
.color-orange html .color-green .pagination__link:hover,
.color-orange html .color-green .pagination__next:hover,
.color-orange html .color-green .pagination__prev:hover,
html .color-orange .pagination__link:hover,
html .color-orange .pagination__next:hover,
html .color-orange .pagination__prev:hover, .color-orange html .color-red #Form_SearchForm_action_results:hover,
.color-orange html .color-blue #Form_SearchForm_action_results:hover,
.color-orange html .color-green #Form_SearchForm_action_results:hover,
html .color-orange #Form_SearchForm_action_results:hover, .color-orange html .color-red .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-orange html .color-blue .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
.color-orange html .color-green .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange),
html .color-orange .button:hover:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange), .color-orange html .color-red .contact-person__toggle:hover,
.color-orange html .color-red .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-red .page-slider .bx-controls .bx-controls-direction .color-orange a:hover,
.color-orange html .color-blue .contact-person__toggle:hover,
.color-orange html .color-blue .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-blue .page-slider .bx-controls .bx-controls-direction .color-orange a:hover,
.color-orange html .color-green .contact-person__toggle:hover,
.color-orange html .color-green .page-slider .bx-controls .bx-controls-direction a:hover,
html .color-green .page-slider .bx-controls .bx-controls-direction .color-orange a:hover,
html .color-orange .contact-person__toggle:hover,
html .color-orange .page-slider .bx-controls .bx-controls-direction a:hover {
  background: #a75f1b !important;
}
.color-orange .change-bg-dark, .color-orange html .color-red .grid__link:after,
.color-orange html .color-red .news-preview__text > a:after,
.color-orange html .color-blue .grid__link:after,
.color-orange html .color-blue .news-preview__text > a:after,
.color-orange html .color-green .grid__link:after,
.color-orange html .color-green .news-preview__text > a:after,
html .color-orange .grid__link:after,
html .color-orange .news-preview__text > a:after, .color-orange html .color-red .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-orange html .color-blue .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
.color-orange html .color-green .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after,
html .color-orange .button:not(.button--neutral):not(.button--light-green):not(.button--green):not(.button--red):not(.button--blue):not(.button--orange):after {
  background: #a75f1b !important;
}
.color-orange .change-color-hover-dark:hover, .color-orange html .color-red .basic-holder__grand-children li a:hover, html .color-red .basic-holder__grand-children li .color-orange a:hover,
.color-orange html .color-blue .basic-holder__grand-children li a:hover,
html .color-blue .basic-holder__grand-children li .color-orange a:hover,
.color-orange html .color-green .basic-holder__grand-children li a:hover,
html .color-green .basic-holder__grand-children li .color-orange a:hover,
html .color-orange .basic-holder__grand-children li a:hover, .color-orange html .color-red .module--downloads span a:hover, html .color-red .module--downloads span .color-orange a:hover,
.color-orange html .color-blue .module--downloads span a:hover,
html .color-blue .module--downloads span .color-orange a:hover,
.color-orange html .color-green .module--downloads span a:hover,
html .color-green .module--downloads span .color-orange a:hover,
html .color-orange .module--downloads span a:hover, .color-orange html .color-red .accordion__title i:hover, html .color-red .accordion__title .color-orange i:hover,
.color-orange html .color-blue .accordion__title i:hover,
html .color-blue .accordion__title .color-orange i:hover,
.color-orange html .color-green .accordion__title i:hover,
html .color-green .accordion__title .color-orange i:hover,
html .color-orange .accordion__title i:hover, .color-orange html .color-red .main__text a:hover:not(.button), html .color-red .main__text .color-orange a:hover:not(.button),
.color-orange html .color-red .formatted-text a:hover:not(.button),
html .color-red .formatted-text .color-orange a:hover:not(.button),
.color-orange html .color-blue .main__text a:hover:not(.button),
html .color-blue .main__text .color-orange a:hover:not(.button),
.color-orange html .color-blue .formatted-text a:hover:not(.button),
html .color-blue .formatted-text .color-orange a:hover:not(.button),
.color-orange html .color-green .main__text a:hover:not(.button),
html .color-green .main__text .color-orange a:hover:not(.button),
.color-orange html .color-green .formatted-text a:hover:not(.button),
html .color-green .formatted-text .color-orange a:hover:not(.button),
html .color-orange .main__text a:hover:not(.button),
html .color-orange .formatted-text a:hover:not(.button) {
  color: #a75f1b !important;
}
.color-orange .change-border, .color-orange html .color-red form input:not([type=submit]):not([type=reset]):focus, html .color-red form .color-orange input:not([type=submit]):not([type=reset]):focus,
.color-orange html .color-blue form input:not([type=submit]):not([type=reset]):focus,
html .color-blue form .color-orange input:not([type=submit]):not([type=reset]):focus,
.color-orange html .color-green form input:not([type=submit]):not([type=reset]):focus,
html .color-green form .color-orange input:not([type=submit]):not([type=reset]):focus,
html .color-orange form input:not([type=submit]):not([type=reset]):focus, .color-orange html .color-red .page-slider,
.color-orange html .color-blue .page-slider,
.color-orange html .color-green .page-slider,
html .color-orange .page-slider {
  border-color: #de8530 !important;
}
.color-orange .change-border-top, .color-orange html .color-red .navigation--main .navigation__sub, html .color-red .navigation--main .color-orange .navigation__sub,
.color-orange html .color-blue .navigation--main .navigation__sub,
html .color-blue .navigation--main .color-orange .navigation__sub,
.color-orange html .color-green .navigation--main .navigation__sub,
html .color-green .navigation--main .color-orange .navigation__sub,
html .color-orange .navigation--main .navigation__sub {
  border-top-color: #de8530 !important;
}

html .navigation--main .navigation__top-link.color-red:hover > a, html .navigation--main .navigation__top-link.color-red > a:hover,
html .navigation--main .navigation__top-link.color-red .navigation__sub-link:hover > a {
  color: #eb6446 !important;
  opacity: 1;
}
html .navigation--main .navigation__top-link.color-red > .navigation__sub {
  border-top-color: #eb6446 !important;
}
html .navigation--main .navigation__top-link.color-red > .navigation__sub:before {
  color: #eb6446 !important;
}
html .navigation--main .navigation__top-link.color-orange:hover > a, html .navigation--main .navigation__top-link.color-orange > a:hover,
html .navigation--main .navigation__top-link.color-orange .navigation__sub-link:hover > a {
  color: #de8530 !important;
  opacity: 1;
}
html .navigation--main .navigation__top-link.color-orange > .navigation__sub {
  border-top-color: #de8530 !important;
}
html .navigation--main .navigation__top-link.color-orange > .navigation__sub:before {
  color: #de8530 !important;
}
html .navigation--main .navigation__top-link.color-blue:hover > a, html .navigation--main .navigation__top-link.color-blue > a:hover,
html .navigation--main .navigation__top-link.color-blue .navigation__sub-link:hover > a {
  color: #488ccb !important;
  opacity: 1;
}
html .navigation--main .navigation__top-link.color-blue > .navigation__sub {
  border-top-color: #488ccb !important;
}
html .navigation--main .navigation__top-link.color-blue > .navigation__sub:before {
  color: #488ccb !important;
}
html .navigation--main .navigation__top-link.color-green:hover > a, html .navigation--main .navigation__top-link.color-green > a:hover,
html .navigation--main .navigation__top-link.color-green .navigation__sub-link:hover > a {
  color: #5da55b !important;
  opacity: 1;
}
html .navigation--main .navigation__top-link.color-green > .navigation__sub {
  border-top-color: #5da55b !important;
}
html .navigation--main .navigation__top-link.color-green > .navigation__sub:before {
  color: #5da55b !important;
}
html .navigation--main .navigation__top-link.color-light-green:hover > a, html .navigation--main .navigation__top-link.color-light-green > a:hover,
html .navigation--main .navigation__top-link.color-light-green .navigation__sub-link:hover > a {
  color: #aac858 !important;
  opacity: 1;
}
html .navigation--main .navigation__top-link.color-light-green > .navigation__sub {
  border-top-color: #aac858 !important;
}
html .navigation--main .navigation__top-link.color-light-green > .navigation__sub:before {
  color: #aac858 !important;
}