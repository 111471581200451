@import "../node_modules/susy/sass/_susy.scss";
@import "globals/_fa-variables";
@import "globals/_variables";
@import "globals/_extends";
@import "globals/_mixins";
@import "globals/_text-styling";
@import "modules/basic-holder/_basic-holder.scss";
@import "modules/modular-content/_accordion.scss";
@import "modules/modular-content/_downloads.scss";
@import "modules/modular-content/_gallery.scss";
@import "modules/modular-content/_grid.scss";
@import "modules/modular-content/_headline.scss";
@import "modules/modular-content/_modular-content.scss";
@import "modules/modular-content/_textimage.scss";
@import "modules/modular-content/_video.scss";
@import "modules/news/_latest-news.scss";
@import "modules/news/_news-holder.scss";
@import "modules/news/_news-page.scss";
@import "modules/page-media/_page-media.scss";
@import "modules/page-slider/_page-slider.scss";
@import "modules/search/_search.scss";
@import "modules/simple-calendar/_calendar-event-page.scss";
@import "modules/simple-calendar/_calendar-page.scss";
@import "modules/teams/_contact-person.scss";
@import "modules/teams/_team-member.scss";
@import "modules/teams/_team-page.scss";

// - Set box-sizing to border-box
*,
*:after,
*:before {
  box-sizing: border-box;
}

// - Fix small gutters of images
img {
  vertical-align: top;
}

// - Set page bg color
html {
  background: $footer-dark;
}


body {
  background: $page-bg;
  font-weight: $fw-light;
  padding: 0;
  margin: 0;
}

.main {
  display: block;
}

body:not(#homepage):not(#schoolpage) {
  .main {
    @extend %outer-container;
  }
}

*:focus {
  outline: 0;
}

.responsive-iframe-container {
  @extend %cf;

  .responsive-iframe {
    &--1_1 {
      @include aspect-ratio(1, 1);
    }

    &--4_3 {
      @include aspect-ratio(4, 3);
    }

    &--3_2 {
      @include aspect-ratio(3, 2);
    }

    &--5_3 {
      @include aspect-ratio(5, 3);
    }

    &--16_9 {
      @include aspect-ratio(16, 9);
    }

    &--3_1 {
      @include aspect-ratio(3, 1);
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

@import "partials/_contact-data.scss";
@import "partials/_cookie.scss";
@import "partials/_department-basicholder.scss";
@import "partials/_footer.scss";
@import "partials/_form.scss";
@import "partials/_header.scss";
@import "partials/_job-description.scss";
@import "partials/_latest-events.scss";
@import "partials/_loader.scss";
@import "partials/_mobile-navigation.scss";
@import "partials/_navigation.scss";
@import "partials/_page-title.scss";
@import "partials/_pagination.scss";
@import "partials/_responsive-tables.scss";
@import "partials/_school-pages.scss";
@import "partials/_section-heading.scss";
@import "partials/_totop.scss";
@import "partials/_userforms.scss";
@import "pages/_basic-holder.scss";
@import "pages/_homepage.scss";
@import "pages/_jobdescriptionpage.scss";
@import "pages/_schoolpage.scss";
@import "pages/_userdefinedform.scss";
@import '_colors.scss';
