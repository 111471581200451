#SchoolPage {
  .main__text {
    @include resp-margin(bottom, 1.5);
  }

  .page-title {
    text-align: center;
  }

  .section-heading--latest-news {
    @include resp-margin(top, 1.5);
  }
}

.modular-content-wrapper {
  @include resp-padding(top, 1.5);

  .module__title {
    text-align: center;

    h3 {
      font-size: $fs-h1;
    }
  }

  .module--headline {
    text-align: center;

    h2 {
      font-size: $fs-h1;
    }
  }
}