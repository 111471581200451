#TeamPage {
  .main__text {
    @include resp-margin(bottom);
    @include resp-padding(bottom);
    border-bottom: 1px solid $border-color;
  }
  .team-members {
    &__group {
      margin-bottom: $dist;
      &-title {
        margin-bottom: $dist/2;
      }
    }
  }
}