@import "_variables";
@import "_mixins";

// - Clearfix
%cf {
  *zoom: 1;

  &:before {
    content: "";
    display: table;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.cf {
  @extend %cf;
}

// - Decent Link
%decent-link {
  & {
    color: $fc;
    text-decoration: none;
    transition: color $duration linear;

    &:hover {
      color: $ac;
    }
  }
}

// - Container
%outer-container {
  width: calc(100% - #{$dist/2});
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

.outer-container {
  @extend %outer-container;
}

// - Buttons
%button {
  display: inline-block;
  padding: 0;
  padding-left: $dist/2;
  color: #FFFFFF;
  background: $ac;
  transition: all linear $duration;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: $radius;
  font-weight: $fw;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  white-space: nowrap;

  &:hover {
    background: $ac-hover;
    color: #ffffff;
  }

  &:focus {
    outline: none;
    border: none;
  }

  &:after {
    @include fontawesome($fa-var-long-arrow-right);
    background: $ac-hover;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    margin-left: $dist/2;
    transition: background linear $duration;
  }
}

%button--small {
  font-size: $fs-small;
  height: $dist;
  line-height: $dist;

  &:after {
    width: $dist;
    height: $dist;
    line-height: $dist;
  }
}

%button--full-width {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: $dist*2;

  &:after {
    position: absolute;
    right: 0;
  }
}

%button--white {
  background: #FFFFFF;
  color: $fc;

  &:hover {
    color: #FFFFFF;
  }
}

%button--light-green {
  background: $c1;
  color: #FFFFFF;

  &:hover {
    color: #FFFFFF;
  }
}

%button--green {
  background: $c2;
  color: #FFFFFF;

  &:after {
    background: darken($c2, 15%);
  }

  &:hover {
    background: darken($c2, 15%);
  }
}

%button--blue {
  background: $c3;
  color: #FFFFFF;

  &:after {
    background: darken($c3, 15%);
  }

  &:hover {
    background: darken($c3, 15%);
  }
}

%button--red {
  background: $c4;
  color: #FFFFFF;

  &:after {
    background: darken($c4, 15%);
  }

  &:hover {
    background: darken($c4, 15%);
  }
}

%button--orange {
  background: $c5;
  color: #FFFFFF;

  &:after {
    background: darken($c5, 15%);
  }

  &:hover {
    background: darken($c5, 15%);
  }
}

%button--neutral {
  background: $ac-sec;
  color: #ffffff;

  &:after {
    background: $ac-sec-hover;
  }

  &:hover {
    background: $ac-sec-hover;
  }
}

.button {
  @extend %button;

  &--small {
    @extend %button--small;
  }

  &--full-width {
    @extend %button--full-width;
  }

  &--white {
    @extend %button--white;
  }

  &--light-green {
    @extend %button--light-green;
  }

  &--green {
    @extend %button--green;
  }

  &--blue {
    @extend %button--blue;
  }

  &--red {
    @extend %button--red;

  }

  &--orange {
    @extend %button--orange;
  }

  &--neutral {
    @extend %button--neutral;
  }
}

// - Formatierter Text
%formatted-text {
  code {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    font-family: monospace, san-serif;
    font-size: $fs-small;
    padding: $dist/2;
    background: #ffffff;
    border-radius: $radius;
  }

  h1 {
    font-size: $fs-h1;
  }

  h2 {
    font-size: $fs-h2;
  }

  h3 {
    font-size: $fs-h3;
  }

  h4 {
    font-size: $fs-h4;
  }

  h5 {
    font-size: $fs-h5;
  }

  h6 {
    font-size: $fs-h6;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $fw-h;
    font-weight: $fw-h-bold;
  }

  & > *:first-child {
    margin-top: 0 !important;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  a {
    i {
      display: inline-flex;
      align-items: center;
      margin-right: 0.5em;
    }
  }

  p {
    margin-top: 0.75em;

    & > *:first-child {
      margin-top: 0 !important;
    }

    .btn {
      margin-top: 0.75em;
    }

    & + ul,
    & + ol,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div,
    & + blockquote,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  blockquote {
    margin-top: 0.75em;

    & > *:first-child {
      margin-top: 0 !important;
    }

    & + ul,
    & + ol,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div,
    & + p,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  code {
    margin-top: 0.75em;

    & > *:first-child {
      margin-top: 0 !important;
    }

    & + ul,
    & + ol,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div,
    & + p,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  pre {
    margin-top: 0.75em;

    & > *:first-child {
      margin-top: 0 !important;
    }

    & + ul,
    & + ol,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div,
    & + p,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + p,
    & + ul,
    & + ol,
    & + table,
    & + div,
    & + blockquote,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  ul, ol {
    list-style: outside;
    margin: 0 0 1em 1.25em;

    li {
      ul,
      ol {
        margin-bottom: 0;
      }
    }

    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div,
    & + blockquote,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  div {
    & > *:first-child {
      margin-top: 0;
    }

    & + p,
    & + ul,
    & + ol,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div,
    & + blockquote,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  table {
    & > *:first-child {
      margin-top: 0;
    }

    & + p,
    & + ul,
    & + ol,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div,
    & + blockquote,
    & + code,
    & + pre {
      margin-top: 0.75em;
    }
  }

  hr {
    outline: 0;
    border: 0;
    display: block;
    height: 1px;
    margin: $dist/2 0;
    background: $border-color;
  }

  blockquote {
    padding: $dist/4 0 $dist/4 $dist/4;
    border-left: 2px solid $border-color;
    font-style: italic;
  }
}

.formatted-text,
[class*="__text"] {
  @extend %formatted-text;
}