.page-slider {
  border-top: 2px solid $hc;
  width: 100%;
  max-width: $outer-width;
  margin-left: auto;
  margin-right: auto;

  .bx-wrapper {
    //overflow: hidden;
    position: relative;
  }

  .slick-arrow {
      @include center(v);
      z-index: 20;
      display: block;
      color: #ffffff;
      font-size: 2.5rem;
      background: $ac;
      line-height: 1;
      width: 45px;
      padding: $dist/1.5 0;
      text-align: center;
      text-decoration: none;
      transition: background linear $duration;

      i {
        width: auto;
        display: block;
      }

      &:hover {
        background: $ac-hover;
      }

      &.fa-chevron-left {
        left: 23px;
      }

      &.fa-chevron-right {
        right: 23px;
      }

      &.disabled {
        display: none;
      }

    @include breakpoint(null, $outer-width + $dist*2) {
      a {
        font-size: 2.5rem;
        width: 45px;

        &.fa-chevron-left {
          left: $dist/2;
        }

        &.fa-chevron-right {
          right: $dist/2;
        }
      }
    }

    @include breakpoint(null, 1340px) {
      a {
        font-size: 2rem;
        width: 45px;
      }
    }

    @include breakpoint(null, $bp-l) {
      a {
        font-size: 2rem;
        width: 45px;
      }
    }

    @include breakpoint(null, $bp-s) {
      display: none;
    }
  }

  .bx-pager {
    position: absolute;
    z-index: 20;
    bottom: $dist/2;
    left: 0;
    right: 0;
    font-size: 0;
    text-align: center;

    .bx-pager-item {
      display: inline-block;
      margin-right: 2px;

      &:last-of-type {
        margin-right: 0;

        &:first-of-type {
          display: none;
        }
      }

      a {
        display: block;
        width: $dist/3;
        height: $dist/3;
        background: $box-color;
        border-radius: 100%;
        font-size: 0;

        &:hover {
          background: $box-color-dark;
        }

        &.active {
          background: $hc;

          &:hover {
            background: darken($hc, 10%);
          }
        }
      }
    }
  }

  &__slide {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__caption {
    z-index: 10;
    @include center(v);
    left: 0;
    right: 0;
  }

  &__caption-content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $max-width;

    & > h3 {
      font-size: 40px / $fs-root + rem;
      font-weight: $fw-h-bold;
      font-family: $ff-h;
      color: #ffffff;
      text-transform: uppercase;
      line-height: 1;

      & > span {
        display: inline-block;
        background: rgba($footer-dark, 0.9);
        color: #ffffff;
        padding: $dist/2 $dist/2 $dist/3 $dist/2;
        line-height: 1;

        span {
          color: $hc;
        }
      }
    }

    & > p {
      font-size: $fs-big;
      font-weight: $fw-light;
      text-transform: uppercase;
      color: $fc;

      span {
        display: inline-block;
        padding: $dist/3 $dist/2;
        background: rgba(#ffffff, 0.9);
      }
    }

    & > a {
      @include resp-margin(top);
    }
  }

  @include breakpoint(null, 1340px) {
    &__caption {
      left: $dist + 45px;
      right: $dist + 45px;
    }

    &__caption-content {
      & > h3 {
        font-size: $fs-huge;
      }

      & > p {
        font-size: $fs-medium;
      }
    }
  }

  @include breakpoint(null, $bp-m) {
    &__caption-content {
      & > h3 {
        font-size: $fs-big;

        & > span {
          padding: $dist/2 $dist/2 $dist/3 $dist/2;
        }
      }

      & > p {
        font-size: $fs;

        span {
          padding: $dist/3 $dist/2;
        }
      }
    }
  }

  @include breakpoint(null, $bp-s) {
    &__caption {
      left: $dist/4;
      right: $dist/4;
    }

    &__caption-content {
      & > p {
        display: none;
      }
    }
  }
}