.module--accordion {
  .accordion {
    &__item {
      margin-bottom: 2px;
      background: $box-color;

      &:last-of-type {
        margin-bottom: 0;
      }

      &--open {
        .accordion__content {
          display: block;
        }

        .accordion__title {
          background: $box-color-dark;
        }
      }
    }

    &__title {
      cursor: pointer;
      padding: $dist/2;
      transition: background $duration linear;
      @extend %cf;
      text-transform: uppercase;

      &:hover {
        background: $box-color-dark;

        i {
          color: $ac-hover;
        }
      }

      strong {
        display: block;
        float: left;
        margin-right: $dist/4;
        width: calc(100% - #{$dist/4} - #{$fs});
        font-weight: $fw;
      }

      i {
        display: block;
        float: right;
        color: $ac;
        font-size: $fs;
        width: $fs;
        text-align: center;

        &.fa-minus-circle:before {
          content: fa-content($fa-var-chevron-up);
        }

        &.fa-plus-circle:before {
          content: fa-content($fa-var-chevron-down);
        }
      }
    }

    &__content {
      padding: $dist/2;
      display: none;

      .formatted-text {
        margin-top: -$dist/2;

        &:before {
          content: '';
          display: block;
          height: 2px;
          width: 100%;
          background: #ffffff;
          margin-bottom: $dist/2;
        }
      }
    }
  }
}