#JobDescriptionPage {
  .main {
    width: 100% !important;
    max-width: unset !important;

    & > *:not(.latest-news) {
      @extend %outer-container;
    }
  }

  .section-heading--latest-news {
    @include resp-margin(top, 1.5);
  }
}