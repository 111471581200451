.header {
    font-size: 0;
    @include resp-margin(v, 1, $bp-l);
    @extend %outer-container;
    @extend %cf;

    .header__logo-container {
        float: left;
        display: block;

        display: flex;
        align-items: center;

        @include breakpoint(null, $bp-m) {
            display: inherit;
        }
    }

    .header__logo {
        text-decoration: none;
    }

    .header__logo2 {
        width: 170px;
        margin-left: 1.4rem;
        img {
            width: 100%;
            height: auto;
        }
        @include breakpoint(null, $bp-s) {
            width: 130px;
            margin-left: 0rem;
            margin-top: 1rem;
        }
    }
    .header__search {
        display: block;
        float: right;
        width: span(6 of 12);
        padding-top: (86px - 45px) / 2;

        form#Form_SearchForm {
            border: none;
        }

        fieldset {
            @extend %cf;
            border: none;

            #Form_SearchForm_Search_Holder {
                background-color: red;
                float: left;
                width: calc(100% - 45px);

                input {
                    height: 45px;
                    line-height: 45px;
                    border: 0;
                    background: $box-color;
                    padding: 0 $dist/2;
                    color: $fc;
                }
            }

            #Form_SearchForm_action_results {
                display: block;
                width: 45px;
                height: 45px;
                //line-height: 45px;
                border:0;
                background: $ac;
                color: #ffffff;
                float: left;
                padding: 0;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 300;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                transition: background $duration linear;
                
                &:hover {
                    background: $ac-hover;
                }

                span {
                
                    font-size: 1.5rem;
                    transform: rotate(-45deg) !important;

                }
            }
        }
    }

    @include breakpoint(null, $bp-s) {
        .header__logo {
            width: 150px;

            img {
                width: 150px;
                height: 48px;
            }
        }

        .header__search {
            padding-top: 1px;
        }
    }
}