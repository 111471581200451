.team-members,
.search-results__list--TeamMember .search-results__list-items {
  @extend %cf;

  .team-member {
    $grid-conf: (
     (null, $bp-s, 1, 2),
     ($bp-s, null, 2)
    );
    @include resp-gallery-layout($grid-conf);
    @extend %cf;

    &__image {
      position: relative;
      float: left;
      margin-right: $dist/2;
      width: span(2 of 6);
      background: $box-color;
      text-align: center;

      img {
        @include resp-img;
      }

      &--no-image {
        @include aspect-ratio(170,227);

        & > *:first-child {
          font-size: 2.5rem;
          color: $border-color;
          @include center(f);
          right: initial;
          bottom: initial;
        }
      }
    }

    &__info {
      float: left;
      width: span(4 of 6);

      i {
        margin-right: 0.5em;
        color: $hc;
      }

      a {
        @extend %decent-link;
        font-weight: $fw-light;
      }
    }

    &__separator {
      line-height: 1;
      height: $dist/2;
    }

    &__title {
      font-weight: $fw-bold;
    }
  }
}