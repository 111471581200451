.contact-data {
  @extend %cf;
  @include resp-margin(bottom, 1.5);

  overflow-x: hidden;

  ul {
    position: relative;
    width: span(6 of 12) + gutter(12)/2;
    margin-left: -1rem;
    margin-top: -0.5rem;
    padding: $dist;
    float: left;
    @extend %cf;

    &::before {
      content: '';

      position: absolute;
      left: 1rem;
      top: 0.5rem;
      width: calc(100% + 1rem);
      height: calc(100% - 0.5rem);
    }

    &:nth-of-type(2) {
      li {
        &:nth-of-type(2) {
          display: flex;
          align-items: baseline;
        }
      }
    }

    @include breakpoint(null, $bp-l) {
      padding: $dist/2;
    }

    @include breakpoint($bp-m, $bp-l) {
      li {
        &:first-of-type {
          float: none;
          margin-bottom: 0.5em;
        }
      }
    }

    @include breakpoint(null, $bp-m) {
      float: none;
      width: 100%;
    }

    &:first-of-type {
      &::before {
        background: $box-color;
      }
    }

    &:last-of-type {
      &::before {
        background: $box-color-dark;
      }
    }

    li {
      position: relative;
      z-index: 1;
      display: block;
      float: left;
      margin-top: 0.5rem;
      margin-left: 1rem;
      line-height: 1;

      @include breakpoint(null, $bp-s) {
        &:first-of-type {
          display: none;
        }

        line-height: $line-height;
      }

      &:first-of-type {
        margin-right: 1em;
      }

      p {
        display: inline-block;
        line-height: 1;
        margin-right: 1em;

        &:last-of-type {
          margin-right: 0;
        }
      }

      strong,
      a,
      i {
        line-height: 1;
      }

      i {
        margin-right: 0.5em;
        color: $hc;
      }

      a {
        @extend %decent-link;
        font-weight: $fw-light;
      }
    }
  }
}