.back-to-top {
  display: none;
  position: fixed;
  z-index: 9999;
  right: $dist/2.25;
  bottom: $dist/2.25;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  background: $box-color-dark;
  color: $fc !important;
  border-radius: 100%;
  transition: background $duration linear;

  &:hover {
    background: darken($box-color-dark, 10%);
  }

  i {
    &:before {
      position: relative;
      top: -1px;
    }
  }
}