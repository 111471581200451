.modular-content {
  .module {
    @include resp-margin(bottom);

    &:last-of-type {
      margin-bottom: 0;
    }

    &--separator-top {
      @include resp-padding(top);
      border-top: 1px solid $border-color;
    }

    &--separator-bottom {
      @include resp-padding(bottom);
      border-bottom: 1px solid $border-color;
    }

    &:first-of-type {
      &.module--separator-top {
        padding-top: 0;
        border-top: 0;
      }
    }

    &:last-of-type {
      margin-bottom: 0;

      &.module--separator-bottom {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__title {
      @include resp-margin(bottom);
      text-transform: uppercase;
    }
  }
}