@import "_variables";

// - Aspect Ratio
@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  & > *:first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// - Convert PX to Rem
@function px2rem($pixel) {
  @return $pixel / $fs-root + rem;
}

// - Fontawesome
// light, regular, solid
@mixin fontawesome($icon, $style: light) {
  @if $style == light {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
  }

  @if $style == regular {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
  }

  @if $style == solid {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
  }

  @if $style == brands {
    font-family: 'Font Awesome 5 Brands';
    font-weight: 400;
  }

  content: fa-content($icon);
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}

// - Gallery Layout
@mixin gallery-layout($columns, $of: 12, $vertical-gutter-multiplicator: 1) {
  float: left;
  width: span(($of / $columns) of $of);
  margin-right: gutter($of);
  margin-top: gutter($of) * $vertical-gutter-multiplicator;

  &:nth-of-type(-n + #{$columns}) {
    margin-top: 0;
  }

  &:nth-of-type(#{$columns}n+#{$columns}) {
    margin-right: 0;
    float: right;
  }

  &:nth-of-type(#{$columns}n+#{$columns + 1}) {
    clear: both;
  }
}

// - Responsive Gallery Layout
// $grid-conf: (
//   (null, $bp-s, 1, 2),
//   ($bp-s, $bp-m, 2),
//   ($bp-m, null, 3)
// );
//
// .element {
//   @include resp-gallery-layout($grid-conf);
// }

@mixin resp-gallery-layout($conf) {
  @each $state in $conf {

    $multi: 1;
    @if length($state) == 4 {
      $multi: nth($state, 4);
    }

    @if length($state) > 4 {
      $multi: nth($state, 4);
    }

    $of: 12;
    @if length($state) >= 5 {
      $of: nth($state, 5);
    }

    @include breakpoint(nth($state, 1), nth($state, 2)) {
      @include gallery-layout(nth($state, 3), $of, $multi);
    }
  }
}

@mixin center($position) {
  position: absolute;

  @if $position == h {
    left: 50%;
    transform: translateX(-50%);
  } @else if $position == v {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == f {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  } @else if $position == n {
    position: static;
    left: initial;
    top: initial;
    right: initial;
    bottom: initial;
    transform: translateX(0) translateY(0);
  }
}

// - Truncate
@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// - Responsive Image
@mixin resp-img() {
  display: block;
  width: 100%;
  height: auto;
}

// - Icon Before
@mixin icon-before($icon: $fa-var-dot, $width: 1em, $margin: 0.25em) {
  padding-left: $width + $margin;

  &:before {
    @include fontawesome($icon);
    width: $width;
    margin-left: 0 - $width - $margin;
    float: left;
    text-align: center;
    position: relative;
    top: 3px;
  }
}

// - Image Hover
@mixin image-hover($icon: $fa-var-search-plus) {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;

  &:after {
    @include fontawesome($icon);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: -$dist*2;
    bottom: 0;
    z-index: 10;
    color: $ac;
    background: rgba(darken($fc, 25%), 0.85);
    text-align: right;
    padding: $dist/2 $dist/2+4px 0 0;
    font-size: $fs-medium;
    width: 100%;
    transition: all $duration linear;
  }

  &:before {
    visibility: hidden;
    opacity: 0;
    content: '';
    display: block;
    width: 4px;
    position: absolute;
    top: 0;
    left: -4px;
    bottom: 0;
    background: rgba($ac, 0.9);
    transition: all $duration linear;
    z-index: 20;
  }

  &:hover {
    &:before,
    &:after {
      visibility: visible;
      opacity: 1;
      left: 0;
    }

    &:after {
      left: 4px;
    }
  }
}

// - Breakpoint
@mixin breakpoint($from: null, $to: null, $incrementFrom: true) {
  @if $from != null and $to != null {
    @if $incrementFrom {
      $from: $from + 1px;
    }

    @media screen and (min-width: $from) and (max-width: $to) {
      @content;
    }
  }

  @if $from !=null and $to == null {
    @if $incrementFrom {
      $from: $from + 1px;
    }

    @media screen and (min-width: $from) {
      @content;
    }
  }

  @if $from == null and $to != null {
    @media screen and (max-width: $to) {
      @content;
    }
  }
}

// - Responsive Margin
@mixin resp-margin($direction: bottom, $multi: 1, $bp: $bp-m, $important: null) {
  @if $direction == top {
    margin-top: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      margin-top: ($dist/2) * $multi $important;
    }
  } @else if $direction == bottom {
    margin-bottom: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      margin-bottom: ($dist/2) * $multi $important;
    }
  } @else if $direction == left {
    margin-left: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      margin-left: ($dist/2) * $multi $important;
    }
  } @else if $direction == right {
    margin-right: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      margin-right: ($dist/2) * $multi $important;
    }
  } @else if $direction == h {
    margin-right: ($dist) * $multi $important;
    margin-left: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      margin-right: ($dist/2) * $multi $important;
      margin-left: ($dist/2) * $multi $important;
    }
  } @else if $direction == v {
    margin-top: ($dist) * $multi $important;
    margin-bottom: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      margin-top: ($dist/2) * $multi $important;
      margin-bottom: ($dist/2) * $multi $important;
    }
  }
}

// - Responsive Padding
@mixin resp-padding($direction: bottom, $multi: 1, $bp: $bp-m, $important: null) {
  @if $direction == top {
    padding-top: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      padding-top: ($dist/2) * $multi $important;
    }
  } @else if $direction == bottom {
    padding-bottom: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      padding-bottom: ($dist/2) * $multi $important;
    }
  } @else if $direction == left {
    padding-left: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      padding-left: ($dist/2) * $multi $important;
    }
  } @else if $direction == right {
    padding-right: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      padding-right: ($dist/2) * $multi $important;
    }
  } @else if $direction == h {
    padding-right: ($dist) * $multi $important;
    padding-left: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      padding-right: ($dist/2) * $multi $important;
      padding-left: ($dist/2) * $multi $important;
    }
  } @else if $direction == v {
    padding-top: ($dist) * $multi $important;
    padding-bottom: ($dist) * $multi $important;

    @include breakpoint(null, $bp) {
      padding-top: ($dist/2) * $multi $important;
      padding-bottom: ($dist/2) * $multi $important;
    }
  }
}