.calendar-event {
  @extend %cf;

  &__text {
    float: left;
    width: calc(100% - 230px - #{gutter(12)});
    margin-right: gutter(12);
  }

  .calendar-entry__dates {
    float: left;
    width: 230px;
    text-align: left !important;
    margin-right: 0 !important;
  }

  .dates__headline {
    margin-bottom: $dist/2;
    font-weight: $fw-bold;
    text-transform: uppercase;
  }

  #Form_SignUp {
    clear: both;
    width: calc(100% - 230px - #{gutter(12)});
    @include resp-padding(top);

    #Form_SignUp_HeaderFieldAnmeldung {
      margin-bottom: $dist/2;
      text-transform: uppercase;
    }
  }
}